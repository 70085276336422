/**
 * User Model
 *
 * @format
 */

import { IOrderedClip } from './stack.model';
import { Project } from '@app/projects/shared/project.model';
import { ProjectMember } from '@app/project-members/shared/project-member.model';
import { SubscriptionLevel, SubscriptionStatus } from '@app/billing/shared/billing.model';
import { filestackConfig } from 'src/environments/environment';

export const DEFAULT_USER_AVATAR = '/assets/images/default-avatar.png';
export const DEFAULT_USER_AVATAR_TERTIARY = '/assets/images/default-avatar-tertiary.png';
export const DEFAULT_USER_AVATAR_DARK = '/assets/images/default-avatar-dark.png';

export const USER_AVATAR_UPLOAD_CDN = filestackConfig.storeTo.avatar.cdn; //'https://content.filmstacker.com/';
export const USER_AVATAR_UPLOAD_CDN_FILETYPE = 'jpg'; // this is what is created by backend
/** These were moved to avatar-upload.directive */
// export const USER_AVATAR_UPLOAD_BUCKET = filestackConfig.storeTo.avatar.container; //'content.filmstacker.com';
export const USER_AVATAR_UPLOAD_PATH = filestackConfig.storeTo.avatar.path; // 'public/avatars/'; // <user-id.jpg>
// export const USER_AVATAR_UPLOAD_RESIZE_TRIGGER_SUFFIX = filestackConfig.storeTo.avatar.apiTriggerSuffix; // '__orig';

export class User {
  // username : string;
  loggedIn?: boolean;
  // user : any;
  email?: string;

  // appsync model
  // cognito:username
  userId: string;
  //  Amazon Cognito Identity - for federating logins from multiple locations
  identityId?: string;
  // public name (first last)
  name?: string;
  // handle (preferred_username)
  username?: string; // username
  // handle: String
  // string url to s3 bucket containing image
  avatar?: string;

  //  array of projects you are an owner - or, we can query project index
  projectIds?: string[];
  projects?: Project[];
  memberProjects?: ProjectMember[];
  stacks?: string[]; // (projectId/stackId)
  recentProjects?: string[];

  bio?: string;
  //  state/region
  location?: string;
  country?: string;

  //  array of stacks & clips marked as watch later
  watchLater?: Array<string>;
  //  stats
  numClipsWatched?: number;
  numClipsAddedToStack?: number;
  numStacksPublished?: number;
  numStacksWatched?: number;
  numShares?: number;
  numVotes?: number;
  votes?: Array<string>; // ids of the items voted on
  //  clipsWatched [clip ids]
  historyClips?: Array<IOrderedClip>;
  //  json of environ data for your user - don't include history here
  environment?: string;
  version?: string;

  loaded?: boolean;
  /**
   * display that this user has not yet logged in so there's no data in the UsersDB
   * (setup by admin in console) MVP-1280
   * @note not a prop in DB schema
   */
  pending?: boolean;
  createdAt?: string;
  updatedAt?: string;

  //  Array<string> groups cognito
  groups?: Array<string>;
  //  <type>_uuid (types= fb_, up_userpool, g_goog)
  userIdType?: string;

  // subscriptionStatus
  subscriptionLevel?: SubscriptionLevel;
  subscriptionStatus?: SubscriptionStatus;
  subscriptionMinutes?: number;

  /**
   * @deprecated
   */
  projectCrews?: ProjectMember[];
  /**
   * @deprecated
   */
  lastModified?: string;
  /**
   * @deprecated
   */
  sub?: string;

  constructor(props: unknown) {
    if (props && typeof props === 'object') {
      Object.entries(props).forEach(([key, value]) => (this[key] = value));
    }
  }
}
