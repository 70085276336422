/**
 * @format
 */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  ChargebeeCustomer,
  ChargebeeCustomerApiInput,
  ChargebeePlanType,
  ChargebeeEndpointResult,
} from '@app/billing/shared/billing.model';
import { EMPTY, Observable, lastValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const DEBUG_LOGS = false;
const PAGE = '[ChargebeeApi]';

/*
<script src="https://js.chargebee.com/v2/chargebee.js" data-cb-site="filmstacker" ></script>

https://github.com/chargebee/chargebee-checkout-samples/blob/master/api/front-end/angular/src/app/app.component.ts


TODO: 
- manage payment sources:
  generate_update_payment_method_url

*/

export interface ChargebeePlan {
  id: string;
  name: string;
  external_name: string;
  description: string;
  status: string; // 'active'
  channel: string;
  enabled_for_checkout: boolean;
  enabled_in_portal: boolean;
  is_giftable;
  is_shippable;
  item_applicability: string;
  item_family_id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: any;
  metered;
  object: string; // 'item'
  resource_version: number;
  type: ChargebeePlanType; //'plan'
  updated_at: number;
}
export interface ChargebeePlanPrice {
  channel: string; //"web"
  created_at: number;
  currency_code: string; // "USD"
  external_name: string; // "Teams Monthly"
  free_quantity: boolean;
  id: string; // "Default-Teams-USD-Monthly"
  is_taxable: boolean;
  item_family_id: string;
  item_id: string;
  item_type: string; // "plan"
  name: string; // "Default-Teams USD Monthly"
  object: string; // "item_price"
  period: number; // 1
  period_unit: string; // "month"
  price: number; //20000
  pricing_model: string; // "flat_fee"
  resource_version: number;
  show_description_in_invoices: boolean;
  show_description_in_quotes: boolean;
  status: string; // "active"
  updated_at: number;
  tiers?: {
    ending_unit: number;
    price: number;
    starting_unit: number;
  }[];
}

/**
 * The customers will be redirected to this URL upon successful checkout.
 * The hosted page id and state will be passed as parameters to this URL.
 *
 * @note
 * Although the customer will be redirected to the redirect_url after successful checkout,
 * we do not recommend relying on it for completing critical post-checkout actions.
 * This is because redirection may not happen due to unforeseen reasons such as user closing the tab,
 * or exiting the browser, and so on. If there is any synchronization that you are doing after the redirection,
 * you will have to have a backup. Chargebee recommends listening to appropriate webhooks such as
 * subscription_created or invoice_generated to verify a successful checkout.
 * Redirect URL configured in Settings > Hosted Pages Settings would be overriden by this redirect URL.
 *
 * http://yoursite.com?id=<hosted_page_id>&state=succeeded
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getRedirectUrl = (path = ''): string => `${location.origin}/${path}`;

/**
 * coupon
 * For validating the coupon code provided by the user,
 * use the following codes in combination with the param attribute in the error response.
 * resource_not_found : Returned if the coupon is not present.
 * resource_limit_exhausted : Returned if the coupon has expired or the maximum redemption for the coupon has already been reached.
 * invalid_request : Returned if the coupon is not applicable for the particular plan/addon.
 */
const isResponseCouponError = (res): boolean => {
  try {
    if (res?.error?.param === 'coupon_ids[0]') {
      switch (res.error.api_error_code) {
        case 'resource_not_found':
        case 'resource_limit_exhausted':
        case 'invalid_request':
          // we know this is a coupon error
          console.log(`Handling coupon error: '${res.error.error_msg}'`);
          return true;
        default:
          console.log(`coupon_ids error not known? api_error_code='${res.error.api_error_code}'`, res.error);
          return false;
      }
    }
    return false;
  } catch (error) {
    console.warn(error);
    return false;
  }
};

/**
 * @todo add auth token
 */
//   http.get('someurl', {
//     Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
//  });

@Injectable({
  providedIn: 'root',
})
export class ChargebeeApiService {
  public chargebeeConfig = environment.chargebee;

  get chargebeeEndpoint(): string {
    return this.chargebeeConfig.endpoint;
  }

  constructor(private http: HttpClient) {}

  /**
   * {
      "list": [
          {"item": {
              "applicable_items": [
                  {"id": "day-pass"},
                  {..}
              ],
              "enabled_for_checkout": true,
              "enabled_in_portal": true,
              "id": "gold",
              "is_giftable": false,
              "is_shippable": false,
              "item_applicability": "restricted",
              "name": "Gold",
              "object": "item",
              "resource_version": 1599817250235,
              "status": "active",
              "type": "plan",
              "updated_at": 1599817250
          }},
          {..}
      ],
      "next_offset": "1"
  }
   * https://apidocs.chargebee.com/docs/api/items?prod_cat_ver=2#list_items
   */
  listPlans(): Promise<{ plans: ChargebeePlan[]; prices: ChargebeePlanPrice[] }> {
    return (
      lastValueFrom(this.http.get(`${this.chargebeeEndpoint}/plans`))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: { plans?: any[]; prices?: any[] } = {}) => {
          const { plans = [], prices = [] } = res;
          DEBUG_LOGS && console.log(`api listPlans`, { plans, prices });
          return {
            plans: plans.map((el) => el && el.item),
            prices: prices.map((el) => el && el.item_price),
          };
        })
        .catch((error) => {
          console.warn(`listPlans caught error:`, error);
          throw error;
        })
    );
  }

  /**
   * Get Chargebee User and Subscriptions of current loggedIn User, if exists
   */
  getUserSubscriptions(userId: string): Observable<ChargebeeEndpointResult[]> {
    return this.http.get(`${this.chargebeeEndpoint}/subscriptions/${userId}`).pipe(
      map((res) => res as ChargebeeEndpointResult[]),
      catchError((error) => {
        if (error && error.error && error.error.error_code === 'resource_not_found') {
          DEBUG_LOGS && console.log(`getUserSubscriptions user not found:`, error);
          return EMPTY;
        } else {
          console.warn(`getUserSubscriptions caught error:`, error);
          throw error;
        }
      })
    );
  }

  /**
   * Generate the Portal Session for current user
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  generatePortalSession(userId: string): Promise<any> {
    // Hit your end point that returns portal session object as response
    // This sample end point will call the below api
    // https://apidocs.chargebee.com/docs/api/portal_sessions#create_a_portal_session
    return lastValueFrom(
      this.http.post(
        `${this.chargebeeEndpoint}/generate_portal_session`,
        this.getFormUrlEncoded({
          user_id: userId,
        }),
        { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) }
      )
    ).catch((error) => {
      console.warn(`generatePortalSession caught error:`, error);
      throw error;
    });
  }

  /**
   * https://apidocs.chargebee.com/docs/api/hosted_pages?prod_cat_ver=2#create_checkout_for_a_new_subscription
      app.post("/chargebee/checkout_new_for_items", 
   */
  async generateCheckoutSession({
    user_id,
    plan_id,
    price_id = '',
    referral_code = '',
    project_id = '',
    project_name = '',
    event_date = '',
    email = '',
    first_name = '',
    last_name = '',
    phone = '',
    redirectSuccessPath = '',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): Promise<any> {
    /** note that this payload is unique to our API, not exactly the same as chargebee */
    const payload = {
      user_id, // customer.id
      plan_id, // unused... fyi we get what we need from price_id
      price_id,
      ...(project_id ? { project_id } : {}),
      ...(project_name ? { project_name } : {}),
      ...(event_date ? { event_date } : {}),

      ...(referral_code ? { referral_code } : {}),
      // could also send first/last name
      ...(email ? { email } : {}),
      ...(first_name ? { first_name } : {}),
      ...(last_name ? { last_name } : {}),
      ...(phone ? { phone } : {}),
      // ...(company ? { company } : {}),
      // allow the app to handle success, do not send a redirect_url..
      // redirect_url: getRedirectUrl(redirectSuccessPath),
    };
    /**
     * Other options to consider
     * pass_thru_content: https://apidocs.chargebee.com/docs/api/hosted_pages?prod_cat_ver=2#create_checkout_for_a_new_subscription_pass_thru_content
     */
    DEBUG_LOGS && console.log('generateCheckoutSession', { payload });
    return await lastValueFrom(
      this.http
        .post(`${this.chargebeeEndpoint}/checkout_new_for_items`, this.getFormUrlEncoded(payload), {
          headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
        })
        .pipe(
          catchError((error) => {
            if (isResponseCouponError(error)) {
              // call myself again without a coupon since it failed
              return this.generateCheckoutSession({
                user_id,
                plan_id,
                price_id,
                // referral_code,
                project_id,
                project_name,
                event_date,
                email,
                first_name,
                last_name,
                phone,
                redirectSuccessPath,
              });
            }
            console.warn(`generateCheckoutSession caught error:`, error);
            if (error.error) {
              throw error.error;
            }
            throw error;
          })
        )
    );
  }

  /**
   * generate_checkout_existing_url", (req, res, next) => {
   *  Chargebee.hosted_page.checkout_existing
   */
  async generateCheckoutExisting({
    subscriptionId,
    user_id,
    plan_id,
    price_id = '',
    referral_code = '',
    project_id = '',
    project_name = '',
    event_date = '',
    email = '',
    first_name = '',
    last_name = '',
    phone = '',
    redirectSuccessPath = '',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): Promise<any> {
    /** note that this payload is unique to our API, not exactly the same as chargebee */
    const payload = {
      id: subscriptionId,
      user_id, // customer.id
      plan_id, // unused... fyi we get what we need from price_id
      price_id,
      ...(project_id ? { project_id } : {}),
      ...(project_name ? { project_name } : {}),
      ...(event_date ? { event_date } : {}),
      ...(referral_code ? { referral_code } : {}),
      // could also send first/last name
      ...(email ? { email } : {}),
      ...(first_name ? { first_name } : {}),
      ...(last_name ? { last_name } : {}),
      ...(phone ? { phone } : {}),
      // ...(company ? { company } : {}),
      // allow the app to handle success, do not send a redirect_url..
      // redirect_url: getRedirectUrl(redirectSuccessPath),
    };
    DEBUG_LOGS && console.log('generateCheckoutExisting', { payload });
    return await lastValueFrom(
      this.http
        .post(`${this.chargebeeEndpoint}/checkout_existing_for_items`, this.getFormUrlEncoded(payload), {
          headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
        })
        .pipe(
          catchError((error) => {
            if (isResponseCouponError(error)) {
              // call myself again without a coupon since it failed
              return this.generateCheckoutExisting({
                subscriptionId,
                user_id,
                plan_id,
                price_id,
                referral_code,
                project_id,
                project_name,
                event_date,
                redirectSuccessPath,
              });
            }
            console.warn(`generateCheckoutExisting caught error:`, error);
            if (error.error) {
              throw error.error;
            }
            throw error;
          })
        )
    );
  }

  listHostedPages() {
    return lastValueFrom(this.http.get(`${this.chargebeeEndpoint}/hosted_pages/list`)).catch((error) => {
      console.warn(`listHostedPages caught error:`, error);
      throw error;
    });
  }

  retrieveHostedPage(id: string) {
    return lastValueFrom(this.http.get(`${this.chargebeeEndpoint}/hosted_pages/${id}`))
      .then((res) => {
        DEBUG_LOGS && console.log(`retrieveHostedPage res:`, res);
        return res;
      })
      .catch((error) => {
        console.warn(`retrieveHostedPage caught error:`, error);
        throw error;
      });
  }

  /**
   * https://www.chargebee.com/checkout-portal-docs/api.html#opencheckout
   */
  createCustomer(customer: ChargebeeCustomerApiInput): Observable<ChargebeeCustomer> {
    DEBUG_LOGS && console.log(`createCustomer:`, customer);
    return this.http
      .post(
        `${this.chargebeeEndpoint}/customer_create`,
        this.getFormUrlEncoded({
          ...customer,
        }),
        { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) }
      )
      .pipe(
        map((res) => res as ChargebeeCustomer),
        catchError((error) => {
          // api_error_code: "duplicate_entry"
          if (error?.error?.error_code === 'param_not_unique') {
            DEBUG_LOGS && console.log(`createCustomer already exists:`, error);
            return of(customer as ChargebeeCustomer);
          } else {
            console.warn(`getUserSubscriptions caught error:`, error);
            throw error;
          }
        })
      );
  }

  /**
   * @note Does not include subscriptions, use getUserSubscriptions instead (includes both)
   * But if no subscriptions, that endpoint returns nothing...
   * Get Chargebee User of current loggedIn User, if exists
   */
  getUser(userId: string): Observable<ChargebeeEndpointResult | boolean> {
    if (!userId) {
      DEBUG_LOGS && console.log(`${PAGE} user not logged in -> no portal session`);
      throw new Error('User not Logged In -> No Portal Session');
    }
    return this.http.get(`${this.chargebeeEndpoint}/customer/${userId}`).pipe(
      map((res) => res as ChargebeeEndpointResult),
      catchError((error) => {
        if (error?.error?.error_code === 'resource_not_found') {
          // error.message = "Sorry, we couldn't find that resource"
          DEBUG_LOGS && console.log(`getUser no user (${error.error.error_code})`);
          return of(false);
        } else {
          console.warn(`getUser caught error:`, error);
          throw error;
        }
      })
    );
  }

  /**
   * get subscription by id
   */
  getSubscription(id: string): Observable<ChargebeeEndpointResult | boolean> {
    if (!id) {
      DEBUG_LOGS && console.log(`${PAGE} getSubscription`, id);
      throw new Error('No id..');
    }
    return this.http.get(`${this.chargebeeEndpoint}/subscription/${id}`).pipe(
      map((res) => res as ChargebeeEndpointResult),
      catchError((error) => {
        if (error?.error?.error_code === 'resource_not_found') {
          // error.message = "Sorry, we couldn't find that resource"
          DEBUG_LOGS && console.log(`getSubscription not found (${error.error.error_code})`);
          return of(false);
        } else {
          console.warn(`getSubscription caught error:`, error);
          throw error;
        }
      })
    );
  }

  private getFormUrlEncoded(toConvert) {
    const formBody = [];
    for (const property in toConvert) {
      if ({}.hasOwnProperty.call(toConvert, property)) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(toConvert[property]);
        formBody.push(encodedKey + '=' + encodedValue);
      }
    }
    return formBody.join('&');
  }
}
