/**
 * @format
 */

/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Analytics } from 'aws-amplify';
import { ProjectMember } from '@app/project-members/shared/project-member.model';
import { Project } from '@app/projects/shared/project.model';
import { EventsService } from '../events.service';
import { UserService } from '../user.service';
import { AuthMethod, GoogleAnalyticsService, ProjectRole, SelectContentType } from './google-analytics.service';
import { PinpointService } from './pinpoint.service';
import { SentryService } from '../sentry.service';
import { environment } from 'src/environments/environment';

export { AuthMethod, ProjectRole, SelectContentType, ShareInput } from './google-analytics.service';

// old
export interface VoteOptions {
  itemType?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item?: any;
  inc?: number;
}

// Interfaces inspired by node_modules/@aws-amplify/src/types/Analytics.ts
export class AnalyticsEvent {
  name: string;
  attributes?: { [key: string]: string } = {};
  metrics?: { [key: string]: number } = {};

  constructor(props: unknown) {
    if (props && typeof props === 'object') {
      Object.entries(props).forEach(([key, value]) => (this[key] = value));
    }
  }
}

/**
 * EventType: "_session.start"
 * Attributes: {origin: "http://localhost:8100", host: "localhost:8100", pathname: "/stack/home", referrer: ""}
 *
 * EventType: "pageView"
 * Attributes: {url: "http://localhost:8100/stack/play/wedding-metzner-velds/matt_The-Big-Day_20220305",…}
 *
 * EventType: "stacks/playIndex"
 * Attributes: {index: 0, isPlayingAgain: 1}
 *
 * EventType: "clips/play"
 * Attributes: {projectId: 'wedding-metzner-velds', clipId: 'tjmanitou_-_IMG_8564MOV_202109251722'}
 *
 * EventType: "stacks/share"
 * Attributes: {projectId: 'wedding-metzner-velds', stackId: 'matt_The-Big-Day_20220305', shareUrl: 'https://stacks.filmstacker.com/wedding-metzner-velds/matt_The-Big-Day_20220305/', method: 'Twitter', itemType: 'STACKS'}
 *
 * EventType: "stacks/watchComplete"
 * Attributes: {projectId: 'wedding-metzner-velds', stackId: 'matt_The-Big-Day_20220305'}
 *
 * EventType: "stacks/watch"
 * Attributes: {projectId: 'cody_sheehy-wonderful-home-in-tucson', stackId: 'cody_sheehy_foothills-3-bedroom-3-batg_20221003'}
 *
 * EventType:
 * Attributes:
 */

export enum AnalyticsEventName {
  CLIPS_PLAY = 'clips/play',
  STACKS_EDIT = 'stacks/edit',
  STACKS_PLAY_INDEX = 'stacks/playIndex',
  STACKS_SHARE = 'stacks/share',
  STACKS_WATCH = 'stacks/watch',
  STACKS_WATCH_COMPLETE = 'stacks/watchComplete',
}

/**
 * Split payload to assign props whose value is:
 * - number to metrics properties
 * - string to attributes properties
 *
 * @param name
 * @param payload
 * @returns AnalyticsEvent
 */
export const buildAnalyticsEventPayload = (name, payload) => {
  const metrics = {},
    attributes = {};

  for (const [key, value] of Object.entries(payload)) {
    if (typeof value === 'number') {
      metrics[key] = value;
    } else if (typeof value === 'string') {
      attributes[key] = value;
    }
  }

  return { name, attributes, metrics };
};

const DEBUG_LOGS = !environment.production;
const ENABLE_IN_DEV = true; // test the Analytics interface when !env.production

const PAGE = '[AnalyticsService]'; // eslint-disable-line  @typescript-eslint/no-unused-vars

const getStackId = (projectId, stackId) => `${projectId}/${stackId}`;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let dataLayer: any;

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  get isEnabled(): boolean {
    return environment.production || ENABLE_IN_DEV;
  }

  constructor(
    private events: EventsService,
    private userService: UserService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private pinpointService: PinpointService,
    private sentryService: SentryService
  ) {
    if (this.isEnabled) {
      this.events.subscribe('analytics:setUser', this.setUser.bind(this));
    }
  }

  recordEvent(name: string, payload: object): void {
    try {
      const analyticsEvent: AnalyticsEvent = buildAnalyticsEventPayload(name, payload);
      if (!environment.isAnalyticsEnabled) {
        /**
         * To enable Analytics on dev set env.isAnalyticsEnabled = true
         */
        DEBUG_LOGS && console.log(`Analytics event '${name}' not logged, disabled on env`, payload);
        return;
      }
      dataLayer.push({
        event: name,
        ...payload,
      });
      Analytics.record(analyticsEvent);
    } catch (error) {
      this.sentryService.captureError(error);
    }
  }

  /*
    GA4
  */

  /**
   * check if invited crew
   * AuthMethod.Invite
   */
  signUpFromInvite(method: AuthMethod = AuthMethod.Invite) {
    if (this.isEnabled) {
      this.googleAnalyticsService.signUp(method);
    }
  }
  signUp(method: AuthMethod = AuthMethod.Email) {
    if (this.isEnabled) {
      this.googleAnalyticsService.signUp(method);
    }
  }
  login(method: AuthMethod = AuthMethod.Email) {
    if (this.isEnabled) {
      this.googleAnalyticsService.login(method);
    }
  }
  search(term: string) {
    if (this.isEnabled) {
      this.googleAnalyticsService.search(term);
      this.pinpointService.recordEvent({
        name: 'search',
        search_term: term,
      });
    }
  }
  tutorialBegin(tutorialId = '') {
    if (this.isEnabled) {
      this.googleAnalyticsService.tutorialBegin();
      this.pinpointService.recordEvent({
        name: 'tutorial_begin',
        tutorial_id: tutorialId,
      });
    }
  }
  tutorialComplete(tutorialId = '') {
    if (this.isEnabled) {
      this.googleAnalyticsService.tutorialComplete();
      this.pinpointService.recordEvent({
        name: 'tutorial_complete',
        tutorial_id: tutorialId,
      });
    }
  }

  /**
   * joinGroup when accept crew invite
   * used by: project-member.service.applyTokenRoleForCurrentUser
   */
  crewInviteAccepted(projectId) {
    if (this.isEnabled) {
      this.googleAnalyticsService.joinGroup(projectId);
      this.pinpointService.recordEvent({
        name: 'join_group',
        group_id: projectId,
      });
    }
  }

  /*
   * Custom Events
   */

  /**
   * on publish
   * role on project = crew | producer | public
   */
  stackPublished(projectId: string, stackId: string, role: ProjectRole) {
    if (this.isEnabled) {
      this.googleAnalyticsService.stackPublished({ projectId, stackId, role });
      this.pinpointService.recordEvent({
        name: 'stack_published',
        project: projectId,
        stack: stackId,
      });
    }
  }
  clipUpload({
    projectId,
    id,
    userId,
    seconds,
    size,
  }: {
    projectId: string;
    id: string;
    userId: string;
    seconds?: string;
    size?: string;
  }) {
    if (this.isEnabled) {
      this.googleAnalyticsService.clipUpload({ projectId, id, userId, seconds, size });
      this.pinpointService.recordEvent({
        name: 'clip_upload',
        project: projectId,
        clip: id,
        user: userId,
        seconds,
        size,
      });
    }
  }
  projectPosterUpdated(projectId, imageUrl) {
    if (this.isEnabled) {
      this.googleAnalyticsService.projectPosterUpdated(projectId, imageUrl);
      this.pinpointService.recordEvent({
        name: 'project_poster_update',
        project: projectId,
        image_url: imageUrl,
      });
    }
  }

  /*
   * Effects
   */

  selectContent(content_type: SelectContentType, projectId, stackId) {
    if (this.isEnabled) {
      this.googleAnalyticsService.selectContent({
        content_type,
        item_id: getStackId(projectId, stackId),
      });
      // replaced by selectStackEdit
      // this.pinpointService.recordEvent({
      //   name: 'select_content',
      //   content_type,
      //   project: projectId,
      //   stack: stackId,
      // });
    }
  }
  selectStackEdit({ projectId, stackId }) {
    this.selectContent(SelectContentType.StackEdit, projectId, stackId);
  }
  selectStackPlay({ projectId, stackId }) {
    this.selectContent(SelectContentType.StackPlay, projectId, stackId);
  }
  stackApproved({ projectId, stackId, isApproved, userId }) {
    if (this.isEnabled) {
      this.googleAnalyticsService.stackApproved({ projectId, stackId, isApproved });
      this.pinpointService.recordEvent({
        name: 'stack_approved',
        project: projectId,
        stack: stackId,
        approved: isApproved,
        userId,
      });
    }
  }

  userUpdated({ prop, value, userId }: { prop: string; value: string; userId: string }) {
    if (this.isEnabled) {
      this.googleAnalyticsService.userUpdated({ property: prop, value, userId });
      this.pinpointService.userUpdated({ property: prop, value, userId });
    }
  }

  /**
   * From UserEffects - Foreach project the current user has, update the endpoint data
   * Be sure to add all at once, else append to existing since they will overwrite Pinpoint data
   */
  updateUserProjects({
    // identityId,
    userId,
    projectsOwned,
    projectRoles,
  }: {
    // identityId: string;
    userId: string;
    projectsOwned: Project[];
    projectRoles: ProjectMember[];
  }) {
    if (!this.isEnabled) {
      /**
       * To enable Analytics on dev set env.isAnalyticsEnabled = true
       */
      DEBUG_LOGS &&
        console.log(`Analytics event 'updateUserProjects' not logged, disabled on env`, {
          userId,
          projectsOwned,
          projectRoles,
        });
      return;
    }
    this.pinpointService.updateUserProjects({
      // identityId,
      userId,
      projectsOwned,
      projectRoles,
    });
  }

  /*
    Todo: at some point
  */
  // selectItem({ item_list_id, item_list_name, items }: SelectItemInput)

  /*
    UA3
  */

  /**
   * Called from app.component
   */
  startTracker() {
    if (this.isEnabled) {
      this.initEvent();
    }
  }

  private initEvent() {
    DEBUG_LOGS && console.log(`${PAGE} initEvent`);
    this.googleAnalyticsService.sendInitEvent();
  }

  private setUser(user: { id: string; userId: string; username: string; email?: string; groups?: string[] }) {
    if (!this.isEnabled) {
      DEBUG_LOGS &&
        console.log(`Analytics event 'setUser' not logged, disabled on env`, {
          user,
        });
      return;
    }
    this.pinpointService.setUser(user);
    this.googleAnalyticsService.setUser(user);
  }
}
