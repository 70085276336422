/** @format */

import { UpdateParam, UpdateParamInt } from '@app/core/api/api-types';
import { createAction, props, union } from '@ngrx/store';
import { Clip } from '@app/shared/models/clip.model';
import { STACK_PRIVACY, Stack } from '@app/shared/models/stack.model';

/**
//   recommended: 0,
//   featured: 0,
//   suggested: 0,
//   tax: {
//     topics : [],
//     subtopics : [],
//     emotions: [],
//     tags: []
//   },
//   stats: {
//     featured : 0,
//     votes : 0,
//     views : 0,
//     shares: 0,
//     restacks: 0
//   }
 */
export const newMyStack = createAction('[MyStack] New MyStack', props<{ stack: Partial<Stack> }>());
export const updateMyStack = createAction(
  '[MyStack] Update MyStack',
  props<{ stack: Stack; updates: UpdateParam[] | UpdateParamInt[] }>()
);

export const reset = createAction('[MyStack] Reset');
export const load = createAction('[MyStack] Load');
export const loadClipIds = createAction(
  '[MyStack] Load Clip IDs',
  props<{ ids: { projectId: string; id: string }[] }>()
);
export const addClipIds = createAction('[MyStack] Add Clip IDs', props<{ ids: { projectId: string; id: string }[] }>());
export const addClip = createAction('[MyStack] Add Clip', props<{ clip: Clip }>());

export const addClipSuccess = createAction('[MySack] Add Clip Stuccess', props<{ clip: Clip }>());
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addClipFail = createAction('[MyStack] Add Clip Fail', props<{ clip: Clip; error?: any }>());
export const removeClip = createAction('[MyStack] Remove Clip', props<{ clip: Clip }>());
export const removeClipSuccess = createAction('[MyStack] Remove Clip Success', props<{ clip: Clip }>());
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeClipFail = createAction('[MyStack] Remove Clip Fail', props<{ clip: Clip; error?: any }>());
export const removeClipByIndex = createAction('[MyStack] Remove Clip By Index', props<{ index: number }>());
export const clearAllClips = createAction('[MyStack] Clear All Clips');

export const loadSuccess = createAction('[MyStack] Load Success', props<{ stack?: Partial<Stack> }>());
// export const loadClipsSuccess = createAction('[MyStack] Load Clips Success', props<{ clips: Clip[] }>());
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loadFail = createAction('[MyStack] Load Fail', props<{ error: any }>());
export const hydrated = createAction('[MyStack] Hydrated');

/**
 * Reordered in Editor
 */
export const reorderClipIds = createAction('[MyStack] Clips Re-Ordered', props<{ clipIds: string[] }>());

/**
 * Prop Updates
 */
export const updateProjectUrl = createAction('[MyStack] Update ProjectUrl', props<{ projectUrl: string }>());
export const updatePrivacy = createAction('[MyStack] Update Privacy', props<{ privacy: STACK_PRIVACY }>());
export const updateTitle = createAction('[MyStack] Update Title', props<{ title: string }>());
export const updatePoster = createAction('[MyStack] Update Poster', props<{ poster: string }>());
export const updateCredits = createAction('[MyStack] Update Credits', props<{ credits: string }>());
export const updateDescription = createAction('[MyStack] Update Description', props<{ description: string }>());
export const updateDuration = createAction('[MyStack] Update Duration', props<{ duration: string }>());
export const updateShareUrl = createAction('[MyStack] Update ShareUrl', props<{ shareUrl: string }>());
export const updatePublishedDate = createAction('[MyStack] Update dtePublished', props<{ dtePublished: string }>());
export const prePublishStack = createAction('[MyStack] Prepare Publish Stack', props<{ stack: Stack }>());
export const publishStack = createAction('[MyStack] Publish Stack', props<{ stack: Stack }>());

export const currentIndex = createAction('[MyStack] Set Current Index', props<{ index: number }>());
export const nextClip = createAction('[MyStack] Next Clip');
export const prevClip = createAction('[MyStack] Previous Clip');

// do not export return value
const actions = union({
  reset,
  loadClipIds,
  addClipIds,
  addClip,
  addClipSuccess,
  addClipFail,
  removeClip,
  removeClipSuccess,
  removeClipFail,
  removeClipByIndex,
  reorderClipIds,
  clearAllClips,
  hydrated,
  load,
  loadSuccess,
  // loadClipsSuccess,
  loadFail,
  updateProjectUrl,
  updatePrivacy,
  updateTitle,
  updatePoster,
  updateCredits,
  updateDescription,
  updateDuration,
  updateShareUrl,
  updatePublishedDate,
  publishStack,
  prePublishStack,
  currentIndex,
  nextClip,
  prevClip,
  newMyStack,
  updateMyStack,
});

// export only type
export type ActionsUnion = typeof actions;

/** Actions to ignore during local-storage-sync, for efficiency */
export const ignoreStorageSync = [
  load.type,
  loadClipIds.type,
  // loadClipsSuccess.type,
  loadSuccess.type,
  nextClip.type,
  prevClip.type,
];
