/**
 * example: https://github.com/chargebee/chargebee-checkout-samples/blob/master/api/front-end/angular/src/app/app.component.ts
 * @format
 */

/* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/member-ordering */
import _groupBy from 'lodash/groupBy';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';
import {
  AddOn,
  ChargebeeCustomer,
  ChargebeePlanId,
  ChargebeePlanType,
  ChargebeeSubscription,
  Plan,
  PORTAL_SECTIONS,
} from '../billing.model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
// import { State } from '@store/reducers';
import { ChargebeeApiService, ChargebeePlan, ChargebeePlanPrice } from '@app/billing/store/api/chargebee-api.service';
import { State } from '@app/billing/store/billing.reducer';
import { selectBilling } from '@app/billing/store/billing.selectors';
import * as billingActions from '@app/billing/store/billing.actions';
import * as projectActions from '@store/actions/projects.actions';
import { UserService } from '@app/core/services/user.service';

const DEBUG_LOGS = false;
const PAGE = '[Chargebee]';

/**
 * This aligns with API
 */
export interface OpenCheckoutParams {
  user_id: string;
  plan_id: ChargebeePlanId;
  price_id?: string;
  referral_code?: string;
  project_id?: string;
  project_name?: string;
  event_date?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  // company?: string;
  successRedirectPath?: string;
  subscriptionId?: string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  onCancel?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
  onSuccess?: (...args: any[]) => any;
  /* eslint-enable @typescript-eslint/no-explicit-any */
}

const convertChargebeePlan = (item: ChargebeePlan, prices: ChargebeePlanPrice[] = []): Plan | AddOn => {
  // modify to our Plan type
  const {
    id,
    name,
    external_name, //: string;
    description, //: string;
    status, //: string; 'active'
    // channel, //: string; 'web'
    enabled_for_checkout, //: boolean;
    enabled_in_portal, //: boolean;
    item_applicability, //: string;
    // item_family_id, //: string;
    metadata, //: any;
    metered,
    object, //: 'item'; // 'item'
    resource_version, //: number;
    type, //: 'plan' | 'addon'
    updated_at, //: number;
  } = item;

  // if type == 'addon' then it's an addOn, not a plan... but it looks the same as a plan..
  if ((type !== ChargebeePlanType.Plan && type !== ChargebeePlanType.AddOn) || object !== 'item') {
    console.warn(`Why not an known item type?`, item);
  }

  const planPrices = prices
    .filter((p) => p && p.item_id === id && p.status === 'active')
    .map((p) => ({
      id: p.id,
      name: p.external_name,
      currencyCode: p.currency_code,
      isTaxable: p.is_taxable,
      period: p.period, // number; // 1
      periodUnit: p.period_unit, //: string;// "month"
      // convert price to dollars from pennies
      price: !isNaN(p.price) && p.price > 0 ? p.price / 100 : 0, //number; //20000
      pricing_model: p.pricing_model, // string;// "flat_fee"
      ...(Array.isArray(p.tiers) && p.tiers.length > 0
        ? {
            tiers: p.tiers.map((t) => ({
              start: t.starting_unit,
              end: t.ending_unit,
              price: t.price > 0 ? t.price / 100 : 0,
            })),
          }
        : {}),
    }));
  // DEBUG_LOGS && console.log(`convertChargebeePlan`, {
  //   item, planPrices, prices
  // });

  const result = {
    id,
    name,
    title: external_name,
    description,
    isActive: status === 'active',
    itemType: item.type,
    prices: planPrices,
    // billingPeriod?: string; // 3 Months
    // cost?: number; // 500.00
    // costUnit? = '$'; //USD
    // pricingModel?: string; // Flat pricing

    // extra metadata
    enabled_for_checkout, //: boolean;
    enabled_in_portal, //: boolean;
    item_applicability, //: string;
    metadata, //: any;
    metered,
    resource_version,
    updated_at,
  };
  if (type === ChargebeePlanType.AddOn) {
    return new AddOn(result);
  }
  return new Plan(result);
};

@Injectable({
  providedIn: 'root',
})
export class ChargebeeService {
  billingInfo$: Observable<State> = this.store.select(selectBilling);

  private userId: string;

  /** https://www.chargebee.com/checkout-portal-docs/api.html#chargebee-instance-object */
  private cbInstance: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  private didInit = false;
  private didCreatePortalSession = false;

  constructor(
    private store: Store<State>,
    private platform: Platform,
    private userService: UserService,
    private chargebeeApi: ChargebeeApiService
  ) {}

  /**
   * v2: https://apidocs.chargebee.com/docs/api/items?prod_cat_ver=2#list_items
   * @todo if you want AddOns, need to enable and likely dev more...
   * called by Billing.Effects
   */
  async getPlans(): Promise<{ plans: Plan[]; addons?: AddOn[] }> {
    const { plans: cbPlans, prices } = await this.chargebeeApi.listPlans();
    // convert plans to Plan objects
    const { plan, addon } = _groupBy(
      cbPlans.map((item) => convertChargebeePlan(item, prices)),
      'itemType'
    );
    // return plans.map(item => convertChargebeePlan(item, prices));
    return { plans: plan as Plan[], addons: addon as AddOn[] };
  }

  // v1...

  /**
   * https://www.chargebee.com/checkout-portal-docs/api.html#logout
   * logout Chargebee’s session. If you do not call this function, the session will be valid for a maximum of one hour
   */
  logout() {
    this.instance.logout();
  }

  /**
   * This function is used to rebind all the events attached.
   * Use this function if a new element inserted dynamically after page load
   * https://www.chargebee.com/checkout-portal-docs/dropIn.html#registeragain
   */
  rebindElements(): void {
    try {
      this.Chargebee.registerAgain();
    } catch (error) {
      console.warn(`rebindElements caught:`, error);
    }
  }

  /**
   * https://www.chargebee.com/checkout-portal-docs/api.html#chargebee-object
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private get Chargebee() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return window && (window as any).Chargebee ? (window as any).Chargebee : {};
  }

  /**
   * https://www.chargebee.com/checkout-portal-docs/api.html#getinstance
   */
  private get instance() {
    return this.Chargebee.getInstance();
  }

  /**
   * https://www.chargebee.com/checkout-portal-docs/api.html#getportalsections
   */
  private get portalSections() {
    return this.Chargebee.getPortalSections();
  }

  /**
   * Cart
   * https://www.chargebee.com/checkout-portal-docs/dropIn.html#cart-object
   */
  // private get cbCart() {
  //   return this.cbInstance.getCart();
  // }

  /**
   * https://www.chargebee.com/checkout-portal-docs/dropIn.html#setcustomer
   */
  // setCartCustomer(customer: CustomerInput) {
  //   this.cbCart.setCustomer(customer)
  // }

  /**
   * https://www.chargebee.com/checkout-portal-docs/api.html#chargebee-object
   */
  init(): Promise<boolean> {
    const maxRetries = 3;
    let currentTry = 0;
    let retryTimeout;

    const doInit = (resolve, reject) => {
      if (!this.Chargebee || typeof this.Chargebee.init !== 'function') {
        if (currentTry < maxRetries) {
          DEBUG_LOGS && console.log(`Missing Chargebee window object... retrying...`);
          currentTry++;
          clearTimeout(retryTimeout);
          retryTimeout = setTimeout(() => doInit(resolve, reject), 300 * currentTry);
        } else {
          console.warn(`Chargebee not available on window after ${maxRetries} tries!`);
          return reject(false);
        }
      } else {
        this.cbInstance = this.Chargebee.init({
          site: this.chargebeeApi.chargebeeConfig.site,
          domain: this.chargebeeApi.chargebeeConfig.domain,
          /**
           * This will work only on mobile browsers.
           * Set true to open the Checkout or Portal as an iFrame on the same browser tab.
           *
           * When this is set to true,
           * If Checkout or Portal is opened from within an application, it opens in a WebView tab.
           * If Checkout or Portal is opened from a WebView tab, it opens on the same WebView tab.
           * If Checkout or Portal is opened from a browser tab, it opens on the same browser tab.
           * https://www.chargebee.com/checkout-portal-docs/api.html#chargebee-object
           */
          // iframeOnly: true,
          /**
           * end user will be redirected to chargebee checkout and self-serve portal page
           * instead of opening in a modal box.
           * Please make sure you have configured redirect URL in checkout and self-serve portal,
           * so that the user is able to navigate back to the app.
           */
          // enableRedirectMode: true, // this.platform.isMobile (just is safari or popups blocked?)
        });
        this.cbInstance.setCheckoutCallbacks(this.checkoutCallbacks);
        this.didInit = true;
        DEBUG_LOGS && console.log(`${PAGE} chargebeeInstance:`, this.cbInstance);

        // DEV: just to see them:
        if (DEBUG_LOGS) {
          console.log(`${PAGE} DEV portalSections:`, this.portalSections);
          // this.listHostedPages().then((res) => {
          //   DEBUG_LOGS && console.log(`${PAGE} DEV hostedPages:`, res);
          // });
        }

        resolve(this.didInit);
      }
    };

    return new Promise((resolve, reject) => {
      if (this.didInit && this.cbInstance) {
        DEBUG_LOGS && console.log(`${PAGE} instance already created`);
        return resolve(true);
      }
      return this.platform.ready().then(() => doInit(resolve, reject));
    });
  }

  /**
   * create a new hostedPageId for checkout a new subscription with customer details.
   * USAGE: embedded iframe (checkout-modal
   */
  createCheckoutHostedPageId({
    user_id,
    plan_id,
    price_id = '',
    referral_code = '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    successRedirectPath = '',
    project_id = '',
    project_name = '',
    event_date = '',
    email = '',
    first_name = '',
    last_name = '',
    phone = '',
  }: OpenCheckoutParams) {
    return this.chargebeeApi.generateCheckoutSession({
      user_id,
      plan_id,
      price_id,
      referral_code,
      project_id,
      project_name,
      event_date,
      email,
      first_name,
      last_name,
      phone,
      //company,
      // redirectSuccessPath: successRedirectPath,
    });
  }

  /**
   * create a new hostedPageId for checkout a new subscription with customer details.
   * USAGE: embedded iframe (checkout-modal
   */
  getEditCheckoutHostedPageId({
    subscriptionId,
    user_id,
    plan_id,
    price_id,
    referral_code,
    project_id,
    project_name,
    event_date,
    successRedirectPath = '',
  }: OpenCheckoutParams) {
    return this.chargebeeApi.generateCheckoutExisting({
      subscriptionId,
      user_id,
      plan_id,
      price_id,
      referral_code,
      project_id,
      project_name,
      event_date,
      redirectSuccessPath: successRedirectPath,
    });
  }

  /**
   * https://www.chargebee.com/checkout-portal-docs/api.html#closeall
   * close all Chargebee modals
   */
  private closeAll() {
    if (typeof this.instance?.closeAll === 'function') {
      try {
        this.instance.closeAll();
      } catch (error) {
        // ignore errors internal to cb
        // such as: event-listener.ts:196 TypeError: Cannot read properties of undefined (reading 'type')
      }
    }
  }

  /**
   * https://apidocs.chargebee.com/docs/api/hosted_pages#list_hosted_pages
   */
  private listHostedPages(): Promise<object> {
    return this.chargebeeApi.listHostedPages();
  }

  /**
   * Open the Billing Info Hosted Page
   */
  openBillingInfo() {
    this.openPortal({ section: PORTAL_SECTIONS.EDIT_BILLING_ADDRESS });
  }

  /**
   * Open the Payment Methods Hosted Page
   */
  openPaymentMethods() {
    this.openPortal({ section: PORTAL_SECTIONS.PAYMENT_SOURCES });
  }

  /**
   * Open the Purchase history Hosted Page
   */
  openPurchaseHistory() {
    this.openPortal({ section: PORTAL_SECTIONS.BILLING_HISTORY });
  }

  /**
   * https://www.chargebee.com/checkout-portal-docs/api.html#opensection
   * open a particular section as a separate card, instead of opening the entire customer portal
   * 
   * Only the below section types can be opened as a separate card
   *   SUBSCRIPTION_DETAILS
   *   ACCOUNT_DETAILS
   *   ADDRESS
   *   PAYMENT_SOURCES
   *   BILLING_HISTORY
   * 
   * cbPortal.openSection({
        sectionType: Chargebee.getPortalSections().ACCOUNT_DETAILS
      }
   */

  // portalCallbacks

  /**
   * USED v2 - but does not open on mobile..
   * https://www.chargebee.com/checkout-portal-docs/api.html#opencheckout
   */
  openCheckout({
    user_id,
    plan_id = ChargebeePlanId.Pro,
    price_id,
    referral_code,
    project_id,
    project_name,
    event_date = '',
    email = '',
    first_name = '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    successRedirectPath = '',
    /* eslint-disable @typescript-eslint/no-empty-function */
    onCancel = () => {},
    onError = () => {},
    onSuccess = () => {},
  }: /* eslint-enable @typescript-eslint/no-empty-function */
  OpenCheckoutParams) {
    if (!user_id) {
      console.log(`${PAGE} user not logged in -> no checkout session`);
      return;
    }
    const closeAll = () => this.closeAll();
    let ignoreCancel = false;
    this.cbInstance.logout();
    this.cbInstance.openCheckout({
      /**
       * hostedPage
       *
       * Hit your end point that returns hosted page object as response
       * This sample end point will call the below api
       * You can pass hosted page object created as a result of checkout_new, checkout_existing, manage_payment_sources
       * https://apidocs.chargebee.com/docs/api/hosted_pages#checkout_new_subscription
       * https://apidocs.chargebee.com/docs/api/hosted_pages#checkout_existing_subscription
       * https://apidocs.chargebee.com/docs/api/hosted_pages#manage_payment_sources
       * If you want to use paypal, go cardless and plaid, pass embed parameter as false
       *
       * @todo add callbacks? actions?
       */
      hostedPage: () =>
        this.chargebeeApi.generateCheckoutSession({
          user_id,
          plan_id,
          price_id,
          referral_code,
          project_id,
          project_name,
          event_date,
          email,
          first_name,
        }),
      loaded: function () {
        // Optional
        // will be called once checkout page is loaded
        console.log(`${PAGE} checkout loaded`);
      },
      error: function (error) {
        // Optional
        // will be called if the promise passed causes an error
        console.warn(`${PAGE} checkout error:`, error);
        switch (error.error_code) {
          case 'referenced_resource_not_found':
            console.log(`openCheckout msg: ${error.error_msg || error.message}`);

            break;
          default:
            console.log(`openCheckout error missed case: ${error.error_code}`);
        }
        onError(error);
      },
      step: function (step) {
        // Optional
        // will be called for each step involved in the checkout process
        console.log(`${PAGE} checkout step:`, step);
        if (step === 'thankyou_screen') {
          console.log('closing...');
          ignoreCancel = true;
          closeAll();
        }
      },
      success: (hostedPageId) => {
        // Optional
        // will be called when a successful checkout happens.
        console.log(`${PAGE} checkout hostedPageId:`, hostedPageId);
        // Hosted page id will be unique token for the checkout that happened
        // You can pass this hosted page id to your backend
        // and then call our retrieve hosted page api to get subscription details
        // https://apidocs.chargebee.com/docs/api/hosted_pages#retrieve_a_hosted_page

        this.updateBillingInfoWithHostedPage(hostedPageId);
        onSuccess(hostedPageId);
      },
      close: function () {
        /**
         * @dev look at this - related to modal close?
         */
        // Optional
        // will be called when the user closes the checkout modal box
        console.log(`${PAGE} checkout close`);
        if (!ignoreCancel) {
          onCancel();
        } else {
          ignoreCancel = false;
        }
      },
    });
  }

  private getHostedPage(id: string) {
    return this.chargebeeApi.retrieveHostedPage(id);
  }

  updateBillingInfoWithHostedPage(id: string) {
    this.getHostedPage(id)
      .then((res: { content?: { customer?: ChargebeeCustomer; subscription?: ChargebeeSubscription } }) => {
        if (res && res.content) {
          const customer = res.content.customer;
          const subscriptions: ChargebeeSubscription[] = [res.content.subscription];
          DEBUG_LOGS && console.log(`${PAGE} updateBillingInfoWithHostedPage info:`, { customer, subscriptions });
          this.store.dispatch(billingActions.loadCustomerSubscriptionsSuccess({ customer, subscriptions }));
        } else {
          console.warn(`${PAGE} getHostedPage no content?`, res);
        }
      })
      .catch((error) => {
        console.warn(`${PAGE} getHostedPage caught`, error);
      });
  }

  /**
   * @todo 2023-06-25 - is this still valid? Chargebee returning error:
   *   api_error_code: "configuration_incompatible"
   *   error_code: "portal_access_disabled_for_api"
   *   error_msg: "Customer portal access via API is disabled."
   *   message: "Customer portal access via API is disabled."
   * Set the Portal Session for current user
   */
  async setPortalSession() {
    // await this.init();
    this.billingInfo$.pipe(take(1)).subscribe(async (billingInfo) => {
      if (!billingInfo.userId) {
        try {
          this.store.dispatch(billingActions.createCustomer());
          // const res = await this.createCustomer();
          // console.log(`${PAGE} setPortalSession createCustomer res:`, res);
        } catch (error) {
          throw error;
        }
      }
      if (!billingInfo.userId) {
        DEBUG_LOGS && console.log(`${PAGE} user not logged in -> no portal session`);
        return;
      }
      if (this.didCreatePortalSession) {
        DEBUG_LOGS && console.log(`${PAGE} already created portal session`);
        return;
      }
      return this.cbInstance.setPortalSession(() => {
        // Hit your end point that returns portal session object as response
        // This sample end point will call the below api
        // https://apidocs.chargebee.com/docs/api/portal_sessions#create_a_portal_session
        this.didCreatePortalSession = true;
        return this.chargebeeApi.generatePortalSession(billingInfo.userId);
      });
    });
  }

  /**
   * https://www.chargebee.com/checkout-portal-docs/api.html#open
   * open Chargebee's self-serve portal
   */
  async openPortal({ section = '', subscriptionId = '' }) {
    try {
      await this.setPortalSession();
    } catch (error) {
      throw error;
    }

    const chargebeeApi = this.chargebeeApi;
    const store = this.store;

    /**
     * All callbacks per docs, not needed, but interesting...
     */
    const callbacks = {
      loaded: function () {
        // Optional
        // called when chargebee portal is loaded
        console.log(`${PAGE} portal loaded`);
      },
      close: function () {
        // Optional
        // called when chargebee portal is closed
        console.log(`${PAGE} portal close`);
      },
      visit: function (sectionName) {
        // Optional
        // called whenever the customer navigates across different sections in portal
        console.log(`${PAGE} portal visit`, sectionName);
      },
      paymentSourceAdd: function () {
        // Optional
        // called whenever a new payment source is added in portal
        console.log(`${PAGE} portal paymentSourceAdd`);
      },
      paymentSourceUpdate: function () {
        // Optional
        // called whenever a payment source is updated in portal
        console.log(`${PAGE} portal paymentSourceUpdate`);
      },
      paymentSourceRemove: function () {
        // Optional
        // called whenever a payment source is removed in portal.
        console.log(`${PAGE} portal paymentSourceRemove`);
      },
      subscriptionChanged: function (data) {
        // Optional
        // called whenever a subscription is changed
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
        console.log(`${PAGE} portal subscriptionChanged`, data);
      },
      subscriptionCustomFieldsChanged: function (data) {
        // Optional
        // called whenever a subscription custom fields are changed
        // data.subscription.id will give you the subscription id
        console.log(`${PAGE} portal subscriptionCustomFieldsChanged -> handle new EventDate`, data);
        try {
          // the user just modified the Event Date, we should handle
          chargebeeApi
            .getSubscription(data.subscription.id)
            .pipe(take(1))
            .subscribe((res) => {
              const subscription = res as ChargebeeSubscription;
              DEBUG_LOGS && console.log({ subscription });
              if (subscription?.cf_event_date && subscription?.cf_project_id) {
                store.dispatch(
                  projectActions.setSubscriptionEvent({
                    id: subscription.cf_project_id,
                    eventDate: subscription.cf_event_date,
                  })
                );
              }
            });
        } catch (error) {
          console.warn(error);
        }
      },
      subscriptionCancelled: function (data) {
        // Optional
        // called when a subscription is cancelled
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
        console.log(`${PAGE} portal subscriptionCancelled`, data);
        store.dispatch(
          billingActions.cancelSubscription({
            id: data.subscription.id,
          })
        );
      },
      subscriptionPaused: function (data) {
        // Optional
        // called when a subscription is Paused.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
        console.log(`${PAGE} portal subscriptionPaused`, data);
      },
      subscriptionResumed: function (data) {
        // Optional
        // called when a paused subscription is resumed.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
        console.log(`${PAGE} portal subscriptionResumed`, data);
      },
      scheduledPauseRemoved: function (data) {
        // Optional
        // called when the schedule to pause a subscription is removed for that subscription.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
        console.log(`${PAGE} portal scheduledPauseRemoved`, data);
      },
      subscriptionReactivated: function (data) {
        // Optional
        // called when a cancelled subscription is reactivated.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
        console.log(`${PAGE} portal subscriptionReactivated`, data);
      },
    };

    /**
     * if you want to redirect the user to a specific section while opening customer portal, the section name along with the supported params need to be passed
     * see https://www.chargebee.com/checkout-portal-docs/api.html#example-for-forwarding-the-user-to-a-specific-section-after-opening-customer-portal
      .open({callbacks},
      {
        sectionType: Chargebee.getPortalSections().EDIT_SUBSCRIPTION,
        params: {
          subscriptionId: "active_direct"
        }
      }
     */
    const portalSections = this.Chargebee?.getPortalSections() ?? {};
    if (section && DEBUG_LOGS) {
      console.log(`${PAGE} sectionParams:`, {
        portalSections,
        section,
        sectionType: portalSections[section],
      });
    }
    if (section && portalSections[section]) {
      this.cbInstance.createChargebeePortal().open(callbacks, {
        sectionType: portalSections[section],
        params: {
          ...(subscriptionId ? { subscriptionId } : {}),
        },
      });
    } else {
      this.cbInstance.createChargebeePortal().open(callbacks);
    }
  }

  /**
   * Add with cbInstance.setCheckoutCallbacks(this.checkoutCallbacks)
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private checkoutCallbacks(cart) {
    // cbInstance.setCheckoutCallbacks(this.checkoutCallbacks(cart))
    // you can define a custom callbacks based on cart object
    return {
      loaded: function () {
        console.log('checkout opened');
      },
      close: function () {
        console.log('checkout closed');
      },
      success: function (hostedPageId) {
        //  success: hostedPidDS4b2Z3s7C60dQqUXPiFsBQXcSFa91yq
        console.log('success: hostedPid' + hostedPageId);

        this.getHostedPage(hostedPageId)
          .then((res: { content?: object }) => {
            if (res && res.content) {
              DEBUG_LOGS && console.log(`${PAGE} checkout success getHostedPage:`, { content: res.content, res });
            } else {
              console.warn(`${PAGE} getHostedPage no content?`, res);
            }
          })
          .catch((error) => {
            console.warn(`${PAGE} getHostedPage caught`, error);
          });
      },
      step: function (value) {
        // value -> which step in checkout
        console.log(value);
      },
    };
  }
}
