/** @format */

import { Stack } from './stack.model';
import { Clip } from './clip.model';
import { User } from './user.model';
import { ProjectMember } from '@app/project-members/shared/project-member.model';
import { Project } from '@app/projects/shared/project.model';

export class LayoutOptions {
  showAvatar = true;
  hasAddNewItemButton = false;
  hasNewItemButtonAtEnd = false;
  isEvent = false;

  constructor(opts: object) {
    if (opts != null) {
      Object.entries(opts).forEach(([key, value]) => (this[key] = value));
    }
  }
}

/**
 * The List Layout Type
 */
export enum LAYOUT_TYPE {
  Carousel = 'CAROUSEL',
  List = 'LIST',
  Grid = 'GRID',
}

export interface LAYOUT_SETTINGS {
  type: LAYOUT_TYPE;
  options?: { [key: string]: string | boolean };
}

export type FlexiblePartialItem = Partial<(Clip | Stack | Project | ProjectMember | User)>;

/**
 * Type of Item for a List
 */
export enum ITEM_TYPE {
  Stacks = 'STACKS',
  Projects = 'PROJECTS',
  Clips = 'CLIPS',
  Users = 'USERS',
}

/**
 * What to show in the Indicator Chip for a Card / Slide
 */
export enum INDICATOR_PROP {
  Role = 'ROLE',
  ItemType = 'ITEM_TYPE',
  None = 'NONE',
}

export interface DiscoverCardClick {
  item: Stack | Clip | Project | ProjectMember | User;
  itemType: ITEM_TYPE;
  event: Event;
  /** optional action if there's something that needs handled */
  action?: string;
}

/*
 * deprecated - use List class from ./list.model
 * Used to create arrays for rendering lists in templates
 */
// export interface IList {
//   id: string;
//   name: string;
//   active: boolean;
//   items$: Observable<any[]>;
//   itemType?: ITEM_TYPE;
//   loading: boolean;
//   loaded?: boolean;
//   group?: string;
//   action?: string;
//   // // instead of canLoadMore, just show the loadMore slide/card and once clicked, hide it. add another loadmore if there's more to load
//   canLoadMore$?: Observable<string>;
//   nextToken?: string;
//   projectId?: string;
// };
