/**
 * Core.User.Service
 * @format
 */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Subscription, Observable, combineLatest, from } from 'rxjs';
import { filter, map } from 'rxjs/operators';
/**
 * The update of Amplify to v5 caused us to loose the onAuthUIStateChange
 * Using Service to access authData - not needed, it appears..
 * https://ui.docs.amplify.aws/angular/connected-components/authenticator/advanced
 */
// import { AuthenticatorService } from '@aws-amplify/ui-angular';
// copied these inline as they were removed from source - we totally lost onAuthUIStateChange..
import { CognitoUserInterface, AuthState, ICredentials } from '@app/core/api/api-types';
import { Auth } from 'aws-amplify';
import { Store } from '@ngrx/store';
import { State as UserState } from '@store/reducers/user.reducers';
import {
  getUserLoggedIn,
  getUserId,
  getUsername,
  getUserIdentityId,
  getUserAvatar,
  getUserEmail,
  getUserBio,
  getUserLocation,
  getUserNumClipsWatched,
  getUserGroups,
} from '@store/selectors/user.selectors';
import * as userActions from '@store/actions/user.actions';
import * as resetActions from '@store/actions/reset.actions';
import { EventsService, EventActions } from './events.service';
import {
  COGNITO_GROUP_ADMIN,
  COGNITO_GROUP_BETA_TESTERS,
  COGNITO_GROUP_KICKSTARTER,
  COGNITO_GROUP_WEDDING_PILOT,
  COGNITO_GROUP_DEVELOPERS,
} from '@app/core/api/users-api.service';
import { User, DEFAULT_USER_AVATAR } from '@app/shared/models/user.model';
import { SentryService } from './sentry.service';
import { AVATAR_URL_PREFIX } from '@app/app.config';
import { selectMyProjects } from '@store/selectors/projects.selectors';
import { ConfigService } from '../config/config.service';
import { EnvironService } from './environ.service';
import { Project } from '@app/projects/shared/project.model';
import { selectCanCapture } from '@app/billing/store/billing.selectors';

/** to handle 'require', app needs 'resolveJsonModule: true' on tsconfig.app */
declare const require: NodeRequire;
const awsconfig = require('../../../aws-exports').default;

/**
 * Cognito Auth Ref:
 * https://aws-amplify.github.io/amplify-js/api/classes/authclass.html
 */

/**
 * REFACTOR TO USER STORE (MVP-481)
 *
 * TODOs:
 *  EventActions.AVATAR_CHANGED
 *  remove:
 *    cachedAvatarUrl (studio-page & user-profile)
 *    loggedIn
 *
 * COMPONENTS TODO:
 *  StudioPage (UserProfile)
 *  PublishStackPage
 *  ProjectCrew
 *  CapturePage
 */

const DEBUG_LOGS = false;

const REHYDRATE_DELAY = 700; //ms

/**
 * NOTE: using SIGNOUT_GLOBAL true will force the user to be logged out on ALL devices
 * Not an ideal UX, but the only solution for now:
 *
 * Tracking solution, but over a year waiting
 * MAJOR Cognito issue: https://github.com/aws-amplify/amplify-js/issues/3435
 */
const SIGNOUT_GLOBAL = true;

const PAGE = '[UserService]';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  /*
     ngrx Store connections
   */
  public isLoggedIn$: Observable<boolean> = this.store.select(getUserLoggedIn);
  public userId$: Observable<string> = this.store.select(getUserId);
  public username$: Observable<string> = this.store.select(getUsername);
  public identityId$: Observable<string> = this.store.select(getUserIdentityId);
  public avatarUrl$: Observable<string> = this.store.select(getUserAvatar);
  public email$: Observable<string> = this.store.select(getUserEmail);
  public bio$: Observable<string> = this.store.select(getUserBio);
  public location$: Observable<string> = this.store.select(getUserLocation);
  public numClipsWatched$: Observable<number> = this.store.select(getUserNumClipsWatched);

  public userIsGlobalAdmin = (groups): boolean =>
    Array.isArray(groups) ? groups.includes(COGNITO_GROUP_ADMIN) : false;
  private _userGroups$: Observable<string[]> = this.store.select(getUserGroups);
  public userIsGlobalAdmin$: Observable<boolean> = this._userGroups$.pipe(
    filter((groups) => Array.isArray(groups)),
    //= (Array.isArray(groups) ? groups.includes(COGNITO_GROUP_ADMIN) : false)
    map(this.userIsGlobalAdmin)
  );

  public userIsDeveloper$: Observable<boolean> = this._userGroups$.pipe(
    map((groups) => (Array.isArray(groups) ? groups.includes(COGNITO_GROUP_DEVELOPERS) : false))
  );
  /** The user is part of Kickstarter Cognito Group */
  public userGroupIncludesKickstarter$: Observable<boolean> = this._userGroups$.pipe(
    map((groups) => (Array.isArray(groups) ? groups.includes(COGNITO_GROUP_KICKSTARTER) : false))
  );
  /** The user is part of WeddingPilot Cognito Group */
  public userGroupIncludesWeddingPilot$: Observable<boolean> = this._userGroups$.pipe(
    map((groups) => (Array.isArray(groups) ? groups.includes(COGNITO_GROUP_WEDDING_PILOT) : false))
  );
  public userGroupIncludesBeta$: Observable<boolean> = this._userGroups$.pipe(
    map((groups) => (Array.isArray(groups) ? groups.includes(COGNITO_GROUP_BETA_TESTERS) : false))
  );

  /**
   * @todo refactor this to billing status
   */
  public userHasUploadAccess$: Observable<boolean> = combineLatest([
    this.userIsGlobalAdmin$,
    this.userIsDeveloper$,
    this.userGroupIncludesKickstarter$,
    this.userGroupIncludesWeddingPilot$,
    this.userGroupIncludesBeta$,
  ]).pipe(
    map(
      ([isAdmin, isDeveloper, isKickstarter, isWeddingPilot, isBeta]) =>
        !!isAdmin || !!isDeveloper || !!isKickstarter || !!isWeddingPilot || !!isBeta
    )
  );

  /**
   * ViewModel for capture data
   * isCaptureAllowed: appConfig.allowCapture || hasProjects
   * @todo this should include subscription, or be renamed
   * selectCanCapture
   * selectCanCaptureToProject
   *
   */
  public isCaptureAllowed$: Observable<{ isAllowed: boolean; projects: Project[] }> = combineLatest([
    // this.userId$,
    this.store.select(selectMyProjects).pipe(filter((p) => typeof p !== 'undefined')),
    this.store.select(selectCanCapture),
    from(this.configService.appConfig).pipe(map((appConfig) => appConfig && appConfig.allowCapture)),
  ]).pipe(
    map(([projects, canCapture, allowCapture]) => {
      const hasProjects = Array.isArray(projects) && projects.length > 0;
      DEBUG_LOGS &&
        console.log(`userService isCaptureAllowed$ -> selectCanCapture`, {
          canCapture,
          projects,
          allowCapture,
          hasProjects,
        });
      return {
        isAllowed: canCapture || allowCapture, // || hasProjects,
        projects,
      };
    })
  );

  /**
   * @todo refactor & remove (MVP-481)
   */

  /**
   * @deprecated
   */
  public loggedIn: boolean = false; // TODO: use store
  /**
   * @deprecated
   */
  public cachedAvatarUrl: string = ''; // TODO: use store
  /**
   * @deprecated
   */
  private user: User = { userId: '' };
  /**
   * @deprecated
   */
  private didAuthCheck: boolean = false;
  /**
   * @deprecated
   */
  private subAuthChg: Subscription;
  //end remove

  /**
   * keep a reference to the current cognitoUser
   */
  private cognitoUser: CognitoUserInterface | undefined;
  private authState: AuthState = AuthState.Loading;

  constructor(
    private store: Store<UserState>,
    private configService: ConfigService,
    private environService: EnvironService,
    private sentryService: SentryService,
    private events: EventsService // private authenticator: AuthenticatorService
  ) {
    /**
     * 2023-06-28 removed. refactor @adminsOnly & @betaTestersOnly Decorator to remove this subscription
     */
    // this.subAuthChg = this.events.subscribe(EventActions.AUTH_CHANGE, this.onAuthChange);

    // this triggers the authenticator to check cookies, then setUser
    this.checkIsAuthenticated();

    /**
     * @todo amplify auth verification: this does not appear to do what we want...
     * authStatus is always == 'signedin'
     * @see onAuthUIStateChange
     */
    // this.authenticator.subscribe((res) => {
    //   if (!res || !res.authStatus) {
    //     console.warn(res);
    //     return;
    //   }
    //   this.onAuthUIStateChange(AuthState.SignedIn, res.user);
    // });
  }

  /**
   * Check if Authentication Cookie exists & isValid
   * used:
   *    login.page ionViewWillEnter
   *    user.service constructor !!
   */
  async checkIsAuthenticated(): Promise<boolean> {
    /**
     * Get current authenticated user
     * @returns Promise<CognitoUser | any>
     * A promise resolves to current authenticated CognitoUser if success
     */
    try {
      const user = await Auth.currentAuthenticatedUser();
      DEBUG_LOGS && console.log(`${PAGE} checkIsAuthenticated`, { user, authState: this.authState });
      this.setUser(user);
      return true;
    } catch (error) {
      console.log(error);
      // no need to await the reset,
      // but since this may be happening in constructor, setTimeout to resetUser after hydration
      setTimeout(() => {
        this.resetUser();
      }, 600);
      return false;
    }
  }

  /**
   * Amplify Auth Change
   * 2023-06-27 changed to aws-amplify@5 which broke this method
   * The service provides it, but this is despite warning in docs (we are not rendering the component):
   * > You must render the <amplify-authenticator> UI component before using AuthenticatorService.
   * > AuthenticatorService was designed to retrieve <amplify-authenticator> UI specific state
   * > such as route and user and should not be used without the UI component
   * https://ui.docs.amplify.aws/angular/connected-components/authenticator/advanced
   * old:
   * https://docs.amplify.aws/ui/auth/authenticator/q/framework/angular#onauthuistatechange
   */
  onAuthUIStateChange(authState: AuthState | string, authData = {}) {
    const wasAuthState = this.authState.valueOf();
    const user = authData as CognitoUserInterface;
    // if (!user) {
    //   DEBUG_LOGS && console.log(`${PAGE} NO USER onAuthUIStateChange '${authState}'`);
    //   return;
    // }
    DEBUG_LOGS && console.log(`${PAGE} onAuthUIStateChange '${authState}'`, user);
    return;
    try {
      // let signedIn = false;
      switch (authState) {
        case 'authenticated':
        case AuthState.SignedIn:
          if (!user) {
            throw new Error(`authState: 'signedIn' BUT NO USER?!?!`);
          }
          this.authState = AuthState.SignedIn;
          this.loggedIn = true;
          // if (authData && this.authState !== AuthState.SignedIn ) {
          // this.setUser(user);
          break;
        case 'unauthenticated':
        case AuthState.SignedOut: {
          DEBUG_LOGS && console.log(`${PAGE} authState: '${authState}'`);
          //TODO: Verify no need to handle signout and reset action?
          break;
        }
        case AuthState.SigningUp:
        case AuthState.ConfirmSignIn:
        case AuthState.ConfirmSignUp:
        case AuthState.ForgotPassword:
        case AuthState.SignIn:
        case AuthState.SignUp:
          this.authState = authState as AuthState;
          break;
        case 'configuring':
          // console.log(`authenticator.authStatus: ignoring '${authStatus}'`)
          break;
        default: {
          console.warn(`${PAGE} authState '${authState}' UNHANDLED`);
          this.authState = AuthState.Loading;
        }
      }
      if (this.authState !== wasAuthState) {
        this.store.dispatch(userActions.setAuthState({ authState }));
      }
      // this.events.publish(EventActions.AUTH_CHANGE, signedIn);
    } catch (error) {
      console.warn(`${PAGE} amplifyService.authStateChange$ caught:`, error);
      this.resetUser();
    }
  }

  /*
     ngrx Store Actions
     ex: 
        onSignin(username: string, password: string) {
          this.store.dispatch(userActions.signin({ username: username, password: password }));
        }


    // NOT YET IMPLEMENTED
       * SetEmail = '[User] Change Email',
       * ClipAddedToStack = '[User] Clip Added To Stack',
   */

  setUsername(username: string) {
    this.store.dispatch(userActions.setUsername({ username }));
  }
  setLocation(location: string) {
    this.store.dispatch(userActions.setLocation({ location }));
  }
  setBio(bio: string) {
    this.store.dispatch(userActions.setBio({ bio }));
  }

  stackPublished() {
    this.store.dispatch(userActions.stackPublished());
  }

  resetUser() {
    this.store.dispatch(userActions.reset());

    // todo: remove below (MVP-481)
    this.loggedIn = false;
    this.cognitoUser = null;
    this.user = { userId: '' };
    this.cachedAvatarUrl = '';
    this.events.publish(EventActions.AVATAR_CHANGED, { avatarUrl: DEFAULT_USER_AVATAR });
  }

  updateAnalytics(userState) {
    const user = {
      id: userState.identityId,
      userId: userState.userId,
      username: userState.username || userState.name || userState.userId,
      email: userState.email,
      groups: userState.groups,
    };
    DEBUG_LOGS && console.log(`${PAGE} updateAnalytics userState:`, { user, userState });
    this.events.publish('analytics:setUser', user);
    this.sentryService.setUser(user);
  }

  /**
   * @deprecated
   */
  // onAuthChange = () => {
  //   DEBUG_LOGS && console.log(`${PAGE} onAuthChange already didAuthCheck?:`, this.didAuthCheck);
  //   this.didAuthCheck = true;
  //   // we no longer need to watch it
  //   this.subAuthChg.unsubscribe();
  // };

  /**
   * @deprecated
   */
  // doAuthChange() {
  //   setTimeout(() => {
  //     this.events.publish(EventActions.AUTH_CHANGE, this.loggedIn);
  //   }, 100);
  // }

  /**
   * @deprecated
   */
  getUser() {
    return this.user;
  }

  /**
   * @deprecated use userId$ instead
   */
  getUserId() {
    return this.user && this.user.userId ? this.user.userId : '';
  }
  /**
   * @deprecated use userId$ instead
   */
  getUsername() {
    return this.getHandle();
  }
  /**
   * @deprecated use userId$ instead
   */
  getHandle() {
    return this.user && this.user.name ? this.user.name : this.getUserId();
  }
  /**
   * @deprecated use userId$ instead
   */
  getIdentityId() {
    return this.user && this.user.identityId ? this.user.identityId : '';
  }
  /**
   * @deprecated use userId$ instead
   */
  getIsAuth() {
    return this.loggedIn && this.getUser() != null;
  }

  /**
   * @todo REFACTOR
   */

  /**
   * removed from app
   * Used by @adminsOonly Decorator
   */
  // getIsAdmin() {
  //   // console.log(`${PAGE} getIsAdmin:`, this.user);
  //   return new Promise((resolve) => {
  //     let didSub = false;
  //     let subAuthChg: Subscription;
  //     try {
  //       const isAdminTest = () => {
  //         console.log(`${PAGE} isAdminTest groups:`, this.user.groups);
  //         if (didSub) subAuthChg.unsubscribe();
  //         resolve(
  //           this.getIsAuth() && Array.isArray(this.user.groups) && this.user.groups.indexOf(COGNITO_GROUP_ADMIN) > -1,
  //         );
  //       };
  //       if (!this.didAuthCheck) {
  //         didSub = true;
  //         subAuthChg = this.events.subscribe(EventActions.AUTH_CHANGE, isAdminTest);
  //       } else {
  //         isAdminTest();
  //       }
  //     } catch (e) {
  //       console.log(`${PAGE} getIsAdmin Error:`, e);
  //       resolve(false);
  //     }
  //   });
  // }

  /**
   * removed from app
   * Used by @betaTestersOnly Decorator
   */
  // getIsBetaTester() {
  //   // console.log(`${PAGE} getIsBetaTester:`, this.user);
  //   return new Promise((resolve) => {
  //     let didSub = false;
  //     let subAuthChg: Subscription;
  //     try {
  //       const isInGroupTest = () => {
  //         console.log(`${PAGE} isInGroupTest groups:`, this.user.groups);
  //         if (didSub) subAuthChg.unsubscribe();
  //         resolve(
  //           this.getIsAuth() &&
  //             Array.isArray(this.user.groups) &&
  //             (this.user.groups.indexOf(COGNITO_GROUP_BETA_TESTERS) > -1 ||
  //               this.user.groups.indexOf(COGNITO_GROUP_ADMIN) > -1),
  //         );
  //         // && (this.user.groups.indexOf(COGNITO_GROUP_BETA_TESTERS) > -1 ))); // test not admins
  //       };
  //       if (!this.didAuthCheck) {
  //         didSub = true;
  //         subAuthChg = this.events.subscribe(EventActions.AUTH_CHANGE, isInGroupTest);
  //       } else {
  //         isInGroupTest();
  //       }
  //     } catch (e) {
  //       console.log(`${PAGE} getIsBetaTester Error:`, e);
  //       resolve(false);
  //     }
  //   });
  // }

  //end TODO REFACTOR

  getAttributes(): Promise<{ Name: string; Value: string }[]> {
    return new Promise((resolve, reject) => {
      if (this.cognitoUser != null && typeof this.cognitoUser.getUserAttributes == 'function') {
        this.cognitoUser.getUserAttributes((err, data) => {
          DEBUG_LOGS && console.log(`${PAGE} getAttributes:`, data);
          // this.attributes = data;
          resolve(data);
        });
      } else {
        reject('!cognitoUser');
      }
    });
  }

  async getPreferredUsername(): Promise<string> {
    try {
      const attr = await this.getAttributes();
      if (Array.isArray(attr)) {
        // get Value from Name
        const handle = attr.find((a) => a.Name === 'preferred_username');
        if (handle && handle.Value) {
          return handle.Value;
        }
      }
      return '';
    } catch (error) {
      DEBUG_LOGS && console.log(`${PAGE} getPreferredUsername caught:`, error);
      return '';
    }
  }

  /**
   * preferred_username
   *
   * called by user.effects
   * @todo MVP-814
   */
  updatePublicUsername(name): Promise<string> {
    DEBUG_LOGS && console.log(`${PAGE} updatePublicUsername:`, { name });

    return Auth.updateUserAttributes(this.cognitoUser, {
      preferred_username: name,
    }).catch((err) => {
      console.warn('updateIdentityIdAttribute error:', err);
      throw err;
    });
  }

  getUserEmail(): Promise<string> {
    return this.getAttributes()
      .then((res) => {
        if (!Array.isArray(res)) {
          throw new Error('No user Attributes');
        }
        const arrEmail = res.filter((att) => att.Name === 'email').map((att) => att.Value);
        return Array.isArray(arrEmail) && arrEmail.length === 1 ? arrEmail[0] : '';
      })
      .catch((error) => {
        console.warn(`Caught:`, error);
        return '';
      });
  }

  /** this should be called if there is no identity_id */
  updateIdentityIdAttribute(user, identityId) {
    // let result =
    Auth.updateUserAttributes(user, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'custom:identity_id': identityId,
    }).catch((err) => {
      console.log('updateIdentityIdAttribute error:', err);
    });
    // console.log("updateIdentityIdAttribute result:",result); // SUCCESS
  }

  /**
   * using amplify protected s3
   * v1: Get a presigned URL of the file
   * ISSUE: if 404, a PSK url is still returned... ugg!
   * https://github.com/aws-amplify/amplify-js/issues/1135
   */
  // getAvatar(): Promise<any> {
  //   // avatarUrl$
  //   return this.getUserAvatar(this.getIdentityId()).then(res => {
  //     this.setAvatarUrl(res);
  //     return res;
  //   });
  // }

  getUserAvatarUrlVersioned(userId: string): string {
    return `${this.getUserAvatarUrl(userId)}?c=${new Date().getTime()}`;
  }

  getUserAvatarUrl(userId: string): string {
    return `${AVATAR_URL_PREFIX}${userId}.jpg`;
  }

  /*
    Deprecation references: refactored to UserCommunityStore and Observables
    
   * getPublicUserInfo => projectMemberService.getPublicUserInfo
   * getPublicUserName => projectMemberService.getPublicUsername
   * getPublicUserAvatar => projectMemberService.getUserAvatar
   */

  /**
   * Amplify Auth Login with username and password
   */
  async login(username, password): Promise<UserState> {
    DEBUG_LOGS && console.log(`${PAGE} login...`);
    await this.clearCredentials();
    return (
      Auth.signIn(username, password)
        // setUser will check credentials - use that..
        .then((user) => this.setUser(user))
        .catch((err) => {
          console.warn(`${PAGE} auth.signIn caught:`, err.message);
          this.resetUser();
          throw err;
        })
    );
  }

  /**
   * Amplify Auth Logout
   */
  async logout() {
    try {
      if (SIGNOUT_GLOBAL) {
        await Auth.signOut({ global: true });
      } else {
        await Auth.signOut();
      }
    } catch (error) {
      console.error(error);
    }
    this.clearCredentials();
    this.resetUser();
    this.store.dispatch(resetActions.resetStoreOnLogout());
    // this.doAuthChange();
  }

  /**
   * Due to shortcoming of AWS-Amplify, need to clear credentials manually & get new UNAUTH credentials
   * https://github.com/aws-amplify/amplify-js/issues/5696#issuecomment-641209027
   */
  async clearCredentials() {
    try {
      const creds: ICredentials = await Auth.currentCredentials();
      DEBUG_LOGS && console.log(`${PAGE} clearCredentials`, { creds, typeof: typeof creds });
      if (
        (creds?.message?.includes('Access to Identity') && creds?.message?.includes('is forbidden')) ||
        creds?.name === 'NotAuthorizedException'
      ) {
        window.localStorage.removeItem(`CognitoIdentityId-${awsconfig.aws_cognito_identity_pool_id}`);
        await Auth.currentCredentials();
      }
    } catch (error) {
      console.warn(`${PAGE} clearCredentials caught:`, error);
    }
  }

  /**
   * NEW_PASSWORD_REQUIRED
   * 
   * // https://docs.aws.amazon.com/cognito/latest/developerguide/using-amazon-cognito-identity-user-pools-javascript-example-authenticating-admin-created-user.html
   *  // User was signed up by an admin and must provide new 
      // password and required attributes, if any, to complete 
      // authentication.
   
      // userAttributes: object, which is the user's current profile. It will list all attributes that are associated with the user. 
      // Required attributes according to schema, which don’t have any values yet, will have blank values.
      // requiredAttributes: list of attributes that must be set by the user along with new password to complete the sign-in.
   
   
      // Get these details and call 
      // newPassword: password that user has given
      // attributesData: object with key as attribute name and value that the user has given.
   * 
   * You'll need to hold this user object, collect new password, then call 
   * Auth.completeNewPassword(user, new_password, requiredAttributes)
   * to complete the process.
   * 
   * challengeParam:
        requiredAttributes: []
        userAttributes:
          email: "jdgibbz@gmail.com"
          email_verified: "true"
   */
  completeNewPassword(newPassword, requiredAttributes): Promise<User> {
    DEBUG_LOGS && console.log(`${PAGE} completeNewPassword...`, requiredAttributes);
    return Auth.completeNewPassword(this.cognitoUser, newPassword, requiredAttributes).then((user) =>
      this.login(user.username, newPassword)
    );
  }

  /**
   * SignUp / Register as new user
   * @param username (string)
   * @param password (string)
   * @param attr (object) { email: string, phone_number: string }
   */
  async register(
    username,
    password,
    attr
  ): Promise<{
    user: CognitoUserInterface;
    userConfirmed: boolean;
    userSub: string;
    codeDeliveryDetails: {
      AttributeName: string;
      DeliveryMedium: string;
      Destination: string;
    };
  }> {
    const attributes: { email?: string; phone_number?: string } = {}; // other custom attributes
    if (attr.email) {
      attributes.email = attr.email; // optional
    }
    if (attr.phone_number) {
      attributes.phone_number = attr.phone_number; // optional - E.164 number convention
    }

    if (this.user?.identityId) {
      // most likely this will not exist, but if it does add it
      attributes['custom:identity_id'] = this.user.identityId;
    }

    return Auth.signUp({
      username,
      password,
      attributes,
      validationData: [], //optional
    });
  }

  /**
   * Amplify Auth Confirm Registration with username and code
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  confirmRegistration(username, code): Promise<any> {
    return Auth.confirmSignUp(username, code);
  }

  /**
   * Amplify Resend Registration Code for username
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resendRegistrationCode(username): Promise<any> {
    return Auth.resendSignUp(username);
  }

  /**
   * Amplify Auth Change Password oldPass, newPass
   * https://github.com/awslabs/aws-cognito-angular-quickstart/tree/master/src/app/public/auth/newpassword
   * https://github.com/awslabs/aws-cognito-angular-quickstart/blob/master/src/app/service/user-registration.service.ts#L77
   */
  changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
    return Auth.currentAuthenticatedUser()
      .then((user) => Auth.changePassword(user, oldPassword, newPassword))
      .then((data) => {
        console.log('Auth.changePassword result:', data); // 'SUCCESS'
        return true;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  /**
   * Amplify Auth Forgot Password with username
   * https://github.com/awslabs/aws-cognito-angular-quickstart/blob/master/src/app/service/user-login.service.ts#L69
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forgotPassword(username: string): Promise<any> {
    return Auth.forgotPassword(username);
  }

  /**
   * Amplify Forgot Password Submit email, code, pwd
   * https://github.com/awslabs/aws-cognito-angular-quickstart/blob/master/src/app/service/user-login.service.ts#L90
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  confirmNewPassword(email: string, code: string, password: string): Promise<any> {
    return Auth.forgotPasswordSubmit(email, code, password);
  }

  private setUserTimeout;
  private isSettingUser = false;
  /**
   * setUser is not a promise
   */
  private setUser(user: CognitoUserInterface): UserState {
    if (!user || Object.keys(user).length < 1) {
      DEBUG_LOGS && console.log(`${PAGE} no user to set...`);
      return;
    }
    DEBUG_LOGS && console.log(`${PAGE} setUser: ${user?.username ?? 'unknown'}`, user);

    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return {
        loggedIn: false,
        userId: user.username,
        cognitoUser: user,
      };
    }

    const userState: UserState = {
      loggedIn: true,
      userId: user.username,
      cognitoUser: user,
    };

    const setUserStore = (state: UserState) => {
      clearTimeout(this.setUserTimeout);
      this.setUserTimeout = setTimeout(() => {
        this.loggedIn = state.loggedIn;
        if (state.loggedIn) {
          state.avatar = this.getUserAvatarUrlVersioned(state.userId);
          DEBUG_LOGS && console.log(`${PAGE} setUser loginSuccess userState:`, state);

          this.store.dispatch(userActions.loginSuccess({ user: state }));
        } else {
          // this happens during signup and confirm flows, it's ok, no sentry needed here
          // this.sentryService.captureMessage(`setUser !loggedIn? [${userState.userId}]`);
        }
      }, REHYDRATE_DELAY);
    };

    this.cognitoUser = user;
    this.user.userId = user.username;
    const attributes = user.attributes || [];
    if (attributes.email) {
      userState.email = attributes.email;
      this.user.email = attributes.email;
    }
    if (user.signInUserSession && user.signInUserSession.idToken && user.signInUserSession.idToken.payload) {
      const sess = user.signInUserSession.idToken.payload;
      if (sess['cognito:groups'] && sess['cognito:groups'].length > 0) {
        userState.groups = sess['cognito:groups'];
        this.user.groups = sess['cognito:groups'];
      }
    }

    // this is possibly wrong due to caching, we need to try to get each time, it appears..
    // const handle = await this.getPreferredUsername(); // changed 2023-06-28 to take it if it exists
    if (attributes['preferred_username']) {
      userState.username = attributes['preferred_username'];
    }

    if (attributes['custom:identity_id']) {
      userState.identityId = attributes['custom:identity_id'];
      this.user.identityId = attributes['custom:identity_id'];
      setUserStore(userState);

      this.updateAnalytics(userState);
      return userState;
    } else {
      // no identity_id -> getCredentials
      DEBUG_LOGS && console.log('no identity_id -> getCredentials');
      //broken during Amplify Refactor ->
      // this.checkIsAuthenticated(); // not working here, the credentials were just lost along the way...
      this.getCredentials()
        .then((res) => {
          if (res.identityId) {
            this.user.identityId = res.identityId;
            userState.identityId = res.identityId;
            // set identityId on the user object
            this.updateIdentityIdAttribute(user, this.user.identityId);
            this.updateAnalytics(userState);
            setUserStore(userState);
            return userState;
          } else {
            console.warn('credentials missing identity_id?', res);
            userState.loggedIn = false;
            setUserStore(userState);
            return userState;
          }
        })
        .catch((err) => {
          console.warn(`${PAGE} getCredentials caught (still update the userStore?):`, err);
          userState.loggedIn = false;
          setUserStore(userState);
          return userState;
        });
      // return the state to the caller while we process the identity ID...
      return userState;
    }
  }

  private async getCredentials(): Promise<ICredentials> {
    const wasLoggedIn = !!this.loggedIn;
    try {
      const creds: ICredentials = await Auth.currentUserCredentials();
      // check isExpired
      if (!creds || !creds.authenticated) {
        DEBUG_LOGS && console.log(`${PAGE} not authenticated`);
        if (wasLoggedIn !== false) {
          this.loggedIn = false;
          // this.doAuthChange();
        }
        return { message: 'Not Authenticated', authenticated: false };
      } else if (creds.expired) {
        // not available in aws-amplify types?
        DEBUG_LOGS && console.log(`${PAGE} credentials Expired:`, creds.expireTime);
        if (wasLoggedIn !== false) {
          this.loggedIn = false;
          // this.doAuthChange();
        }
        return { message: 'Credentials Expired', authenticated: false };
      } else {
        // console.log(`${PAGE} credentials.get sub:`,this.sub, user);
        this.user.identityId = creds.identityId;
        if (wasLoggedIn !== true) {
          this.loggedIn = true;
          // this.doAuthChange();
        }
        return {
          identityId: creds.identityId,
          accessKeyId: creds.accessKeyId,
          secretAccessKey: creds.secretAccessKey,
          authenticated: true,
        };
      }
    } catch (error) {
      console.warn(`${PAGE} credentials.get error:`, error);
      this.loggedIn = false;
      if (wasLoggedIn !== false) {
        // this.doAuthChange();
      }
      throw error;
    }
  }
}
