/** @format */

import { Component, OnInit, Input } from '@angular/core';
import { NgIf, NgFor, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, share, take, distinctUntilChanged, shareReplay, withLatestFrom } from 'rxjs/operators';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import {
  NavController,
  Platform,
  MenuController,
  PopoverController,
  ModalController,
  IonMenuToggle,
  IonButton,
  IonIcon,
  IonItem,
  IonAvatar,
  IonLabel,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonList,
} from '@ionic/angular/standalone';
import { ToasterService } from '@app/core/services/toaster.service';
import { UserService } from '@app/core/services/user.service';
import { ENABLE_BILLING, HOMEPAGE } from '@app/app.config';
import { COMMUNITY_ROUTE, PROJECT_DETAIL_ROUTE, STUDIO_PAGE, STUDIO_SETTINGS_ROUTE } from '@app/app.routes';
import { ActionPopoverComponent } from '@app/shared/components/action-popover/action-popover.component';
import { VersionReloadComponent } from '@app/shared/components/version-reload/version-reload.component';
import { Utils } from '@app/shared/utils';
import { Store } from '@ngrx/store';
import { activeProject as environSetActiveProject } from '@store/actions/environ.actions';
import { selectMyProjects, selectMyProjectsActiveEvent } from '@store/selectors/projects.selectors';
import { BillingService } from '@app/billing/shared/services/billing.service';
import { SubscriptionMiniStatusEditComponent } from '@app/billing/shared/components/subscription-mini-status-edit/subscription-mini-status-edit.component';
import { Project, isEventActive } from '@app/projects/shared/project.model';
import { ProjectService } from '@app/projects/shared/services/project.service';
import { ProjectFormComponent } from '@app/projects/project-form/project-form.component';

interface ProjectVm extends Project {
  canEdit?: boolean;
  isEventActive?: boolean;
}

enum ProjectsSort {
  Alpha,
  Recent,
  ActiveEvent,
  Archived,
}

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  standalone: true,
  imports: [
    IonMenuToggle,
    IonButton,
    IonIcon,
    NgIf,
    IonItem,
    IonAvatar,
    IonLabel,
    SubscriptionMiniStatusEditComponent,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
    RouterLinkActive,
    RouterLink,
    IonList,
    NgFor,
    NgTemplateOutlet,
    VersionReloadComponent,
    AsyncPipe,
    TranslatePipe,
  ],
})
export class UserMenuComponent implements OnInit {
  @Input() menuId: string;

  enableArchivedProjects = false;
  enableBilling = ENABLE_BILLING;

  loggedIn$: Observable<boolean>;
  avatar$: Observable<string>;
  username$: Observable<string>;
  projects$: Observable<ProjectVm[]>;
  recentProjects$: Observable<ProjectVm[]>;
  projectsWithEvents$: Observable<ProjectVm[]>;
  archivedProjects$: Observable<ProjectVm[]>;

  routeHome = `/${HOMEPAGE}`;
  routeStudio = `/${STUDIO_PAGE}`;
  routeStudioSettings = `/${STUDIO_SETTINGS_ROUTE}`;
  routeProjectDetail = `/${PROJECT_DETAIL_ROUTE}`;
  routeCommunity = `/${COMMUNITY_ROUTE}`;

  projectActions = [
    // will be replaced by translated text OnInit
    { type: 'view', text: 'View Project', icon: 'eye-outline' }, // PROJECT.VIEW
    { type: 'edit', text: 'Edit Project', icon: 'create-outline' }, // PROJECT.EDIT
  ];

  /** sort feature */
  showSortToolbar = false;
  projectsSortType = ProjectsSort;
  projectsSort: ProjectsSort = ProjectsSort.Alpha;

  // /** @deprecated replaced with segment */
  // projectsSortRecent = 'Recent Projects'; // will be translated onInit
  // projectsSortAll = 'All Projects'; // will be translated onInit
  // projectsSortText = 'Projects'; // will be translated onInit
  // projectsSortLabel = 'View Recent'; // will be translated onInit
  // private _projectsSortActionRecent = 'View Recent'; // will be translated onInit
  // private _projectsSortActionName = 'View All'; // will be translated onInit
  // private _projectsSortRecentText = 'Recent Projects'; // will be translated onInit
  // private _projectsSortNameText = 'Projects'; // will be translated eventually

  /**
   * CONFIG
   */
  // public enableLinkToProjects = false; //SHOW_PROJECTS_IN_DISCOVER;

  constructor(
    private user: UserService,
    private projectService: ProjectService,
    private toaster: ToasterService,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private platform: Platform,
    private menu: MenuController,
    private store: Store,
    private billingService: BillingService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.loggedIn$ = this.user.isLoggedIn$.pipe(share());
    this.avatar$ = this.user.avatarUrl$;
    this.username$ = this.user.username$;

    this.platform.ready().then(() => {
      this.translate
        .get(['PROJECT.VIEW', 'PROJECT.EDIT'])
        .pipe(take(1))
        .subscribe((res) => {
          this.projectActions = [
            { type: 'view', icon: 'eye-outline', text: res['PROJECT.VIEW'] },
            { type: 'edit', icon: 'create-outline', text: res['PROJECT.EDIT'] },
          ];
        });

      this.projects$ = this.store
        .select(selectMyProjects)
        .pipe(filter((p) => typeof p !== 'undefined'))
        .pipe(
          withLatestFrom(this.user.userId$),
          map(([projects, userId]) =>
            projects.map((project) => ({
              ...project,
              canEdit: this.projectService.isProjectAdmin(project, userId),
              isEventActive: isEventActive(project),
            }))
          )
        );

      this.projectsWithEvents$ = this.store
        .select(selectMyProjectsActiveEvent)
        .pipe(filter((p) => typeof p !== 'undefined'))
        .pipe(
          withLatestFrom(this.user.userId$),
          map(([projects, userId]) =>
            projects.map((project) => ({
              ...project,
              canEdit: this.projectService.isProjectAdmin(project, userId),
              isEventActive: true,
            }))
          )
        );

      this.recentProjects$ = this.projectService.getRecentProjects().pipe(
        withLatestFrom(this.user.userId$),
        // distinctUntilChanged((a,b) => a && b && a.length === b.length), // this is missing the re-order event
        map(([projects, userId]) =>
          projects.map((project) => ({
            ...project,
            canEdit: this.projectService.isProjectAdmin(project, userId),
            isEventActive: isEventActive(project),
          }))
        ),
        shareReplay(1)
      );
      this.recentProjects$
        .pipe(distinctUntilChanged((a, b) => a && b && a.length === b.length))
        .subscribe((projects) => {
          // console.log(`recentProjects$:`, projects);
          // if there's recent projects, change the view
          this.showSortToolbar = projects && projects.length > 0;
          this.projectsSort = projects && projects.length > 0 ? ProjectsSort.Recent : ProjectsSort.Alpha;
          // this.updateSortText();
        });

      /** @todo: archived projects */
      // this.archivedProjects$
    });
  }

  // ngAfterViewInit() {
  //   // this will happen when the user-menu is loaded, to check for sub status
  //   // note that this happens early due to being in the user menu..
  //   // 2024-01-15 moved to UserEffects.loginSuccess to catch the login flow even after component loaded
  //   // if (this.enableBilling) {
  //   //     this.billingService.initUser();
  // }

  logout() {
    this.user.logout();
    this.openHomePage();
    this.toaster.present(this.translate.instant('AUTH.TEXT_MESSAGE_ON_LOGOUT'));
  }

  openHomePage() {
    this.navCtrl.navigateBack(this.routeHome);
  }

  navStudio() {
    this.navCtrl.navigateForward(this.routeStudio);
  }
  navEditProfile(event: MouseEvent) {
    event.stopPropagation(); // don't also navStudio
    this.navCtrl.navigateForward(this.routeStudioSettings);
    this.closeMenu(); //required since we're stoppingPropagation
  }

  closeMenu() {
    this.menu.close(this.menuId);
  }

  async openProjectMenu(event: MouseEvent, project) {
    event.stopPropagation();
    // console.log(`openProjectMenu`, { project });
    // const actions = this.projectActions;
    // if (isRecent) {
    //   actions.push({ type: 'remove', icon: 'remove', text: this.translate.instant('PROJECT.RECENT_REMOVE') })
    // }
    const popover = await this.popoverCtrl.create({
      component: ActionPopoverComponent,
      componentProps: {
        actions: this.projectActions,
        dismissOnEmit: true,
      },
      event,
    });
    await popover.present();
    const { data } = await popover.onWillDismiss();
    if (data && data.selected) {
      this.onProjectActionSelected(data.selected, project);
    }
  }

  onProjectActionSelected(actionType: string, project: Project) {
    if (!project || !project.id) {
      console.warn(`Missing ProjectId?`, { actionType, project });
      return;
    }
    switch (actionType) {
      case 'view': {
        this.navCtrl.navigateForward([this.routeProjectDetail, project.id]);
        break;
      }
      case 'edit': {
        this.navCtrl.navigateForward(['/project', project.id, 'edit']);
        break;
      }
      case 'remove': {
        this.projectService.removeFromRecent(project.id);
        break;
      }
      default:
        console.log(`UNHANDLED action ${actionType}`);
    }
    this.closeMenu();
  }

  /**
   * catch all clicks and stopPropagation to avoid closing the menu
   */
  stopPropogation(event) {
    Utils.tryStopPropagation(event);
  }
  removeRecent(event, project) {
    this.stopPropogation(event);
    this.projectService.removeFromRecent(project.id);
  }

  sortSegmentChanged(event) {
    this.stopPropogation(event);
    const { detail: { value = '' } = {} } = event;
    if (Object.values(ProjectsSort).includes(value as ProjectsSort)) {
      this.projectsSort = value as ProjectsSort;
    } else {
      this.projectsSort = ProjectsSort.Alpha;
    }
  }

  async createProject() {
    this.user.userId$.pipe(take(1)).subscribe(async (userId) => {
      const projectCreateModal = await this.modalCtrl.create({
        component: ProjectFormComponent,
        componentProps: {
          userId,
          // eventType: this.routeId,
        },
      });
      projectCreateModal.present();
      projectCreateModal.onDidDismiss().then((res) => {
        if (res?.data?.project?.id) {
          const projectId = res.data.project.id;
          this.store.dispatch(environSetActiveProject({ projectId }));
          this.navCtrl.navigateForward([this.routeProjectDetail, projectId]);
        }
      });
    });
  }

  // /** @deprecated replaced with segment */
  // private toggleSort(event) {
  //   Utils.tryStopPropagation(event);
  //   this.projectsSort = this.projectsSort === 'alpha' ? 'recent' : 'alpha';
  //   this.updateSortText();
  // }

  // /** @deprecated replaced with segment */
  // private updateSortText() {
  //   this.projectsSortText = this.projectsSort === 'alpha' ? this._projectsSortNameText : this._projectsSortRecentText;
  //   this.projectsSortLabel =
  //     this.projectsSort === 'alpha' ? this._projectsSortActionRecent : this._projectsSortActionName;
  // }
}
