/** @format */

import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular/standalone';

export enum ToasterPosition {
  Bottom = 'bottom',
  Middle = 'middle',
  Top = 'top',
}
export enum ToasterColor {
  Light = 'light',
  Dark = 'dark',
  Warning = 'warning',
  Danger = 'danger',
}

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private defaultDuration: number = 3000; //ms

  constructor(private toastCtrl: ToastController) {}

  async present(
    message: string,
    position: ToasterPosition = ToasterPosition.Top, // not ideal to cover up the tabbar
    duration?: number,
    color: ToasterColor = ToasterColor.Light
  ) {
    if (position !== ToasterPosition.Bottom && position !== ToasterPosition.Top) {
      position = ToasterPosition.Middle;
    }
    const toast = await this.toastCtrl.create({
      color,
      // The message for the toast. Long strings will wrap and the toast container will expand.
      message,
      // How many milliseconds to wait before hiding the toast. By default, it will show until dismiss() is called
      duration: duration || this.defaultDuration,
      // The position of the toast on the screen. Accepted values: "top", "middle", "bottom".
      position,
      buttons: [
        {
          icon: 'close-outline',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
    return toast.onDidDismiss();
  }
}
