/** @format */

import { createAction, props, union } from '@ngrx/store';
import { Stack } from '@app/shared/models/stack.model';
import { UpdateParam, UpdateParamInt } from '@app/core/api/api-types';
import { FilterEntity } from '@store/reducers/viewstate.reducers';
import { ShareResponse } from '@app/core/social-sharing/social-sharing.model';

export interface IAddToStackDrawerPayload {
  playlist: { projectId: string; id: string; order: number }[];  
  projectId?: string;
  title?: string;
  description?: string;
  poster?: string;
  isCreateNew?: boolean;
}

/**
 * ngrx 8
 */
/** removed SIZE_FETCH_ITEMS from the stack actions, as this is a list-managed thing */
// const SIZE_FETCH_ITEMS = 10;

export const subUpdate = createAction('[Stacks] Subscription Update', props<{ stack: Stack; userId?: string }>());
export const addDraft = createAction('[Stacks] Add Draft', props<{ stack: Stack }>());

/**
 * TopNav Drawer expansion, carrying payload of clips to add to a stack
 * if new stack
 * ref: this.mystackService.addClipIdsToMyStack([{ projectId: this.currentClip.projectId, id: this.currentClip.id }]);
 */
export const addClipIds = createAction(
  '[Stacks] Add Clip IDs to Playlist',
  props<{
    projectId: string;
    stackId: string;
    ids: { projectId: string; id: string }[];
  }>()
);
export const addToStackDrawer = createAction(
  '[Stacks] Select Stack to Add Playlist',
  props<IAddToStackDrawerPayload>()
);
export const addToStackIdPlaylist = createAction(
  '[Stacks] Add Playlist To Stack',
  props<{ projectId: string; stackId: string }>()
);
export const resetAddToStackPlaylist = createAction('[Stacks] Reset Add-To-Stack Playlist');

export const setFeatured = createAction(
  '[Stacks] Set featured',
  props<{
    projectId: string;
    stackId: string;
    featured: number;
    isApproved?: boolean;
    fromIdx?: number;
    toIdx?: number;
  }>()
);
export const setApproved = createAction(
  '[Stacks] Set isApproved',
  props<{ projectId: string; stackId: string; isApproved: boolean }>()
);
export const setPoster = createAction(
  '[Stacks] Set Poster Image',
  props<{ projectId: string; stackId: string; poster: string }>()
);
export const setCollaborative = createAction(
  '[Stacks] Set isCollaborative',
  props<{ projectId: string; stackId: string; isCollaborative: number; description?: string }>()
);

export const hydrated = createAction('[Stacks] Hydrated');
export const reset = createAction('[Stacks] Reset');
export const add = createAction('[Stacks] Add', props<{ stacks: Stack[] }>());
export const update = createAction(
  '[Stacks] Update',
  props<{ stack: Partial<Stack>; updates: UpdateParam[] | UpdateParamInt[] }>()
);
export const deleteStack = createAction('[Stacks] Delete', props<{ stack: Stack }>());

export const selectIdPlay = createAction(
  '[Stacks] Select By ID - Play',
  props<{ projectId: string; stackId: string }>()
);
export const selectIdEdit = createAction(
  '[Stacks] Select By ID - Edit',
  props<{ projectId: string; stackId: string }>()
);
export const resetIdEdit = createAction('[Stacks] Reset Edit');

export const load = createAction('[Stacks] Load');
export const loadById = createAction('[Stacks] Load By ID', props<{ projectId: string; stackId: string }>());
export const loadSuccess = createAction(
  '[Stacks] Load Success',
  props<{
    stacks: Stack[];
    filters?: FilterEntity;
    listId?: string; // string to allow for projectId nextTokens
    nextToken?: string;
    isLoadMore?: boolean; // @simwicki is this ok as an optional prop? (jd changed it)
  }>()
);
export const loadFail = createAction(
  '[Stacks] Load Fail',
  props<{ error: string; projectId?: string; stackId?: string }>()
);
export const noMoreToLoad = createAction('[Stacks] No More To Load', props<{ listId: string }>());

export const loadFilteredStacks = createAction(
  '[Stacks] Load Filtered Stacks',
  (payload: { listId: string; filters: FilterEntity; limit?: number; apiLimit?: number }) => ({
    // limit: SIZE_FETCH_ITEMS,
    // apiLimit: SIZE_FETCH_ITEMS,
    nextToken: '',
    ...payload,
  })
);
export const loadMoreFilteredStacks = createAction(
  '[stacks] Load More Filtered Stacks',
  (payload: {
    listId: string;
    filters: FilterEntity;
    nextToken: string;
    limit?: number; // the requested limit from UI
    apiLimit?: number; // keep track of our current limit sent to api, separate from the requested limit from UI
  }) => ({
    // limit: SIZE_FETCH_ITEMS,
    // apiLimit: SIZE_FETCH_ITEMS,
    ...payload,
  })
);

export const loadProjectFeaturedStacks = createAction(
  '[Stacks] Load Featured By Project ID',
  props<{ projectId: string }>()
);
export const loadMoreProjectFeaturedStacks = createAction(
  '[Stacks] Load More Featured By Project ID',
  props<{ projectId: string }>()
);

export const loadProjectRecentStacks = createAction(
  '[Stacks] Load Recent By Project ID',
  props<{ projectId: string }>()
);
export const loadMoreProjectRecentStacks = createAction(
  '[Stacks] Load More Recent By Project ID',
  props<{ projectId: string }>()
);

export const loadPlaylistSuccess = createAction(
  '[Stacks] Load Playlist Success',
  props<{
    // clips?: Clip[];
    ids?: { projectId: string; id: string }[];
    // current?: string;
    // currentIndex?: number;
  }>()
);

/*
  Migrated from Playlist Store
*/

export const createStackPlaylist = createAction('[Stacks] Create Stack Playlist', props<{ stack: Stack }>());
export const playCurrentIndex = createAction(
  '[Stacks] New Current Play Index',
  props<{ index: number; isPlayingAgain?: 0 | 1 }>()
);
export const playNextClip = createAction('[Stacks] Play Next Clip');
export const playPrevClip = createAction('[Stacks] Play Previous Clip');
export const watchComplete = createAction('[Stacks] Watch Complete', props<{ projectId: string; stackId: string }>());
export const share = createAction(
  '[Stacks] Share',
  props<{ projectId: string; stackId: string; response: ShareResponse }>()
);
// do not export return value
const actions = union({
  add,
  addClipIds,
  addDraft,
  addToStackDrawer,
  addToStackIdPlaylist,
  createStackPlaylist,
  deleteStack,
  hydrated,
  load,
  loadById,
  loadFail,
  loadFilteredStacks,
  loadMoreFilteredStacks,
  loadMoreProjectFeaturedStacks,
  loadMoreProjectRecentStacks,
  loadProjectFeaturedStacks,
  loadProjectRecentStacks,
  loadSuccess,
  noMoreToLoad,
  playCurrentIndex,
  playNextClip,
  playPrevClip,
  reset,
  resetAddToStackPlaylist,
  resetIdEdit,
  selectIdEdit,
  selectIdPlay,
  setApproved,
  setFeatured,
  setCollaborative,
  setPoster,
  share,
  subUpdate,
  update,
  watchComplete,
});

// export only type
export type ActionsUnion = typeof actions;

/** Actions to ignore during local-storage-sync, for efficiency */
export const ignoreStorageSync = [
  loadFilteredStacks.type,
  loadMoreFilteredStacks.type,
  noMoreToLoad.type,
  resetAddToStackPlaylist.type,
  loadSuccess.type,
  add.type,
  createStackPlaylist.type,
  loadPlaylistSuccess.type,
  subUpdate.type,
];
