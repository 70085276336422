/** @format */

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { IonButton } from '@ionic/angular/standalone';

@Component({
  selector: 'app-consent-banner',
  templateUrl: './consent-banner.component.html',
  styleUrls: ['./consent-banner.component.scss'],
  standalone: true,
  imports: [IonButton, RouterLink, TranslatePipe],
})
export class ConsentBannerComponent implements OnInit {
  @Output() accept = new EventEmitter<string>();

  isShown = false;

  ngOnInit(): void {
    setTimeout(() => {
      this.isShown = true;
    }, 1000);
  }
}
