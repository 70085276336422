/** @format */

import { Component, Input } from '@angular/core';
import { NgStyle } from '@angular/common';
import { IonTextarea } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormComponent } from '../base-form.component';
import { FormItemComponent } from '../form-item/form-item.component';

@Component({
  selector: 'fs-form-textarea',
  templateUrl: './form-textarea.component.html',
  styleUrls: ['./form-textarea.component.scss'],
  standalone: true,
  imports: [FormItemComponent, NgStyle, IonTextarea],
})
export class FormTextareaComponent extends BaseFormComponent {
  @Input() autoGrow = false;
  @Input() rows = 4;
  @Input() placeholder = '';
  @Input() hint: string = '';

  constructor(translate: TranslateService) {
    super(translate);
  }
}
