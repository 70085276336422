/** @format */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular/standalone';
import { of, from, EMPTY } from 'rxjs';
import {
  tap,
  withLatestFrom,
  filter,
  concatMap,
  mergeMap,
  catchError,
  map,
  switchMap,
  take,
  delay,
  exhaustMap,
} from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { selectUserState, getUserId, getUserSubscriptionState } from '../selectors/user.selectors';
import * as userActions from '../actions/user.actions';
import * as stackActions from '../actions/stacks.actions';
import * as projectActions from '../actions/projects.actions';
import * as clipAction from '../actions/clips.actions';
import {
  activeProject as environActiveProject,
  recentProjects as environRecentProjects,
} from '../actions/environ.actions';
import { State as UserState } from '../reducers/user.reducers';
import { State as EnvironState } from '../reducers/environ.reducers';
import { selectRecentProjectsIds } from '../selectors/environ.selectors';
import { ProjectGroup } from '../selectors/projects.selectors';
import { loadCustomerSubscriptionsSuccess } from '@app/billing/store/billing.actions';
import { selectSubscriptionSummary } from '@app/billing/store/billing.selectors';
import { BillingService } from '@app/billing/shared/services/billing.service';
import { AnalyticsService } from '@app/core/services/analytics/analytics.service';
import { SentryService } from '@app/core/services/sentry.service';
import { UserService } from '@app/core/services/user.service';
import { ToasterPosition, ToasterService } from '@app/core/services/toaster.service';
import { UpdateParam, UpdateParamInt } from '@app/core/api/api-types';
import { UsersApiService } from '@app/core/api/users-api.service';
import { DEFAULT_USER_AVATAR, User } from '@app/shared/models/user.model';
import { HOME_PAGE, HOME_PAGE_ON_LOGIN_EFFECT } from '@app/app.routes';
import { CoreLogicApiService } from '@app/core/api/core-logic-api.service';
import { ENABLE_BILLING } from '@app/app.config';
import { environment } from 'src/environments/environment';

const DEBUG_LOGS = false;
const PAGE = '[UserStoreEffects]';

// const FIELD_TO_DETERMINE_FULL_ENTITY = 'likes'; // isArray

@Injectable()
export class UserEffects {
  /**
   * This effect does not yield any actions back to the store. Set
   * `dispatch` to false to hint to @ngrx/effects that it should
   * ignore any elements of this effect stream.
   *
   * The `defer` observable accepts an observable factory function
   * that is called when the observable is subscribed to.
   * Wrapping the database open call in `defer` makes
   * effect easier to test.
   */

  /* ACTIONS:

    on(UserActions.loginSuccess, (state, { user }) => ({ 
      ...state, 
      ...user,
      loggedIn: true,
    })),
    
    on(UserActions.setAvatar, (state, { avatar }) => ({ ...state, avatar })),
    on(UserActions.setUsername, (state, { username }) => ({ ...state, username })),
    on(UserActions.setEmail, (state, { email }) => ({ ...state, email })),
    on(UserActions.setLocation, (state, { location }) => ({ ...state, location })),
    on(UserActions.setBio, (state, { bio }) => ({ ...state, bio })),
    

    on(UserActions.clipAddedToStack, state => ({ ...state, numClipsAddedToStack: state.numClipsAddedToStack + 1 })),
    on(UserActions.stackPublished, state => ({ ...state, numStacksPublished: state.numStacksPublished + 1 })),

  */

  /**
   * On Set Public Name / Handle / Preferred_username
   * @todo MVP-814
   */
  userSetHandle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.setUsername.type),
        concatMap((action) => of(action).pipe(withLatestFrom(this.store$.pipe(select(selectUserState))))),
        filter(([action, state]) => state.userId && state.userId.length > 0), // if no userId, we're not logged in => stop
        mergeMap(([action, state]) =>
          from(this.userService.updatePublicUsername(action.username)).pipe(
            tap((res) => {
              DEBUG_LOGS && console.log(`${PAGE} userService.updatePublicUsername res:`, res);
              const prop = 'name';
              const updates: UpdateParam[] = [
                {
                  prop,
                  value: action.username,
                },
              ];
              DEBUG_LOGS && console.log(`${PAGE} action -> api ${prop}`, { action, updates });
              from(this.userApi.updateUser(state, updates)).pipe(
                tap((updateRes) => {
                  DEBUG_LOGS && console.log(`${PAGE} userApi.updateUser res:`, { updateRes });
                  this.analyticsService.userUpdated({ prop, value: action.username, userId: state.userId });
                }),
                catchError((error) => {
                  this.captureError(error, 'userSetHandle updateUser caught');
                  return EMPTY;
                })
              );
            }),
            catchError((error) => {
              if (error && error.code && error.code === 'AliasExistsException') {
                console.log(`${PAGE} Cognito:`, error.message);
                this.toaster.present(
                  `Sorry, that name is already used - please try a different username.`,
                  ToasterPosition.Top
                );
                return of(userActions.setUsernameFail);
              } else {
                this.captureError(error, 'userSetHandle caught');
                return EMPTY;
              }
            })
          )
        )
        // ));
      ),
    { dispatch: false }
  );

  /**
   * Update RecentProjects On setActive Project or select Project MVP-1089
   */
  updateUserRecentProjects$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(environActiveProject.type, projectActions.selectById.type, projectActions.removeRecent.type),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.select(getUserId)),
            withLatestFrom(this.environStore$.select(selectRecentProjectsIds))
          )
        ),
        map(([[action, userId], recentProjectIds]) => ({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          id: (action as any).id || (action as any).projectId,
          userId,
          recentProjectIds,
          action,
        })),
        filter(({ id, userId, recentProjectIds }) => userId && userId.length > 0), // if no userId, we're not logged in => stop
        exhaustMap(({ id, userId, recentProjectIds, action }) => {
          // since this is in an Effect, we should be able to assume that the recentProjectIds includes the id, right?
          // if not, import newRecentProjectIds from environ.reducer

          // is it first? if not doUpdate..
          // let doUpdate = recentProjectIds.indexOf(id) !== 0; // always will be..
          // if (action && (action as any).type === projectActions.removeRecent.type) {}
          // already done in reducer:
          // recentProjectIds = recentProjectIds.filter(projectId => projectId !== id)
          !environment.production &&
            console.log(`DEV: Effect.updateUserRecentProjects`, {
              existsIdx: recentProjectIds.indexOf(id),
              id,
              userId,
              recentProjectIds,
              action,
            });
          const updates: UpdateParam[] = [
            {
              prop: 'recentProjects',
              value: recentProjectIds,
            },
          ];
          this.userApi.updateUser({ userId }, updates).catch((error) => {
            this.captureError(error, 'updateUserRecentProjects caught');
          });

          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  setUserAvatar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.setAvatar.type),
        concatMap((action) => of(action).pipe(withLatestFrom(this.store$.pipe(select(selectUserState))))),
        // if no userId, we're not logged in => stop
        // if default avatar, ignore
        filter(
          ([action, state]) =>
            state.userId && state.userId.length > 0 && action?.avatar && action.avatar !== DEFAULT_USER_AVATAR
        ),
        tap(([action, state]) => {
          this.updateProp(action, state, 'avatar');
        })
      ),
    { dispatch: false }
  );

  setUserLocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.setLocation.type),
        concatMap((action) => of(action).pipe(withLatestFrom(this.store$.pipe(select(selectUserState))))),
        filter(([action, state]) => state.userId && state.userId.length > 0), // if no userId, we're not logged in => stop
        tap(([action, state]) => {
          this.updateProp(action, state, 'location');
        })
      ),
    { dispatch: false }
  );

  setUserCountry$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.setCountry.type),
        concatMap((action) => of(action).pipe(withLatestFrom(this.store$.pipe(select(selectUserState))))),
        filter(([action, state]) => state.userId && state.userId.length > 0), // if no userId, we're not logged in => stop
        tap(([action, state]) => {
          this.updateProp(action, state, 'country');
        })
      ),
    { dispatch: false }
  );

  setUserBio$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.setBio.type),
        concatMap((action) => of(action).pipe(withLatestFrom(this.store$.pipe(select(selectUserState))))),
        filter(([action, state]) => state.userId && state.userId.length > 0), // if no userId, we're not logged in => stop
        tap(([action, state]) => {
          this.updateProp(action, state, 'bio');
        })
      ),
    { dispatch: false }
  );

  userStackPublished$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.stackPublished.type),
        concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(getUserId)))),
        filter(([action, userId]) => userId && userId.length > 0), // if no userId, we're not logged in => stop
        tap(([action, userId]) => {
          this.incrementProp(action, userId, 'numStacksPublished');
        })
      ),
    { dispatch: false }
  );

  userStackWatched$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stackActions.watchComplete.type),
        concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(getUserId)))),
        filter(([action, userId]) => userId && userId.length > 0), // if no userId, we're not logged in => stop
        tap(([action, userId]) => {
          this.incrementProp(action, userId, 'numStacksWatched');
        })
      ),
    { dispatch: false }
  );

  userClipWatched$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clipAction.play.type),
        concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(getUserId)))),
        filter(([action, userId]) => userId && userId.length > 0), // if no userId, we're not logged in => stop
        tap(([action, userId]) => {
          this.incrementProp(action, userId, 'numClipsWatched');
        })
      ),
    { dispatch: false }
  );

  /**
   * update userData DB if BILLING SUBSCRIPTION not correct
   */
  loadCustomerSubscriptionsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadCustomerSubscriptionsSuccess),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.select(selectSubscriptionSummary)),
            withLatestFrom(this.store$.select(getUserSubscriptionState))
          )
        ),
        map(([[action, state], userSubState]) => {
          DEBUG_LOGS &&
            console.log(`${PAGE} loadCustomerSubscriptionsSuccess$ pre-filter`, { action, state, userSubState });
          // const { level, minutes, status } = state;
          // const { subscriptionLevel, subscriptionMinutes, subscriptionStatus } = userSubState;
          return {
            action,
            state,
            userSubState,
          };
        }),
        // check if userState (UserData) is correct, if not, update
        filter(({ action, state, userSubState }) => {
          const { level, minutes, status } = state;
          const { subscriptionLevel, subscriptionMinutes, subscriptionStatus } = userSubState;
          return level !== subscriptionLevel || status !== subscriptionStatus || minutes !== subscriptionMinutes;
        }),
        // Update the UserData DB with Chargebee Subscription Status forcurrent loggedIn User
        switchMap(({ action, state, userSubState }) => {
          const { level, minutes, status } = state;
          const { subscriptionLevel, subscriptionMinutes, subscriptionStatus, userId, subscriptionId } = userSubState;
          const updates: UpdateParam[] = [];
          if (level !== subscriptionLevel) {
            updates.push({
              prop: 'subscriptionLevel',
              value: level,
            });
          }
          if (status !== subscriptionStatus) {
            updates.push({
              prop: 'subscriptionStatus',
              value: status,
            });
          }
          if (minutes !== subscriptionMinutes && (minutes > 0 || subscriptionMinutes !== undefined)) {
            updates.push({
              prop: 'subscriptionMinutes',
              value: minutes,
            });
          }
          DEBUG_LOGS &&
            console.warn(`${PAGE} loadCustomerSubscriptionsSuccess$ post-filter -> data changed -> api`, {
              action,
              updates,
            });
          return this.userApi.updateUser({ ...state, userId }, updates).catch((error) => {
            this.captureError(error, `userSet BillingSubscription updateUser caught`);
          });
        })
      ),
    { dispatch: false }
  );
  // )));

  /**
   * On Signin Success, update API
   */
  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.loginSuccess.type),
        concatMap((action) => of(action).pipe(withLatestFrom(this.store$.pipe(select(selectUserState))))),
        tap(([action, state]) => {
          DEBUG_LOGS && console.log(`${PAGE} action pre-filter`, action);
        }),
        // ensure user and user.userId exist
        filter(([action, state]) => state && state.userId && state.userId.length > 0),
        tap(([action, state]) => {
          DEBUG_LOGS && console.log(`${PAGE} action post-filter`, action);
          // this.userApi.effectDidAuth(state);
          /**
           * if current route is /home, navigate to /stack/home
           */
          DEBUG_LOGS && console.log(`ROUTE:`, this.router.url);
          if (!this.router.url || this.router.url === `/${HOME_PAGE}`) {
            this.navCtrl.navigateRoot([`/${HOME_PAGE_ON_LOGIN_EFFECT}`], { animated: false });
          }
        }),
        switchMap(([action, state]) => {
          DEBUG_LOGS && console.log(`${PAGE} loginSuccess switchMap load userData`, { action });

          if (ENABLE_BILLING) {
            DEBUG_LOGS && console.log(`${PAGE} loginSuccess -> initUser...`);
            try {
              this.billingService.initUser();
            } catch (error) {
              console.warn(`${PAGE} loginSuccess initUser caught:`, error);
            }
          }

          return from(this.userApi.getUserWithProjects(state.userId, true)).pipe(
            tap((currentUser) => {
              DEBUG_LOGS && console.log(`${PAGE} userData response`, { currentUser, state });
            }),
            map((currentUser) => currentUser as User),
            mergeMap((currentUser) =>
              // ternary if (iif is moody)
              currentUser && currentUser.userId && currentUser.userId.length > 0
                ? // currentUser exists in DB, update
                  from(this.userApi.effectUpdateOnAuth(currentUser as User, state)).pipe(
                    switchMap((userState: User) => {
                      /**
                       * 1. projectsAction.loadSuccess(projects)
                       * 2. load the projectIds from memberProjects
                       * 3. memberActions.loadSuccess(memberProjects)
                       * 4. Update the UserState with new data (updateUserData)
                       * 5. Update analytics with the projects
                       * 6. Update the Environ.recentProjects if exists
                       * 7. Get any Recent Projects that are not mine
                       */
                      // DEBUG_LOGS && console.log(`${PAGE} updated`, { userState, currentUser });

                      const projects =
                        currentUser && Array.isArray(currentUser.projects) && currentUser.projects.length > 0
                          ? currentUser.projects
                          : [];
                      const memberProjects =
                        currentUser &&
                        Array.isArray(currentUser.memberProjects) &&
                        currentUser.memberProjects.length > 0
                          ? currentUser.memberProjects
                          : [];

                      DEBUG_LOGS &&
                        console.log(`${PAGE} updated`, { projects, memberProjects, userState, currentUser });
                      /**
                       * Update the store with Projects user OWNS
                       */
                      if (projects.length > 0) {
                        // * 1. projectsAction.loadSuccess(projects)
                        // actually adding them here, as the memberProjects will follow a different flow
                        this.store$.dispatch(
                          projectActions.loadSuccess({
                            projects,
                            nextToken: null, //((user as any).projects.nextToken as string),
                            listId: ProjectGroup.Mine,
                            filters: {
                              id: `my_projects`,
                              userId: currentUser.userId,
                            },
                            selected: null,
                            userId: state.userId,
                          })
                        );
                      }

                      DEBUG_LOGS && console.log(`forkJoin result:`, { currentUser, projects, memberProjects });

                      // * 4. Update the UserState with new data (updateUserData)
                      if (currentUser.avatar === DEFAULT_USER_AVATAR) {
                        delete currentUser.avatar;
                      }
                      this.store$.dispatch(userActions.updateUserData({ user: currentUser }));

                      // * 5. Update analytics with the projects
                      this.analyticsService.updateUserProjects({
                        userId: state.userId,
                        // identityId: state.identityId,
                        projectsOwned: projects,
                        projectRoles: memberProjects,
                      });

                      // * 6. Update the Environ.recentProjects if exists
                      if (Array.isArray(currentUser.recentProjects) && currentUser.recentProjects.length > 0) {
                        this.store$.dispatch(environRecentProjects({ ids: currentUser.recentProjects }));
                      }

                      // subscribe to the data streams
                      this.coreApi.watchAllData();

                      // * 2. load the projectIds from memberProjects
                      return of(memberProjects).pipe(
                        take(1),
                        tap((projectMembers) => {
                          DEBUG_LOGS && console.log(`pre-delay getProjectsFromUserMemberIds`, projectMembers);
                        }),
                        // add a delay to allow other calls to complete (fill store before re-loading same) _difference
                        delay(1300),
                        map((projectMembers) => [
                          ...projectMembers.map((p) => p.projectId).filter((id) => id && id.length > 0),
                        ]),
                        tap((projectIds) => {
                          DEBUG_LOGS && console.log(`getProjectsFromUserMemberIds pluck projectIds:`, projectIds);
                        }),
                        // * 3. memberActions.loadSuccess(memberProjects)
                        filter((projectIds) => projectIds && projectIds.length > 0),
                        map((projectIds) => {
                          this.store$.dispatch(
                            projectActions.loadBatchIds({ ids: projectIds, group: ProjectGroup.Mine })
                          );
                          // * 7. Get any Recent Projects that are not mine
                          if (Array.isArray(currentUser.recentProjects) && currentUser.recentProjects.length > 0) {
                            // projectIds
                            const needToGet = currentUser.recentProjects.reduce((prev, curr) => {
                              if (curr && !projectIds.includes(curr)) {
                                return [...prev, curr];
                              }
                              return prev;
                            }, []);
                            if (needToGet.length > 0) {
                              this.store$.dispatch(
                                projectActions.loadBatchIds({ ids: needToGet, group: ProjectGroup.Recent })
                              );
                            }
                          }
                          // ? [
                          //   ,

                          // ]
                          // : EMPTY
                          return EMPTY;
                        }),

                        catchError((e) => {
                          console.warn(`${PAGE} loginSuccess getProjectsFromUserMemberIds caught`, e);
                          this.captureError(e, `loginSuccess getProjectsFromUserMemberIds caught`);
                          return EMPTY;
                        })
                      );
                    }),
                    // tap(results => { console.log(`mergeAll getProjects result:`, results)}),
                    catchError((e) => {
                      console.warn(`${PAGE} loginSuccess effectUpdateOnAuth caught`, e);
                      this.captureError(e, `loginSuccess effectUpdateOnAuth caught`);
                      return EMPTY;
                    })
                  )
                : // no userId in DB, create...
                  from(this.userApi.createUser(state)).pipe(
                    map((newUser) => {
                      DEBUG_LOGS && console.log(`${PAGE} createUser res`, newUser);
                      // this.analyticsService.userUpdated({ prop: 'created', value: state., userId: state.userId });
                      return EMPTY;
                    }),
                    catchError((e) => {
                      console.warn(`${PAGE} loginSuccess createUser caught`, e);
                      this.captureError(e, `loginSuccess createUser caught`);
                      return EMPTY;
                    })
                  )
            )
          ); //end userApi.getUserWithProjects pipe
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions<userActions.ActionsUnion>,
    private store$: Store<UserState>,
    private environStore$: Store<EnvironState>,
    private userService: UserService,
    private userApi: UsersApiService,
    private coreApi: CoreLogicApiService,
    private billingService: BillingService,
    private sentryService: SentryService,
    private toaster: ToasterService,
    private router: Router,
    private navCtrl: NavController,
    private analyticsService: AnalyticsService
  ) {}

  /**
   * Helper method for Errors
   */
  private captureError(error, msg = ''): void {
    const e = error && Array.isArray(error.errors) && error.errors.length > 0 ? error.errors[0] : error;
    console.warn(`${PAGE} ${msg} captureError`, e);
    this.sentryService.captureError(e);
  }

  private updateProp(action, state: UserState, prop: string) {
    const updates: UpdateParam[] = [
      {
        prop,
        value: action[prop],
      },
    ];
    // DEBUG_LOGS &&
    console.log(`${PAGE} action -> api ${prop}`, { action, updates });
    this.userApi.updateUser(state, updates).catch((error) => {
      this.captureError(error, `userSet${prop.charAt(0).toUpperCase() + prop.slice(1)} updateUser caught`);
    });
  }

  private incrementProp(action, userId: string, prop: string) {
    const updates: UpdateParamInt[] = [
      {
        prop,
        value: 1,
      },
    ];
    DEBUG_LOGS && console.log(`${PAGE} action -> api ${prop}`, { action, updates });

    if (environment.production) {
      this.userApi.incrementPublic(userId, updates).catch((error) => {
        this.captureError(error, `incrementProp '${prop}' caught`);
      });
    } else {
      DEBUG_LOGS && console.log(`User.${prop} not incremented on db, only if env.production`);
    }
  }
}
