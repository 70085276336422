/** @format */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { EMPTY, from, of } from 'rxjs';
import { tap, withLatestFrom, filter, mergeMap, map, catchError, concatMap, switchMap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { State as StackState } from '../reducers/index';
import * as stackActions from '../actions/stacks.actions';
import * as clipActions from '../actions/clips.actions';
import * as resetActions from '../actions/reset.actions';
import { selectListEntities } from '@store/selectors/lists.selectors';
import { getUserId } from '@store/selectors/user.selectors';
import { selectStackEntities, selectStacksState } from '@store/selectors/stacks.selectors';
import { getId, StackGroup, getProjectStacksGroup } from '../selectors/stacks.selectors';
import { FilterEntity, FilterEntityTypes } from '@store/reducers/viewstate.reducers';
import { UpdateParam, UpdateParamInt } from '@app/core/api/api-types';
import { StacksApiService, DEFAULT_FETCH_LIMIT_STACKS, MAX_FETCH_LIMIT_STACKS } from '@app/core/api/stacks-api.service';
import { AnalyticsService } from '@app/core/services/analytics/analytics.service';
import { SentryService } from '@app/core/services/sentry.service';
import { getCollabPrivacy, SEARCH_START_STACK_ID, Stack } from '@app/shared/models/stack.model';
import { environment } from 'src/environments/environment';

const DEBUG_LOGS = false;
const PAGE = '[StackStoreEffects]';

const FIELD_TO_DETERMINE_EXISTS = 'stackId'; //FIELD_TO_DETERMINE_FULL_ENTITY;

@Injectable()
export class StacksEffects {
  /**
   * This effect does not yield any actions back to the store. Set
   * `dispatch` to false to hint to @ngrx/effects that it should
   * ignore any elements of this effect stream.
   *
   * The `defer` observable accepts an observable factory function
   * that is called when the observable is subscribed to.
   * Wrapping the database open call in `defer` makes
   * effect easier to test.
   */

  /**
   * On addDraft
   */
  addStackDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stackActions.addDraft.type),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectStacksState)))),
      filter(([{ stack }, state]) => stack && stack.projectId && stack.stackId.length > 0),
      mergeMap(([{ stack }, state]) => {
        const id = getId(stack.projectId, stack.stackId);
        // const updates: UpdateParam[] = [];
        // for (const [key, value] of Object.entries(stack)) {
        //   updates.push({ prop: key, value });
        // }

        DEBUG_LOGS && console.log(`${PAGE} action: addStackDraft$ mergeMap -> api.createStackDraft`, { stack });
        return from(this.stacksApi.createStackDraft(stack)).pipe(
          tap((res) => {
            if (!res || !res.stackId) {
              throw new Error(`Stack Api Error: ${stack.projectId}/${stack.stackId}`);
            }
          }),
          filter((res) => res && res.stackId === stack.stackId),
          switchMap((res) => [
            // on add draft we are incrementing views here!
            stackActions.createStackPlaylist({ stack: res }),
          ]),

          catchError((error) => {
            if (!error || typeof error.message !== 'string' || !error.message.startsWith('Stack not found:')) {
              this.sentryService.captureError(error);
            }
            return of(
              stackActions.loadFail({
                error: error.message || error,
                projectId: stack.projectId,
                stackId: stack.stackId,
              })
            );
          })
        );
      })
    )
  );

  /**
   * addClipIds action should cause updates in api and store
   * append to end of playlist, incrementing order
   * also verify that the clips exist in store?
   * // 1. call api with updates that were already done in the reducer...
   * // 2. resetAddToStackPlaylist
   */
  addPlaylistToStack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stackActions.addToStackIdPlaylist.type),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.select(selectStacksState)),
            withLatestFrom(this.store$.select(getUserId))
          )
        ),
        filter(([[{ projectId, stackId }, state], userId]) => projectId && stackId && stackId.length > 0),
        map(([[{ projectId, stackId }, state], userId]) => {
          !environment.production &&
            console.log(`addToStackIdPlaylist effect`, {
              projectId,
              stackId,
              state,
              playlist: state.addToStackPlaylist,
            });
          return { id: getId(projectId, stackId), projectId, stackId, state, userId };
        }),
        filter(
          ({ id, projectId, stackId, state, userId }) =>
            id &&
            state.entities[id] &&
            Array.isArray(state.entities[id].playlist) &&
            state.entities[id].playlist.length > 0
        ),
        // 1. call api with updates that were already done in the reducer...
        // 2. resetAddToStackPlaylist
        mergeMap(({ id, projectId, stackId, state, userId }) => {
          const stack = state.entities[id];
          const playlist = stack.playlist;

          const updates: UpdateParam[] = [
            {
              prop: 'playlist',
              value: playlist,
            },
            {
              prop: 'updatedBy',
              value: userId,
            },
          ];

          DEBUG_LOGS &&
            console.log(`${PAGE} action: addPlaylistToStack$ mergeMap -> api.updateStack`, {
              projectId,
              stackId,
              updates,
            });
          return from(this.stacksApi.updateStack(stack, updates)).pipe(
            tap((res) => {
              if (!res || !res.stackId) {
                throw new Error(`Stack Api Error: ${stack.projectId}/${stack.stackId}`);
              }
            }),
            filter((res) => res && res.stackId === stack.stackId),
            switchMap((res) => [
              stackActions.resetAddToStackPlaylist(),
              // stackActions.update({ stack: res, updates }),
              // stackActions.createStackPlaylist({ stack: res }),
            ]),

            catchError((error) => {
              if (!error || typeof error.message !== 'string' || !error.message.startsWith('Stack not found:')) {
                this.sentryService.captureError(error);
              }
              return of(
                stackActions.loadFail({
                  error: error.message || error,
                  projectId: stack.projectId,
                  stackId: stack.stackId,
                })
              );
            })
          );
        })
      )
    //  { dispatch: false },
  );

  /**
   * On update
   * * @todo if there is already a playlist, we will be replacing & losing if not saved - need to notify with a ConfirmBox?
   */
  updateStack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stackActions.update.type),
        concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectStacksState)))),
        filter(([{ stack, updates }, state]) => stack && stack.projectId && stack.stackId.length > 0),
        tap(([{ stack, updates }, state]) => {
          !environment.production && console.log(`todo?: updateStack$ effect`, { stack, updates, state });
        })
        //  mergeMap(([{ stack, updates }, state]) => {
        // const id = getId(stack.projectId, stack.stackId);
        // const updates: UpdateParam[] = [];
        // for (const [key, value] of Object.entries(stack)) {
        //   updates.push({ prop: key, value })
        // }

        // DEBUG_LOGS &&
        //   console.log(`${PAGE} action: addStackDraft$ mergeMap -> api.createStackDraft`, { stack });
        // return from(this.stacksApi.updateStack(stack, updates)).pipe(
        //   tap((res) => {
        //     if (!res || !res.stackId) {
        //       throw new Error(`Stack Api Error: ${stack.projectId}/${stack.stackId}`)
        //     }
        //   }),
        //   filter((res) => res && res.stackId === stack.stackId),
        //   switchMap((res) => [
        //     // stackActions.update({ stack: res, updates }),
        //     stackActions.createStackPlaylist({ stack: res }),
        //   ]),

        //   catchError((error) => {
        //     if (!error || typeof error.message !== 'string' || !error.message.startsWith('Stack not found:')) {
        //       this.sentryService.captureError(error);
        //     }
        //     return of(stackActions.loadFail({ error: error.message || error, projectId: stack.projectId, stackId: stack.stackId }));
        //   }),
        // );
        // }),
      ),
    { dispatch: false }
  );

  /**
   * On Add, get the entity if not exists..
   * @note that the ListEffects is handling featured and list-related effects
   */
  subUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stackActions.subUpdate.type),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.select(selectStackEntities)),
            withLatestFrom(this.store$.select(selectListEntities))
          )
        ),
        map(([[{ stack }, state], listState]) => ({ stack, state, listState })),
        filter(({ stack, state, listState }) => stack && stack.projectId && stack.stackId && stack.stackId.length > 0),
        mergeMap(({ stack, state, listState }) => {
          // we already checked if (item.userId === userId || myProjectIds.includes(item.projectId))
          const { projectId, stackId } = stack;
          const id = getId(projectId, stackId);
          if (!state[id] || !state[id].title) {
            // so we do indeed want this, but it does not currently exist - allow the StackEffect to handle loading it
            DEBUG_LOGS && console.log(`[StackEffect] subUpdate new:`, { stack });
            return from(this.stacksApi.getStack(projectId, stackId)).pipe(
              filter((res) => res && res.stackId === stackId),
              mergeMap((newStack) => [stackActions.add({ stacks: [newStack] })])
            );
          }
          return EMPTY;
        }),
        catchError((error) => {
          this.captureError(error, 'subUpdate caught');
          this.sentryService.captureError(error);
          return EMPTY;
        })
      )
    // { dispatch: false },
  );

  /**
   * On Set Collaborative Draft
   */
  setStackCollaborative$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stackActions.setCollaborative.type),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.select(selectStacksState)),
            withLatestFrom(this.store$.select(getUserId))
          )
        ),
        filter(
          ([[action, state], userId]) => action && action.projectId && action.stackId && action.stackId.length > 0
        ),
        mergeMap(([[{ projectId, stackId, isCollaborative, description }, state], userId]) => {
          const privacy = getCollabPrivacy(isCollaborative);
          // DEBUG_LOGS &&
          console.warn(`${PAGE} action -> api`, { projectId, stackId, isCollaborative, privacy, description });
          return from(
            this.updateProps(
              ['isCollaborative', 'privacy', ...(description ? [description] : [])],
              { projectId, stackId, isCollaborative, privacy, description },
              userId
            )
          );
        }),
        catchError((error) => {
          this.captureError(error, 'setStackApproved caught');
          this.sentryService.captureError(error);
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  /**
   * On Feature Stack
   */
  setStackFeatured$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stackActions.setFeatured.type),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.select(selectStacksState)),
            withLatestFrom(this.store$.select(getUserId))
          )
        ),
        filter(
          ([[action, state], userId]) => action && action.projectId && action.stackId && action.stackId.length > 0
        ),
        mergeMap(([[{ projectId, stackId, featured, isApproved }, state], userId]) => {
          DEBUG_LOGS && console.log(`${PAGE} action -> api`, { projectId, stackId, featured, isApproved, userId });
          return from(this.stacksApi.updateStackFeatureApprove({ projectId, stackId, featured, isApproved, userId }));
        }),
        catchError((error) => {
          this.captureError(error, 'setStackApproved caught');
          this.sentryService.captureError(error);
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  /**
   * On Approve Stack
   */
  setStackApproved$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stackActions.setApproved.type),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.select(selectStacksState)),
            withLatestFrom(this.store$.select(getUserId))
          )
        ),
        filter(
          ([[action, state], userId]) => action && action.projectId && action.stackId && action.stackId.length > 0
        ),
        mergeMap(([[{ projectId, stackId, isApproved }, state], userId]) => {
          this.analyticsService.stackApproved({ projectId, stackId, isApproved, userId });
          DEBUG_LOGS && console.log(`${PAGE} action -> api`, { projectId, stackId, isApproved });
          return from(this.stacksApi.updateStackFeatureApprove({ projectId, stackId, isApproved, userId }));
        }),
        catchError((error) => {
          this.captureError(error, 'setStackApproved caught');
          this.sentryService.captureError(error);
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  /**
   * On Set Poster Image
   */
  setStackPoster$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stackActions.setPoster.type),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.select(selectStacksState)),
            withLatestFrom(this.store$.select(getUserId))
          )
        ),
        filter(
          ([[action, state], userId]) =>
            action && action.projectId && action.stackId && action.poster && action.poster.length > 0
        ),
        tap(([[action, state], userId]) => {
          DEBUG_LOGS && console.log(`${PAGE} action -> api`, { action });
          this.stacksApi.updateStackPoster(action.projectId, action.stackId, action.poster, userId).catch((error) => {
            this.captureError(error, 'setStackPoster caught');
          });
        })
      ),
    { dispatch: false }
  );

  /**
   * when selecting a Stack, be sure the entity in the store exists
   * if not, grab the full item from the api
   */
  selectStack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stackActions.selectIdPlay.type, stackActions.selectIdEdit.type),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectStacksState)))),
      // stop here if it's a search-start-stack
      filter(([action, state]) => {
        DEBUG_LOGS && console.log(`${PAGE} selectStack$`, action);
        // why are we seeing the io appended?
        const { stackId } = action;
        return stackId !== SEARCH_START_STACK_ID;
      }),
      mergeMap(([action, state]) => {
        const { projectId, stackId } = action;
        const id = getId(projectId, stackId);
        /** as string[] this was necessary due to ngrx entity key being of type number or string... ugg */
        const stateIds = state.ids as string[];
        const existingStack =
          stateIds.indexOf(id) > 0 && state.entities[id] && state.entities[id][FIELD_TO_DETERMINE_EXISTS]
            ? state.entities[id]
            : null;

        if (existingStack) {
          DEBUG_LOGS &&
            console.log(`${PAGE} selectStack$ we already have the Stack, just load clips if needed`, {
              existingStack,
              action,
            });
          if (Array.isArray(existingStack.playlist) && existingStack.playlist.length > 0) {
            // we have the Stack, just make sure we have the clips
            return of(stackActions.createStackPlaylist({ stack: existingStack }));
            // return of(clipAction.loadBatchIds({ ids: existingStack.playlist.map(item => ({ projectId: item.projectId, id: item.id })) }));
          }
          return EMPTY;
        }

        DEBUG_LOGS &&
          console.log(`${PAGE} action: selectStack$ mergeMap -> api.getStack`, {
            projectId,
            stackId,
            action,
            stateIds: state.ids,
          });
        return from(this.stacksApi.getStack(projectId, stackId)).pipe(
          tap((stack) => {
            DEBUG_LOGS && console.log(`***${PAGE} action: '${action.type}' api.getStack response:`, stack);
            if (!stack || !stack.stackId) {
              throw new Error(`Stack not found: ${projectId}/${stackId}`);
            }
          }),
          filter((res) => res && res.stackId === stackId),
          switchMap((stack) => [
            stackActions.add({ stacks: [stack] }),
            stackActions.createStackPlaylist({ stack }),
            // clipAction.loadBatchIds({ ids: (stack.playlist || []).map(item => ({ projectId: item.projectId, id: item.id })) }),
          ]),

          catchError((error) => {
            if (!error || typeof error.message !== 'string' || !error.message.startsWith('Stack not found:')) {
              this.sentryService.captureError(error);
            }
            return of(stackActions.loadFail({ error: error.message || error, projectId, stackId }));
          })
        );
      })
    )
  );

  /**
   * Create Playlist from a Stack
   * Clip Logic (not loading existing clips, notifying errors) handled in clipActions.loadBatchIds
   * Playlist Selector getPlaylistClips handles checking against ClipStore for undefined or missing entites
   */
  createStackPlaylist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stackActions.createStackPlaylist.type),
      concatMap((action) => of(action)),
      filter(({ stack }) => stack && Array.isArray(stack.playlist)),
      map(({ stack }) => {
        const actionIds = stack.playlist
          .filter((c) => c.projectId && c.id)
          .map((clip) => ({ projectId: clip.projectId, id: clip.id }));
        DEBUG_LOGS && console.log(`${PAGE} createStackPlaylist$ :`, { actionIds, stack });
        // add missing clips to store
        // this.store$.dispatch(clipActions.loadBatchIds({ ids: actionIds, stack }));
        // return actionIds;
        return clipActions.loadBatchIds({ ids: actionIds, stack });
      }),
      // this is not necessary, can be removed and { dispatch: false } added to this Effect
      // map((ids) => stackActions.loadPlaylistSuccess({ ids })),
      catchError((error) => of(stackActions.loadFail({ error })))
    )
  );

  /**
   * v2.8 ListsStore
   * includes loadStackSuccessNextToken$ logic, to avoid useless loadSuccess resolvers
   *
   * 2022-09-02 jd update logic to check ListState
   *  if the filters match && there's no nextToken, then done
   */
  loadFilteredStacks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stackActions.loadFilteredStacks.type, stackActions.loadMoreFilteredStacks.type),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store$.pipe(select(selectListEntities))))),
      mergeMap(([{ listId, filters, limit, nextToken, apiLimit }, listEntities]) => {
        const projectId = (filters as FilterEntity)?.projectId;
        const userId = (filters as FilterEntity)?.userId;
        limit = typeof limit === 'number' && limit > 0 ? limit : DEFAULT_FETCH_LIMIT_STACKS;
        apiLimit = typeof apiLimit === 'number' && apiLimit > 0 ? apiLimit : limit;

        // check ListState for existing entity, if the entity says to load, grab the nextToken
        if (listEntities && listEntities[listId]) {
          if (listEntities[listId].loading) {
            // we're loading - there might be a nextToken in the list
            if (!nextToken && listEntities[listId].nextToken) {
              nextToken = listEntities[listId].nextToken;
            }
          } else {
            // !loading
            DEBUG_LOGS &&
              console.log(
                `${PAGE} loadFilteredStacks$ - We already loaded this list (${listId})`,
                listEntities[listId]
              );
            return [
              stackActions.noMoreToLoad({ listId }), // this is not needed, but completes the request with message
            ];
          }
        }

        let numReceived = 0;
        // DEBUG_LOGS && console.log(`${PAGE} loadFilteredStacks$ check limits`, { limit, apiLimit, projectId, listId, filters });

        /**
         * if (projectId) => queryFilteredStacksByProject
         * else if (userId) => queryStacksByUser
         * else queryFilteredStacks
         *
         * 2022-07-26 modified to allow userId be more important than the project...
         */
        const query = userId
          ? this.stacksApi.queryStacksByUser({ userId, limit: apiLimit, filters, nextToken })
          : projectId
          ? this.stacksApi.queryFilteredStacksByProject({ projectId, limit: apiLimit, filters, nextToken })
          : this.stacksApi.queryFilteredStacks({ limit: apiLimit, filters, nextToken });

        return query.pipe(
          map((result) => {
            const { items, nextToken: token } = result;
            numReceived += Array.isArray(items) && items.length > 0 ? items.length : 0;
            // check if we got enough items
            // const currentItems = state && state[listId] && Array.isArray(state[listId].itemIds) ? state[listId].itemIds : [];
            if (numReceived < limit && token && token.length > 0) {
              /**
               * we need more items, loadMore
               * @todo the timeout needs tuned...
               */
              const newApiLimit =
                (limit + apiLimit) * 2 < MAX_FETCH_LIMIT_STACKS ? (limit + apiLimit) * 2 : MAX_FETCH_LIMIT_STACKS;
              const leftToGet = limit - numReceived;
              DEBUG_LOGS &&
                console.log(`${PAGE} loadFilteredStacks$ (${listId}) we need more items...(leftToGet: ${leftToGet})`, {
                  newApiLimit,
                  leftToGet,
                  limit,
                  apiLimit,
                  projectId,
                  listId,
                  filters,
                  items,
                  tokenExists: token && token.length > 0,
                });
              setTimeout(() => {
                // just to delay a bit to avoid jank
                this.store$.dispatch(
                  stackActions.loadMoreFilteredStacks({
                    listId,
                    filters,
                    nextToken: token,
                    limit: leftToGet,
                    apiLimit: newApiLimit,
                  })
                );
              }, 300);
              // setTimeout(() => {
              //   DEBUG_LOGS && console.warn(`${PAGE} loadFilteredStacks$ (${listId}) DELAYED more items...(leftToGet: ${leftToGet})`, { limit, leftToGet, apiLimit: newApiLimit, projectId, listId, filters, token });
              // }, 1000);
            } else if ((!token || token.length < 1) && Array.isArray(items) && items.length < 1) {
              DEBUG_LOGS &&
                console.log(`${PAGE} loadFilteredStacks$ (${listId}) NO MORE TO LOAD`, {
                  items,
                  projectId,
                  listId,
                  filters,
                  limit,
                  apiLimit,
                  tokenExists: token && token.length > 0,
                });
              this.store$.dispatch(stackActions.noMoreToLoad({ listId }));
            } else {
              DEBUG_LOGS &&
                console.log(`${PAGE} loadFilteredStacks$ api result`, {
                  items,
                  projectId,
                  listId,
                  filters,
                  limit,
                  apiLimit,
                  tokenExists: token && token.length > 0,
                });
            }
            return result;
          }),
          // if we have any items, also loadSuccess
          filter(({ items }) => Array.isArray(items) && items.length > 0),
          map((result) => {
            const { items, nextToken: token } = result;
            return stackActions.loadSuccess({
              stacks: items,
              listId,
              nextToken: token,
              filters,
              isLoadMore: !!nextToken, // if the request was done with a nextToken, the items will be appended
            });
          }),
          catchError((error) => of(stackActions.loadFail({ error })))
        );
      })
    )
  );

  /**
   * V4 Load By Project
   */
  loadProjectStacksFeatured$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stackActions.loadProjectFeaturedStacks.type, stackActions.loadMoreProjectFeaturedStacks.type),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectStacksState)))),
      tap(([action, state]) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        DEBUG_LOGS && console.log(`${PAGE} pre-filter action:`, action); //, state);
      }),
      // stop here if it's already loaded & there's stacks...
      filter(([action, state]) =>
        this.filterLoadState(state, getProjectStacksGroup(action.projectId, StackGroup.Featured))
      ),
      mergeMap(([action, state]) => {
        DEBUG_LOGS && console.log(`${PAGE} post-filter mergeMap action:`, action); //, state);
        const { projectId } = action;
        const group = getProjectStacksGroup(projectId, StackGroup.Featured);

        return this.stacksApi
          .queryFeaturedStacksByProject({ projectId, limit: 20, nextToken: state.nextTokens[group] })
          .pipe(
            filter(({ items, nextToken }) => Array.isArray(items)),
            map(({ items, nextToken }) => {
              DEBUG_LOGS && console.log(`${PAGE} queryFeaturedStacksByProject`, { items, nextToken });
              return stackActions.loadSuccess({ stacks: items, listId: group, nextToken, isLoadMore: false });
            }),

            catchError((error) => {
              console.warn(`${PAGE} queryFeaturedStacksByProject caught:`, error);
              return of(stackActions.loadFail({ error }));
            })
          );
      }),
      // if we see an error here, need to reset store
      // TODO: resetStore Effect to reload the app
      // catchError(error => of(new projectActions.ResetAction()))
      catchError((error) => {
        console.error(error);
        this.sentryService.captureError(error);
        return of(resetActions.resetStore());
      })
    )
  );

  loadRecentProjectStacks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stackActions.loadProjectRecentStacks.type, stackActions.loadMoreProjectRecentStacks.type),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectStacksState)))),
      // stop here if it's already loaded & there's stacks...
      filter(([action, state]) =>
        this.filterLoadState(state, getProjectStacksGroup(action.projectId, StackGroup.Recent))
      ),
      mergeMap(([action, state]) => {
        DEBUG_LOGS && console.log(`${PAGE} post-filter mergeMap action:`, action); //, state);
        const { projectId } = action;
        const group = getProjectStacksGroup(projectId, StackGroup.Recent);

        // deprecated: this.stacksApi.queryRecentStacksByProject({ projectId, limit: 20, nextToken: state.nextTokens[group] })
        return this.stacksApi
          .queryFilteredStacksByProject({
            projectId,
            filters: { id: `${projectId}-recent`, type: FilterEntityTypes.Recent },
            nextToken: state.nextTokens[group],
          })
          .pipe(
            filter(({ items, nextToken }) => Array.isArray(items)),
            map(({ items, nextToken }) => {
              DEBUG_LOGS && console.log(`${PAGE} queryRecentStacksByProject`, { items, nextToken });
              return stackActions.loadSuccess({ stacks: items, listId: group, nextToken, isLoadMore: false });
            }),

            catchError((error) => {
              console.warn(`${PAGE} getApiStacksForProject caught:`, error);
              return of(stackActions.loadFail({ error }));
            })
          );
      }),
      // if we see an error here, need to reset store
      // TODO: resetStore Effect to reload the app
      // catchError(error => of(new projectActions.ResetAction()))
      catchError((error) => {
        console.error(error);
        this.sentryService.captureError(error);
        return of(resetActions.resetStore());
      })
    )
  );

  /**
   * LoadSuccess Check For NextToken and we have less than requested Limit, autoload if so
   * works, but implemented in loadFilteredStacks$
   */
  // loadStackSuccessNextToken$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(stackActions.loadSuccess),
  //     concatMap(action => of(action).pipe(
  //       withLatestFrom(this.store$.select(selectStacksState))
  //     )),
  //     tap(([action, state]) => {
  //       DEBUG_LOGS && console.log(`${PAGE} loadStackSuccessNextToken pre-filter action:`, action);//, state);
  //     }),
  //     // stop here if there's a nextToken, and we don't have the requested limit
  //     filter(([action, state]) => Array.isArray(action.stacks) && action.stacks.length < (action.limit || 5) && action.nextToken && action.nextToken.length > 0),
  //     mergeMap(([{ stacks, listId, filters, limit, nextToken }, state]) => {
  //       const projectId = (filters as FilterEntity)?.projectId;
  //       limit = typeof limit === 'number' && limit > 0 ? limit : DEFAULT_FETCH_LIMIT;
  //       const newLimit = limit * 2 < MAX_FETCH_LIMIT ? limit * 2 : MAX_FETCH_LIMIT;
  //       DEBUG_LOGS && console.log(`${PAGE} loadStackSuccessNextToken post-filter -> api:`, { newLimit, stacks, listId, filters, limit, nextToken });
  //       // load more
  //       return of(stackActions.loadMoreFilteredStacks({ listId, filters, nextToken, limit: newLimit }));
  //     }),
  //     // if we see an error here, need to reset store
  //     catchError((error) => {
  //       console.error(`reset Stack Store?`, error);
  //       this.sentryService.captureError(error);
  //       return EMPTY;
  //       // return of(stackActions.reset());
  //     }),
  //   )
  // );

  // /**
  //  * V4 Load Recent & Load More
  //  * @deprecated
  //  */
  // loadRecentStacks$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(
  //       stackActions.loadRecent.type,
  //       stackActions.loadMoreRecent.type
  //     ),
  //     concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectStacksState)))),
  //     tap(([action, state]) => {
  //       // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  //       DEBUG_LOGS && console.log(`${PAGE} pre-filter action:`, action); //, state);
  //     }),
  //     // stop here if it's already loaded & there's stacks...
  //     // filter(([action, state]) => this.filterLoadState(state, StackGroup.Recent)),
  //     mergeMap(([{ filters, listId, limit, type }, state]) => {
  //       DEBUG_LOGS && console.log(`${PAGE} post-filter deprecated action:`, { filters, listId });//, state);
  //       return from(
  //         this.stacksApi.queryRecentStacks({
  //           limit,
  //           filters,
  //           nextToken: state.nextTokens[listId],
  //         })
  //       ).pipe(
  //         map(({items, nextToken}) => stackActions.loadSuccess({stacks: items, listId, nextToken, isLoadMore: false })),
  //         catchError(error => of(stackActions.loadFail({ error })))
  //       );
  //     }),
  //     // if we see an error here, need to reset store
  //     // TODO: resetStore Effect to reload the app
  //     // catchError(error => of(new projectActions.ResetAction()))
  //     catchError((error) => {
  //       console.error(error);
  //       this.sentryService.captureError(error);
  //       return of(resetActions.resetStore());
  //     }),
  //   ),
  // );

  // /**
  //  * @deprecated in favor of loadFilteredStacks
  //  * V4 Load Featured & Load More
  //  */
  // loadFeaturedStacks$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(
  //       stackActions.loadFeatured.type,
  //       stackActions.loadMoreFeatured.type
  //     ),
  //     concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectStacksState)))),
  //     // tap(([action, state]) => {
  //     //   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  //     //   DEBUG_LOGS && console.log(`${PAGE} pre-filter action:`, action); //, state);
  //     // }),
  //     // stop here if it's already loaded & there's stacks... deprecated
  //     // filter(([action, state]) => this.filterLoadState(state, StackGroup.Featured)),
  //     mergeMap(([{ listId, filters, limit, type }, state]) => {
  //       DEBUG_LOGS && console.log(`${PAGE} mergeMap queryFeaturedStacks action:`, { listId, filters }); //, state);
  //       return from(
  //         this.stacksApi.queryFeaturedStacks({
  //           limit,
  //           filters,
  //           nextToken: state.nextTokens[listId],
  //         })
  //       ).pipe(
  //         map(({items, nextToken}) => stackActions.loadSuccess({stacks: items, listId, nextToken, isLoadMore: false })),
  //         catchError((error) => of(stackActions.loadFail({ error })))
  //       );
  //     }),
  //     // if we see an error here, need to reset store
  //     // TODO: resetStore Effect to reload the app
  //     // catchError(error => of(new projectActions.ResetAction()))
  //     catchError((error) => {
  //       console.error(error);
  //       this.sentryService.captureError(error);
  //       return of(resetActions.resetStore());
  //     }),
  //   ),
  // );

  /**
   * @todo V4 Load Trending & Load More
   */
  // loadTrendingStacks$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(stackActions.LOAD_TRENDING, stackActions.LOAD_MORE_TRENDING),
  //     concatMap(action => of(action).pipe(
  //       withLatestFrom(this.store$.select(selectStacksState))
  //     )),
  //     tap(([action, state]) => {
  // eslint-disable-next-line
  //       // DEBUG_LOGS && console.log(`${PAGE} pre-filter action:`, action);//, state);
  //       console.log(`${PAGE} TODO: action:`, action);//, state);
  //     }),
  //   // stop here if it's already loaded & there's stacks...
  //   filter(([action, state]) => this.filterLoadState(state, StackGroup.Trending)),
  //   mergeMap(([action, state]) => {
  //     DEBUG_LOGS && console.log(`${PAGE} post-filter mergeMap action:`, action);//, state);
  //     return this.stackService.loadFeaturedStacks({nextToken: state.nextTokens[StackGroup.Trending]}).pipe(
  //       map(({stacks, nextToken}) => stackActions.loadSuccess({stacks, group: StackGroup.Trending, nextToken, selected: null})),
  //       catchError(error => of(stackActions.loadFail({ error })))
  //     );
  //   }),
  //   // if we see an error here, need to reset store
  //   // TODO: resetStore Effect to reload the app
  //   // catchError(error => of(new projectActions.ResetAction()))
  //   catchError(error => {
  //     console.error(error);
  //     return of(resetActions.resetStore());
  //   })
  // ));

  // /**
  //  * @deprecated - use loadFeatured or loadRecent instead
  //  * catch all load Action - prefer to use loadFeatured or loadRecent instead
  //  */
  // loadStacks$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(stackActions.load.type),
  //     concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectStacksState)))),
  //     tap(([action, state]) => {
  //       DEBUG_LOGS && console.log(`${PAGE} loadStacks$ @deprecated: '${action.type}'`, action);
  //     }),
  //     // stop here if it's already loaded & there's stacks...
  //     filter(([action, state]) => this.filterLoadState(state, StackGroup.All)),
  //     mergeMap(([action, state]) => {
  //       // DEBUG_LOGS &&
  //       console.warn(`${PAGE} @deprecated - use loadFeatured or loadRecent instead`, action); //, state);
  //       return this.stackService.getFeaturedStacks().pipe(
  //         map((stacks) => stackActions.loadSuccess({ stacks, listId: StackGroup.All, nextToken: null })),
  //         catchError((error) => of(stackActions.loadFail({ error }))),
  //       );
  //     }),
  //     // if we see an error here, need to reset store
  //     // TODO: resetStore Effect to reload the app
  //     // catchError(error => of(new projectActions.ResetAction()))
  //     catchError((error) => {
  //       console.error(error);
  //       this.sentryService.captureError(error);
  //       return of(resetActions.resetStore());
  //     }),
  //   ),
  // );

  share$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stackActions.share.type),
        tap((action) => {
          const { type, projectId, stackId, ...rest } = action;
          this.incrementProp(action, new Stack({ projectId, stackId }), 'shares');
        })
      ),
    { dispatch: false }
  );

  watch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stackActions.selectIdPlay.type),
        tap((action) => {
          const { type, projectId, stackId, ...rest } = action;
          this.incrementProp(action, new Stack({ projectId, stackId }), 'views');
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions<stackActions.ActionsUnion>,
    private store$: Store<StackState>,
    private stacksApi: StacksApiService,
    private sentryService: SentryService,
    private analyticsService: AnalyticsService
  ) {}

  /**
   * Helper method for Errors
   */
  private captureError(error, msg = ''): void {
    const e = error && Array.isArray(error.errors) && error.errors.length > 0 ? error.errors[0] : error;
    console.warn(`${PAGE} ${msg} captureError`, e);
    this.sentryService.captureError(e);
  }

  private updateProps(props: string[] = [], stack: Partial<Stack> = {}, userId: string) {
    const updates: UpdateParam[] = [
      {
        prop: 'updatedBy',
        value: userId,
      },
    ];
    for (const prop of props) {
      updates.push({
        prop,
        value: stack[prop],
      });
    }
    // DEBUG_LOGS &&
    console.log(`${PAGE} action -> api`, { props, updates });
    return this.stacksApi.updateStack(new Stack(stack), updates).catch((error) => {
      this.captureError(error, `updateProps '${props.join(', ')}' caught`);
    });
  }

  private incrementProp(action, stack: Stack, prop: string) {
    const updates: UpdateParamInt[] = [
      {
        prop,
        value: 1,
      },
    ];
    DEBUG_LOGS && console.log(`${PAGE} action -> api ${prop}`, { action, updates });

    if (environment.production) {
      this.stacksApi.incrementPublic(stack.projectId, stack.stackId, updates).catch((error) => {
        this.captureError(error, `incrementProp '${prop}' caught`);
      });
    } else {
      DEBUG_LOGS && console.log(`Stack.${prop} not incremented on db, only if env.production`);
    }
  }

  /**
   * @deprecated
   * Filter Load Actions based on group
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  filterLoadState(state, group: StackGroup | string): boolean {
    return (
      (Array.isArray(state.loading) && state.loading.includes(group)) ||
      (Array.isArray(state.loaded) && !state.loaded.includes(group)) ||
      state.ids.length < 1 ||
      (state.nextTokens[group] && state.nextTokens[group].length > 0)
    );
  }
}
