/**
 * Stack Model
 *
 * @format
 */

import { UpdateParam } from '@app/core/api/api-types';
import { Utils } from '@app/shared/utils';
import { Clip } from './clip.model';
import { Project } from '@app/projects/shared/project.model';

export const SEARCH_START_STACK_ID = 'search-start-stack';
export const STACK_DEFAULT_TITLE = '';
export const STACK_DEFAULT_CREDITS = 'Anonymous';
export const STACK_DEFAULT_POSTER = 'https://content.filmstacker.com/public/posters/stack-default-poster.png';

// Stack Privacy
export enum STACK_PRIVACY {
  UNLISTED = 'UNLISTED',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

/**
 * The fields that can be searched against
 */
export const STACK_SEARCH_FIELDS = ['title', 'description', 'credits'];

export const getCollabPrivacy = (isCollaborative: number) =>
  isCollaborative > 0 ? STACK_PRIVACY.PUBLIC : STACK_PRIVACY.UNLISTED;

/**
 * sort ascending dteSaved
 */
export const sortStacksRecent = (a: Stack, b: Stack) => (a.dteSaved && b.dteSaved && a.dteSaved < b.dteSaved ? 1 : -1);
/**
 * sort ascending dtePublished
 */
export const sortRecentPublished = (a: Stack, b: Stack) =>
  a.dtePublished && b.dtePublished && Date.parse(a.dtePublished) < Date.parse(b.dtePublished) ? 1 : -1;

/**
 * descending featured, descending dteSaved
 */
export const sortFeatured = (a: Stack, b: Stack) => {
  if (b.featured === a.featured) {
    return b.dteSaved > a.dteSaved ? 1 : -1;
  }
  return b.featured > a.featured ? 1 : -1;
};

export const isHlsPlaylistReady = (stack: Stack): boolean => {
  if (stack && stack.hlsSrc && stack.hlsMeta) {
    const hlsMeta = Utils.tryParseJSON(stack.hlsMeta);
    return hlsMeta && hlsMeta.stackId && hlsMeta.stackId.length > 0;
  }
  return false;
};

export interface StackPublishInput {
  projectId: string;
  userId: string;
  private: boolean;
  stackId?: string;
  title?: string;
  description?: string;
  clips?: Clip[];
  credits?: string;
  identityId?: string;
  poster?: string;
  posterSrc?: string;
  tags?: string[];
  project?: Project;
  views?: number;
  shares?: number;
  isCollaborative?: number;
}

export interface StackDraftPublishInput {
  projectId: string;
  title: string;
  userId: string;
  credits: string;
  description?: string;
  poster?: string;
  clips?: Clip[];
  playlist?: IOrderedClip[];
  isCollaborative?: number;
  privacy?: STACK_PRIVACY;
}

export interface IOrderedClip {
  projectId: string;
  id: string; //clip.id
  order: number;
}

export interface StackHlsMetaClip {
  projectId: string;
  clipId: string;
  duration: number;
  startTime: number;
  endTime: number;
  order: number;
  provider: string;
}

export interface StackHlsMeta {
  clipMetadataList: StackHlsMetaClip[];
  numberOfClips: number;
  numberOfVariants: number;
  stackId: string;
  totalDuration: number;
}

/*

stack.moderation = { futureEnhancement: true }

stack.isApproved
clip.isApproved

*/

export class Stack {
  // project this was submitted to - HASH
  projectId: string;
  // stackId is the stackUrl - RANGE
  stackId: string;
  // userId that published this - INDEX
  userId: string;
  // INDEX RANGE
  dteSaved: string; // AWSDateTime

  title: string = STACK_DEFAULT_TITLE;
  // public display name that published this
  credits: string = STACK_DEFAULT_CREDITS;
  userIdentityId?: string; // for avatar

  // if submitted to a project event
  eventId?: string;
  poster: string = STACK_DEFAULT_POSTER;
  posterSrc?: string; // if there's a custom src (upload)

  playlist: IOrderedClip[] = [];

  description: string;
  // length of all clips "00:00:00"
  duration: string = '00:00';

  private: boolean;
  privacy?: STACK_PRIVACY;
  dtePublished?: string;

  shareUrl?: string;
  shareDomain?: string;
  recommended?: number;
  suggested?: number;
  featured?: number;
  shares: number;
  restacks: number;
  views: number;
  votes: number;
  likes: number;

  topics?: string[];
  tags?: string[];
  emotions?: string[];

  projectUrl?: string;

  /**
   * approved by moderation, only required for moderated projects
   *
   * clips and stacks must be moderated before public use if Project.isModerated
   * @note when get recent/trending by projects, have to filter Stacks.isApproved for Projects.isModerated
   */
  isApproved?: boolean;
  /** locked/collaborative */
  isLocked?: boolean;
  isCollaborative?: number; // 0 = false, 1 = true, 2 = needed?

  /**
   * HLS
   */
  hlsSrc?: string;
  hlsMeta?: string;
  stackHlsMeta?: StackHlsMeta;

  createdAt?: string; // AWSDateTime
  updatedAt?: string; // AWSDateTime
  updatedBy?: string; // userId

  // not for DB
  contributors?: { userId: string; num: number }[]; // this is based on playlist, not to be updated directly via api
  clips?: Clip[];
  clipIds?: string[];
  loaded?: boolean;
  loading?: boolean;
  currentIndex?: number;
  selectedId?: string;
  entities?: { [id: string]: Clip };
  error?: string;
  //end not for DB

  /**
   * @deprecated do not store this, just the identityId
   */
  avatar?: string; // if just using url (better for social?)

  constructor(fields: object) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      if (fields.hasOwnProperty(f)) {
        this[f] = fields[f];
      }
    }
  }
}

export const extractStackUpdates = (
  input: Partial<Stack> | StackPublishInput | StackDraftPublishInput,
  doLogs = false
) => {
  // const updates: UpdateParam[] = [];
  const stack = new Stack({});

  const now = new Date();
  const isoDate = now.toISOString();

  if (input.title) {
    stack.title = input.title.trim();
  }
  if (input.description) {
    stack.description = input.description.trim();
  }
  if ((input as Stack).privacy) {
    stack.privacy = (input as Stack).privacy;
    stack.private = stack.privacy === STACK_PRIVACY.PRIVATE;
  } else if (typeof (input as Stack).private === 'boolean') {
    stack.privacy = (input as Stack).private ? STACK_PRIVACY.PRIVATE : STACK_PRIVACY.PUBLIC;
    stack.private = (input as Stack).private;
  }
  doLogs && console.log({ private: stack.private, privacy: stack.privacy, input });

  if (input.isCollaborative >= 0) {
    stack.isCollaborative = input.isCollaborative;
  }

  if (Array.isArray((input as Stack).clips) && (input as Stack).clips.length > 0) {
    stack.playlist = (input as Stack).clips.map((clip, index) => ({
      projectId: clip.projectId,
      id: clip.id,
      order: index,
    }));
    // calc the total duration for the stack
    const durations = (input as Stack).clips.filter((clip) => clip && clip.duration).map((clip) => clip.duration);
    stack.duration = Utils.getTotalDuration(durations);
  } else if ((input as Stack).playlist) {
    // doing this else if since the clips will give us duration, so it's preferred
    stack.playlist = (input as Stack).playlist;
  }

  // stack.dtePublished = isoDate; // not until Published, this is a Draft
  stack.dteSaved = isoDate;
  stack.userId = input.userId;
  if ((input as Stack).credits) {
    stack.credits = (input as Stack).credits;
  }
  if ((input as Stack).poster || (input as Stack).posterSrc) {
    // handle custom poster upload - might have an auth token in the poster for ui view,
    // replace with actual src
    stack.poster = (input as Stack).poster || (input as Stack).posterSrc;
  }
  if (Array.isArray((input as Stack).tags) && (input as Stack).tags.length > 0) {
    stack.tags = (input as Stack).tags;
  }

  const updates: UpdateParam[] = [];
  for (const [key, value] of Object.entries(stack)) {
    updates.push({ prop: key, value });
  }
  // add these after the updates
  stack.stackId = (input as Stack).stackId || Utils.autoId(input.title, input.userId);
  stack.projectId = input.projectId;

  doLogs && console.log(`extractUpdates`, { updates, stack });

  return {
    stack,
    updates,
  };
};

// eslint-disable-next-line arrow-body-style
export const removeClipsFromPlaylist = (
  playlist: IOrderedClip[] = [],
  ids: { projectId: string; id: string }[] = []
): IOrderedClip[] =>
  playlist
    .filter(({ projectId, id }) => ids.findIndex(({ projectId: p, id: d }) => p === projectId && d === id) < 0)
    .map(({ projectId, id }, index) => ({
      projectId,
      id,
      order: index,
    }));

export const addClipsToPlaylist = (
  playlist: IOrderedClip[] = [],
  ids: { projectId: string; id: string }[] = []
): IOrderedClip[] => {
  //playlist
  const removed = playlist
    .filter(({ projectId, id }) => ids.findIndex(({ projectId: p, id: d }) => p === projectId && d === id) < 0)
    .map(({ projectId, id }, index) => ({
      projectId,
      id,
      order: index,
    }));
  const newIds = ids.map(({ projectId, id }, index) => ({
    projectId,
    id,
    order: removed.length + index,
  }));

  return [...removed, ...newIds];
};

export const getClipIdsFromPlaylist = (playlist: IOrderedClip[] = []): string[] =>
  playlist.filter(({ projectId, id }) => projectId && id).map(({ projectId, id }) => `${projectId}/${id}`);
