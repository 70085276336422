/**
 * app/core/store/index
 * 2025-01-31 refactor of store.module.ts to standalone components
 *
 * @format
 */

import { isDevMode } from '@angular/core';
// Import ngrx Tools (standalone)
import { ActionReducer, MetaReducer, provideStore, provideState } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideEffects } from '@ngrx/effects';
// import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';

/**
 * MVP-1128 upgraded from "@ionic/storage": "2.2.0" to "@ionic/storage-angular": "3.0.6"
 * @see Readme https://github.com/ionic-team/ionic-storage/blob/main/README.md
 * @see Changelog https://github.com/ionic-team/ionic-storage/blob/main/CHANGELOG.md
 *
 * 2022-02-09 moved from app.module to core/store/store.module.ts
 * @todo MVP-1245 2022-02-09 awaiting PR https://github.com/ionic-team/ionic-storage/pull/269
 * @see ionic-storage.ts implementation referenced in PR
 * @see ngrx-store-ionic-storage.ts detailed implementation of local storage
 */
import { IonicStorageModule, Drivers } from './ionic-storage';
// import { IonicStorageModule } from '@ionic/storage-angular';

// localstoragesync
import { StorageSyncEffects, storageSync } from './ngrx-store-ionic-storage';
// Import ngRx Store
import { reducers, logger, initialState } from './reducers';
//add the effects we are using
import { AnalyticsEffects } from './effects/analytics.effects';
import { ClipEffects } from './effects/clips.effects';
import { ListsEffects } from './effects/lists.effects';
import { MembersEffects } from './effects/members.effects';
import { MyStackEffects } from './effects/mystack.effects';
import { ProjectsEffects } from './effects/projects.effects';
import { ResetEffects } from './effects/reset.effects';
import { StacksEffects } from './effects/stacks.effects';
import { UserEffects } from './effects/user.effects';
/** todo: MVP-1160 history of stacks + progress */
// import { HistoryEffects } from './effects/history.effects';

import { ignoreStorageSync as ignoreClipActions } from './actions/clips.actions';
import { ignoreStorageSync as ignoreMystackActions } from './actions/mystack.actions';
import { ignoreStorageSync as ignoreProjectActions } from './actions/projects.actions';
import { ignoreStorageSync as ignoreStackActions } from './actions/stacks.actions';
import { ignoreStorageSync as ignoreUserActions } from './actions/user.actions';

import { initialState as intialBillingState } from '@app/billing/store/billing.reducer';
import { BillingEffects } from '@app/billing/store/billing.effects';

export { State } from './reducers';

/**
 * store state features/keys to sync with localstorage
 */
const LOCALSTORAGE_KEYS = [
  // 'history', // todo: MVP-1160 history of stacks + progress
  'viewstate',
  'environ',
  'mystack',
  'playlist',
  'watchLater',
  // ... isDevMode() ? ['projects'] : [], // enable for dev - save on api calls during dev
  // 'clips', // disable for dev
  // 'stacks', // disable for dev

  // 'router', // test case: does it reload where you were? no, errors with "ngrx-store-ionic-storage onSyncErr: DOMException: Failed to execute 'put' on 'IDBObjectStore'"
  // not sync'd
  // 'search',
  // 'layout',
];

/**
 * callback from https://github.com/natural-apptitude/ngrx-store-ionic-storage
 * @param err
 */
function onSyncError(err) {
  console.warn('ngrx-store-ionic-storage onSyncErr:', err);
}

/**
 * Pass options into the storageSync function to create a meta-reducer, and compose it with your other reducers
 * https://github.com/natural-apptitude/ngrx-store-ionic-storage
 */
const storageSyncReducer = storageSync({
  // Only sync these state features/keys
  keys: LOCALSTORAGE_KEYS,
  // keys: [],   // DEV: reset local each time
  ignoreActions: [
    // Don't sync when these actions occur (avoids write to localstorage - more efficient than just specifying keys)
    ...ignoreClipActions,
    ...ignoreMystackActions,
    ...ignoreProjectActions,
    ...ignoreStackActions,
    ...ignoreUserActions,
  ],
  hydratedStateKey: 'hydrated', // Add this key to the state
  // eslint-disable-next-line object-shorthand
  onSyncError: onSyncError, // If a sync fails
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function storageMetaReducer(reducer: ActionReducer<any>): ActionReducer<any, any> {
  return storageSyncReducer(reducer);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const metaReducers: Array<MetaReducer<any, any>> = [logger, storageMetaReducer];

/**
 * we originally needed this due to AOT and ngrx/Entity
 * is it still needed?
 */
const staticInitialState = {
  billing: {
    loaded: false,
    status: '',
    level: '',
    userId: '',
    minutes: 0,
    code: '',
    proSubId: '',
    eventSubIds: [],
    // subscription: {},
    chargebeeSubscriptions: [],
    chargebeeCustomer: {},
    plans: [],
    addons: [],
  },
  clips: {
    ids: [],
    entities: {},
    loadingIds: [],
  },
  environ: {
    permissionLevel: '',
    activeProject: '',
    currentClip: '',
    currentStack: null,
    searchProjects: [],
    searchTemplates: [],
    searchTags: [],
    searchLocations: [],
    searchDatetime: [],
    searchMood: [],
    searchCollabs: [],
    searchLanguage: [],
  },
  // todo: MVP-1160 history of stacks + progress
  // history: {
  //   loaded: false,
  //   loading: false,
  //   ids: [],
  //   current: null,
  //   firstSeenCurrent: false,
  // },
  lists: {
    ids: [],
    entities: {},
  },
  members: {
    ids: [],
    entities: {},
    selectedId: null,
    nextTokens: {},
    loadedProjects: [],
    query: null,
    queryResults: [],
  },
  mystack: {
    loaded: false,
    loading: false,
    currentIndex: 0,
    selectedId: '',
    clipIds: [],
    projectId: '',
    stackId: '',
    credits: '',
    userId: '',
    userIdentityId: '',
    avatar: '',
    eventId: '',
    title: '',
    poster: '',
    playlist: [],
    description: '',
    duration: '00:00',
    dteSaved: '',
    dtePublished: '',
    recommended: 0,
    featured: 0,
    suggested: 0,
    topics: [],
    emotions: [],
    tags: [],
    votes: 0,
    views: 0,
    shares: 0,
    restacks: 0,
    likes: 0,
    projectUrl: '',
    shareUrl: '',
  },
  playlist: {
    loaded: false,
    loading: false,
    ids: [],
    current: null,
    currentIndex: null,
  },
  projects: {
    ids: [],
    entities: {},
    selectedId: null,
    loaded: [],
    loading: [],
    mine: [],
    userId: null,
    nextTokenFeatured: null,
    nextTokenMine: null,
    nextTokenOther: null,
    nextTokens: {},
  },
  search: {
    ids: [],
    loading: false,
    query: '',
  },
  stacks: {
    ids: [],
    entities: {},
    loaded: [],
    loading: [],
    selectedIdPlay: null,
    selectedIdEdit: null,
    currentPlayIndex: 0,
    nextTokens: {},
    addToStackPlaylist: [],
    addToStackPayload: {},
  },
  user: {
    loggedIn: false,
    userId: null,
  },
  viewstate: {
    ids: [],
    entities: {},
    acceptedGDPR: false,
    tourSeen: {
      overview: false,
      capture: false,
      widgetSplash: false,
    },
  },
  // watchLater: {
  //   loaded: false,
  //   loading: false,
  //   ids: [],
  // },
};

// In this function dynamic state slices, if they exist, will overwrite static state at runtime.
function getInitialState() {
  return {
    ...staticInitialState,
    ...initialState,
    ...intialBillingState,
    hydrated: false,
  };
}

/**
 * root importProvidersFrom
 */
export const importProvidersFromAppCoreStoreModule = [
  IonicStorageModule.forRoot({
    name: 'FilmstackerSync',
    // defaults to indexedDB -> webSQL -> localStorage
    driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    // dbKey:'', // this is only for SQLite cipher
  }),
];

/*
  Bootstrap NgRxStoreModule
  with default root store state & reducer & effects
  => rest of store can be loaded with lazy loading
*/
export const provideAppCoreStore = [
  /**
   * https://ngrx.io/api/store/provideStore
   * Accepts a reducer function or object map of reducer functions.
   * If passed an object of reducers, combineReducers will be run creating your application
   * meta-reducer. This returns all providers for an @ngrx/store
   * based application.
   */
  provideStore(reducers, {
    initialState: initialState, // for AOT
    metaReducers,
    runtimeChecks: {
      // TODO: debug when turned on ERROR TypeError: Cannot read property 'color' of undefined (@freeze)
      strictStateImmutability: false,
      strictActionImmutability: false,
      // router state fails the serializability checks, to fix: https://ngrx.io/guide/router-store/configuration
      // strictStateSerializability: true,
      // strictActionSerializability: true,
    },
  }),
  provideEffects([
    StorageSyncEffects,
    AnalyticsEffects,
    ClipEffects,
    ListsEffects,
    MembersEffects,
    MyStackEffects,
    ProjectsEffects,
    ResetEffects,
    StacksEffects,
    UserEffects,
    // from feature state
    BillingEffects,
    /** todo: MVP-1160 history of stacks + progress */
    // HistoryEffects,
  ]),
  /** Include FeatureStores - didn't work, just include in rootState */
  // provideState(billingFeature),
  /**
   * Store devtools instrument the store retaining past versions of state
   * and recalculating new states. This enables powerful time-travel
   * debugging.
   *
   * To use the debugger, install the Redux Devtools extension for either
   * Chrome or Firefox
   *
   * See: https://github.com/zalmoxisus/redux-devtools-extension
   */
  ...(isDevMode()
    ? [
        provideStoreDevtools({
          maxAge: 50, // Retains last # states
          logOnly: !isDevMode(), // Restrict extension to log-only mode
          // connectInZone: true,
        }),
      ]
    : []),
];
