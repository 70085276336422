/** @format */

import { createAction, props, union } from '@ngrx/store';
import { Project } from '@app/projects/shared/project.model';
import { ProjectMember } from '@app/project-members/shared/project-member.model';
import { FilterEntity } from '@store/reducers/viewstate.reducers';

/**
 * TO Add if needed
 * 
  // Search = '[Project] Search',
  // SearchComplete = '[Project] Search Complete',
 */

// ngrx 8 example:
// export const reset = createAction(
//   ActionTypes.Reset,
//   props<{ username: string; password: string }>()
// );

const SIZE_FETCH_ITEMS = 20;

export const subUpdate = createAction('[Projects] Subscription Update', props<{ project: Project; userId?: string }>());
export const removeRecent = createAction('[Projects] Remove From Recent', props<{ id: string }>());
export const setHero = createAction('[Projects] Set Hero Image', props<{ id: string; url: string }>());
export const setSubscriptionEvent = createAction(
  '[Projects] Set Event Data',
  props<{
    id: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    eventConfig?: any;
    eventDate?: string;
    eventIsActive?: boolean;
    eventType?: string;
    subscriptionId?: string;
    subscriptionLevel?: string;
    subscriptionStatus?: number;
    subscriptionMinutes?: number;
    /** persist this as projectEffect */
    doDbUpdate?: boolean;
  }>()
);

export const reset = createAction('[Projects] Reset');

export const add = createAction('[Projects] Add', props<{ project: Project; userId?: string }>());

export const select = createAction('[Projects] Select', props<{ project: Project }>());
export const selectById = createAction('[Projects] Select By ID', props<{ id: string }>());

export const addMember = createAction('[Projects] Add Member', props<{ member: ProjectMember }>());
export const updateMember = createAction('[Projects] Update Member', props<{ member: ProjectMember }>());
export const addMine = createAction('[Projects] Add To Mine', props<{ ids: string[] }>());
export const removeMine = createAction('[Projects] Remove From Mine', props<{ id: string }>());

/**
 * Load Actions
 */
export const hydrated = createAction('[Projects] Hydrated');
export const load = createAction('[Projects] Load');
export const loadSuccess = createAction(
  '[Projects] Load Success',
  props<{
    projects: Project[];
    selected?: string;
    filters?: FilterEntity;
    listId?: string;
    nextToken?: string;
    isLoadMore?: boolean;
    userId?: string;
  }>()
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loadFail = createAction('[Projects] Load Fail', props<{ error: any; id?: string }>());

/**
 *  batch Load - v4
 */
export const loadBatchIds = createAction('[Projects] Load Batch By IDs', props<{ ids: string[]; group?: string }>());

/*
    load Specifics - v3
 */

export const loadFeatured = createAction('[Projects] Load Featured');
export const loadMine = createAction('[Projects] Load Mine');
export const loadMoreFeatured = createAction('[Projects] Load More Featured');
export const loadMoreMine = createAction('[Projects] Load More Mine');

export const loadById = createAction('[Projects] Load By ID', props<{ id: string }>());
export const loadPreviewById = createAction('[Projects] Load Preview By ID', props<{ id: string }>());

export const loadByIdSuccess = createAction(
  '[Projects] Load By ID Success',
  props<{ project: Project; userId?: string }>()
);

export const loadFilteredProjects = createAction(
  '[Projects] Load Filtered Projects',
  (payload: { listId: string; filters: FilterEntity; limit?: number }) => ({
    limit: SIZE_FETCH_ITEMS,
    nextToken: '',
    ...payload,
  })
);
export const loadMoreFilteredProjects = createAction(
  '[Projects] Load More Filtered Projects',
  (payload: { listId: string; filters: FilterEntity; nextToken: string; limit?: number }) => ({
    limit: SIZE_FETCH_ITEMS,
    ...payload,
  })
);

/**
 * Intended for the currentUser
 * @deprecated using UserEffects.loginSuccess$
 */
export const loadAllForCurrentUserId = createAction(
  '[Projects] Load All Projects for UserID (include Private/Unlisted)',
  props<{ userId: string }>()
);
/**
 * For all other users
 */
export const loadPublicByUserId = createAction(
  '[Projects] Load Public Projects for UserId',
  props<{ userId: string }>()
);

// export const addToMine = createAction('[Projects] Add ID to MINE', props<{ id: string }>());

// do not export return value
const actions = union({
  reset,
  add,
  select,
  selectById,
  addMember,
  updateMember,
  addMine,
  load,
  loadBatchIds,
  loadById,
  loadPreviewById,
  loadFeatured,
  loadMine,
  loadMoreFeatured,
  loadMoreMine,
  loadByIdSuccess,
  loadFilteredProjects,
  loadMoreFilteredProjects,
  hydrated,
  loadSuccess,
  loadFail,
  loadAllForCurrentUserId,
  loadPublicByUserId,
  setHero,
  removeMine,
  removeRecent,
  subUpdate,
});

// export only type
export type ActionsUnion = typeof actions;

/** Actions to ignore during local-storage-sync, for efficiency */
export const ignoreStorageSync = [
  loadPreviewById.type,
  loadMine.type,
  loadFilteredProjects.type,
  loadBatchIds.type,
  loadSuccess.type,
  loadByIdSuccess.type,
  subUpdate.type,
];
