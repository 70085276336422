/**
 * 2022-06-29 jd migrated functionality from clips.reducers MVP-970
 * @format
 */

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
// import { State as AppState } from '@store/reducers';
import * as ResetActions from '@store/actions/reset.actions';
import * as ClipActions from '@store/actions/clips.actions';
import { Clip } from '@app/shared/models/clip.model';
import { environment } from 'src/environments/environment';

const PAGE = '[ClipStore]';

export const featureKey = 'clips';

/** we actually do need to keep projectId in the key, as the api needs both for composite key */
// export const getId = (projectId: string, id: string): string => id;
export const getId = (projectId: string, clipId: string): string => `${projectId}/${clipId}`;
export const splitId = (id: string): { projectId: string; id: string } => ({
  projectId: id ? id.split('/')[0] : '',
  id: id ? id.split('/')[1] : '',
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State extends EntityState<Clip> {
  // additional entity state properties
  loadingIds: string[];
  errorIds: string[];
}

export const adapter: EntityAdapter<Clip> = createEntityAdapter<Clip>({
  // not required as each clip is ensured unique clip.id on capture, but it IS needed for API
  selectId: (clip) => getId(clip.projectId, clip.id),
  // selectId: (clip) => clip.id,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loadingIds: [],
  errorIds: [],
});

const clipReducer = createReducer(
  initialState,

  on(ClipActions.reset, ResetActions.resetStore, ResetActions.resetStoreThenReboot, () => ({ ...initialState })),

  /** @todo: instead use Effect to => LOAD_SUCCESS */
  on(ClipActions.hydrated, (state) =>
    Object.assign({}, state, {
      loadingIds: [],
    })
  ),

  on(ClipActions.addClip, (state, { clip }) => {
    const clipId = getId(clip.projectId, clip.id);
    return adapter.upsertOne(clip, {
      ...state,
      loadingIds: state.loadingIds.filter((id) => id && id !== clipId),
    });
  }),

  on(ClipActions.addClips, ClipActions.loadSuccess, (state, { clips }) => {
    const clipIds = clips.map((clip) => getId(clip.projectId, clip.id));
    return adapter.upsertMany(clips, {
      ...state,
      loadingIds: state.loadingIds.filter((id) => id && clipIds.indexOf(id) < 0),
    });
  }),

  // unused
  // on(
  //   // History.loadSuccess.type: //payload:{entities,current}
  //   MystackActions.loadClipsSuccess,
  //   (state, { clips }) => {
  //     const clipIds = clips.map((clip) => getId(clip.projectId, clip.id));
  //     return adapter.upsertMany(clips, {
  //       ...state,
  //       loadingIds: state.loadingIds.filter((id) => id && clipIds.indexOf(id) < 0),
  //     });
  //   }
  // ),

  on(ClipActions.loadFail, (state, { error, id }) => {
    console.warn(`${PAGE} load fail '${id}' error:`, error);
    return Object.assign({}, state, {
      loadingIds: state.loadingIds.filter((clipId) => clipId !== id),
      errorIds: [...state.errorIds, id],
    });
  }),
  on(ClipActions.loadFailBatch, (state, { error, ids }) => {
    console.warn(`${PAGE} load fail ids(${ids.join(', ')}) error:`, error);
    return Object.assign({}, state, {
      loadingIds: state.loadingIds.filter((e) => !ids.includes(e)),
      errorIds: [...state.errorIds, ...ids],
    });
  }),

  on(ClipActions.updateClip, (state, { clip }) => adapter.upsertOne(clip, state)),

  on(ClipActions.updateClipTranscoding, (state, { id, projectId, updates = {} }) => {
    const clip = Object.assign({}, state.entities[getId(projectId, id)]);
    for (const prop in updates) {
      if (updates.hasOwnProperty(prop) && updates[prop]) {
        clip[prop] = updates[prop];
      }
    }
    // console.log(`[ClipsStore] updateClipTranscoding:`, { id, updates, clip })
    return adapter.upsertOne(clip, state);
  }),

  on(ClipActions.subUpdate, (state, { clip }) => {
    if (clip && clip.id && state.entities[clip.id] && state.entities[clip.id].id) {
      // this is a clip we are tracking
      const clipEntity = Object.assign({}, state.entities[clip.id]);
      for (const prop in clip) {
        if (
          clip.hasOwnProperty(prop) &&
          (clip[prop] || typeof clip[prop] === 'boolean' || typeof clip[prop] === 'number')
        ) {
          const val = clip[prop];
          if (clipEntity[prop] !== val) {
            clipEntity[prop] = val;
            !environment.production && console.log(`[Store] clip changed [${prop}]`, val);
          }
        }
      }
      !environment.production && console.log(`[ClipsStore] subUpdate:`, { clipEntity });
      return adapter.upsertOne(clipEntity, state);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } else if (clip && clip.id && !(clip as any).error) {
      // it's new allow the ClipEffect to load it...
      // we already checked if (item.userId === userId || (item.privacy === CLIP_PRIVACY.PUBLIC && myProjectIds.includes(item.projectId)))
      // so we do indeed want this, but it does not currently exist
      if (!clip.title) {
        clip.title = clip.id;
      }
      !environment.production && console.log(`[ClipsStore] subUpdate new:`, { clip });
      // on effect also notify to save stack
      return adapter.upsertOne(clip, state);
    } else if (clip?.hlsMeta?.status) {
      // except on api.subscription, it will have
      // { percentComplete: num, status: 'TRANSCODING' | 'FINISHING, but not complete...' } = JSON.parse(clip.hlsMeta)
      return adapter.upsertOne(clip, state);
    } else {
      // ignoring clips we don't care about.. - this should be handled before the action is sent, no?
      !environment.production && console.log(`subUpdate ignoring:`, clip); // totally ignore me
      return state;
    }
  }),

  on(ClipActions.deleteClip, (state, { clip }) => adapter.removeOne(getId(clip.projectId, clip.id), state)),

  on(ClipActions.loadingClip, (state, { projectId, id }) =>
    Object.assign({}, state, {
      loadingIds: state.loadingIds.indexOf(getId(projectId, id)) < 0 ? [...state.loadingIds, id] : state.loadingIds,
    })
  ),

  on(ClipActions.setPoster, (state, { projectId, id, poster }) => {
    const clip = state.entities[getId(projectId, id)];
    if (!clip || !clip.id) {
      console.warn(`Missing clip: '${id}'? No action taken`);
      return state;
    }
    return adapter.upsertOne({ ...clip, poster }, state);
  }),

  on(ClipActions.setApproved, (state, { projectId, id, isApproved }) => {
    const clip = state.entities[getId(projectId, id)];
    if (!clip || !clip.id) {
      console.warn(`Missing clip: '${id}'? No action taken`);
      return state;
    }
    return adapter.upsertOne({ ...clip, isApproved }, state);
  })
);

export function reducer(state: State | undefined, action: Action) {
  return clipReducer(state, action);
}

// selectors

export const { selectEntities, selectAll } = adapter.getSelectors();

export const getIds = (state: State) => state.ids;

export const getLoadingIds = (state: State) => state.loadingIds;

export const getErrorIds = (state: State) => state.errorIds;

// moved to clips.selector.ts

/** @note MVP-867-upgrade-ionic-angular changed <AppState> to <State> */
// export const selectClipState = createFeatureSelector<State>(featureKey);
// export const selectClipEntities = createSelector(selectClipState, selectEntities);
// export const selectAllClips = createSelector(selectClipState, selectAll);
