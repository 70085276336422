/** @format */

import _keyBy from 'lodash/keyBy';
import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map, filter, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import * as projectActions from '@store/actions/projects.actions';
import { activeProject as environActiveProject } from '@store/actions/environ.actions';
import {
  selectProject,
  getProjects,
  getFeaturedProjects,
  selectMyProjects,
  selectMyProjectsIds,
  selectCurrentProject,
  selectProjectsByIds,
  selectRecentProjects,
} from '@store/selectors/projects.selectors';
import { UpdateParam } from '@app/core/api/api-types';
import { ProjectsApiService } from '@app/core/api/projects-api.service';
import { ProjectCrewApiService } from '@app/core/api/project-crew-api.service';
import { UserService } from '@app/core/services/user.service';
import { Utils } from '@app/shared/utils';
import { Project, PROJECT_MEMBER_ROLE } from '@app/projects/shared/project.model';
import { ProjectMember, isAllowedFor as memberIsAllowedFor } from '@app/project-members/shared/project-member.model';

export const DEFAULT_PROJECT = 'global';

const DEBUG_LOGS = false;

const HYDRATE_DELAY = 800; //ms

const PAGE = '[ProjectsService]'; // eslint-disable-line  @typescript-eslint/no-unused-vars

const sortFeatured = (a: Project, b: Project) => (a.featured && b.featured && a.featured < b.featured ? 1 : -1);

/**
 * @todo:
 * getProjectsPreviewByUserId -> number of Projects, and list in ProjectMember
 */
@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  role: typeof PROJECT_MEMBER_ROLE = PROJECT_MEMBER_ROLE;

  constructor(
    private projectsApiService: ProjectsApiService,
    private projectCrewApiService: ProjectCrewApiService,
    private userService: UserService,
    private store: Store<State>
  ) {}

  getProjects(): Observable<Project[]> {
    return this.store.select(getProjects); //.pipe(filterApiResponse());
  }

  getFeaturedProjects(): Observable<Project[]> {
    return this.store.select(getFeaturedProjects).pipe(map((projects: Project[]) => projects.sort(sortFeatured)));
  }

  /**
   * Get Project By ID
   */
  getProject(id: string): Observable<Project> {
    this.store.dispatch(projectActions.loadById({ id })); // ensure it's in the store (Effect)
    return this.store.select(selectProject(id)).pipe(
      // only return when found (not undefined)
      filter((p) => typeof p !== 'undefined'),
      distinctUntilChanged((p: Project, q: Project) => p.id === q.id)
    );
  }
  getProjectPreview(id: string): Observable<Project> {
    this.store.dispatch(projectActions.loadPreviewById({ id })); // ensure it's in the store (Effect)
    /** @todo MVP-970 ngrx deprecated selectorWithProps */
    return this.store.select(selectProject(id)).pipe(filter((p) => typeof p !== 'undefined'));
  }

  getProjectFromStoreById(id: string): Observable<Project> {
    /** @todo MVP-970 ngrx deprecated selectorWithProps */
    return this.store.select(selectProject(id));
  }
  getProjectsFromStoreByIds(ids: string[]): Observable<Project[]> {
    /**
     * @todo MVP-970 validate this logic here... should it be in selector?
     */
    // return forkJoin(ids.map(id => this.getProjectFromStoreById(id)))
    return this.store.select(selectProjectsByIds(ids));
  }

  loadProjectPreviewsByIds(ids: string[]): void {
    ids.forEach((id) => this.store.dispatch(projectActions.loadPreviewById({ id })));
  }

  /**
   * Get Projects Owned & Project Crew for Current User
   */
  getMyProjects(): Observable<Project[]> {
    return this.store.select(selectMyProjects).pipe(filter((p) => typeof p !== 'undefined'));
  }

  getMyProjectsIds(): Observable<string[]> {
    return this.store.select(selectMyProjectsIds).pipe(filter((p) => typeof p !== 'undefined'));
  }

  /**
   * Get Recent Projects viewed (selected)
   */
  getRecentProjects(): Observable<Project[]> {
    return this.store.select(selectRecentProjects).pipe(
      map((recent) => {
        DEBUG_LOGS && console.log(`getRecentProjects`, recent);
        if (!Array.isArray(recent)) {
          return [];
        }
        return recent.filter((p) => p && p.id);
      }),
      // this was failing filter for undefined, rather just remove those..
      // filter((p) => Array.isArray(p) && !p.some((item) => !item || !item.id)),
      shareReplay(1)
    );
  }

  removeFromRecent(id: string) {
    console.log(id);
    this.store.dispatch(projectActions.removeRecent({ id }));
  }

  getFeaturedAndMyProjects(): Observable<Project[]> {
    return combineLatest([this.getFeaturedProjects(), this.getMyProjects()]).pipe(
      map(([featuredProjs, myProjs]) => {
        DEBUG_LOGS &&
          console.log(`${PAGE} getFeaturedAndMyProjects`, {
            featuredProjs,
            myProjs,
          });
        const merged = featuredProjs.reduce(
          (acc, actual) => {
            if (!acc.some((proj) => proj.id === actual.id)) {
              acc = [...acc, actual];
            }
            return acc;
          },
          [...myProjs]
        );
        return merged.sort(sortFeatured);
      })
    );
  }

  getSelected(): Observable<Project> {
    return this.store.select(selectCurrentProject).pipe(filter((p) => typeof p !== 'undefined'));
  }

  selectAndGet(projectId: string): Observable<Project> {
    this.selectById(projectId);
    return this.store.select(selectCurrentProject).pipe(filter((p) => typeof p !== 'undefined'));
  }

  /*
    NGRX Actions
  */
  selectById(projectId: string) {
    this.store.dispatch(projectActions.selectById({ id: projectId }));
  }

  loadProjects() {
    // trying a timeout to avoid store HYDRATE timing issue
    setTimeout(() => {
      this.store.dispatch(projectActions.load());
    }, HYDRATE_DELAY);
  }
  loadFeaturedProjects() {
    // trying a timeout to avoid store HYDRATE timing issue
    setTimeout(() => {
      this.store.dispatch(projectActions.loadFeatured());
    }, HYDRATE_DELAY);
  }

  setActiveProject(projectId: string) {
    this.store.dispatch(environActiveProject({ projectId }));
  }

  /**
   * Query the API for SearchText
   * NOTE: searchFields[] in project.model.ts
   */
  searchProjects(term: string) {
    console.log(`${PAGE} searchProjects - TODO!`, term);
    // this.store.dispatch(projectActions.search()); // createEffect -> API
  }

  checkIsAdmin(username: string, project: Project): { isAdmin: boolean; isCrew: boolean; role: string } {
    if (username && project && project.id) {
      // console.log(`${PAGE} checkIsAdmin: %s %o`,username, project);
      if (project.owner && project.owner === username) {
        return {
          isAdmin: true,
          isCrew: true,
          role: 'OWNER',
        };
      }
      // check crew
      if (project.members) {
        let isAdmin = false;
        let isCrew = false;
        let role = '';
        for (const member of project.members) {
          if (member.username === username && member.isActive) {
            isCrew = true;
            isAdmin =
              isAdmin || member.role === PROJECT_MEMBER_ROLE.EXECUTIVE || member.role === PROJECT_MEMBER_ROLE.PRODUCER;
            role = member.role;
          }
        }
        return {
          isAdmin,
          isCrew,
          role,
        };
      }
    }
    return {
      isAdmin: false,
      isCrew: false,
      role: '',
    };
  }

  checkIsAdminMultiple(username: string, projects: Project[]): boolean {
    if (!username || !Array.isArray(projects)) return false;

    for (const project of projects) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { isAdmin = false, isCrew = false, role = '' } = this.checkIsAdmin(username, project);

      if (isAdmin) return true;
      DEBUG_LOGS && console.log(`${PAGE} looping checkIsAdminMultiple...`);
    }
  }

  /**
   * Update a Project - supply the Project and an array of UpdateParam{ prop: string, value: any }
   * @param project
   * @param updates array of UpdateParam{ prop: string, value: any }
   * @returns the changed props + id
   */
  updateProject(project: Project, updates: UpdateParam[]) {
    // could return a Promise from api if desired...
    if (!project || !project.id) {
      return Promise.reject('Missing required project.id');
    }
    // update store with project
    updates.forEach((update) => {
      if (update.value || typeof update.value === 'number' || typeof update.value === 'boolean') {
        // it could be number == zero
        // dynamically create the update params for string query
        project[update.prop] = update.value;
      }
    });
    this.store.dispatch(projectActions.add({ project }));

    return this.projectsApiService.updateProject(project, updates);
  }

  createProject(project: Project): Promise<Project> {
    // for projects let's not have userid info - keep it more generic, you're already the "owner"
    const userIdPath = ''; // project.owner
    project.id = Utils.autoId(project.title, userIdPath);
    return this.projectsApiService.createProject(project).then((createdProject) => {
      // we have the full project, just add it and when RTD returns it can update as needed
      this.store.dispatch(projectActions.add({ project: createdProject }));
      this.store.dispatch(projectActions.addMine({ ids: [createdProject.id] }));
      return createdProject;
    });
  }

  deleteProject(projectId: string): Promise<Partial<Project>> {
    return this.projectsApiService.deleteProject(projectId);
  }

  /**
   * Get the ProjectById$ and UserId$ and then determine isProjectAdmin
   *
   * Used by: project-admin.guard
   *
   * merge this.projectService.getProject(projectId)
   * and this.userService.userId$
   * wait for project to have an id (if undefined, it is still loading, if it was not found in api, it will be ..?)
   * then check isAdmin
   */
  getProjectUserIsAdmin(projectId: string): Observable<boolean> {
    // 2022-03-05 jd added userId$ pipe to filter !==null to await Cognito setting auth from token on reload
    return combineLatest([this.getProject(projectId), this.userService.userId$.pipe(filter((u) => u !== null))]).pipe(
      map(([project, userId]) => {
        // we need to have both the project and userId observables
        DEBUG_LOGS &&
          console.log(`**** getProjectUserIsAdmin - make sure userId & project.id are defined...`, { project, userId });
        return (
          userId && project && project.id && memberIsAllowedFor([this.role.OWNER, this.role.PRODUCER], project, userId)
        );
      })
    );
  }

  isProjectAdmin(project: Project, userId?: string) {
    return memberIsAllowedFor([this.role.OWNER, this.role.PRODUCER, this.role.EXECUTIVE], project, userId);
  }

  isProjectMember(project: Project, userId?: string) {
    // userId$
    const currentUserId = userId || this.userService.getUserId(); //TODO: Observable<boolean> = this.userService.userId$
    // this.userService.userId$.pipe(filter(u => u !== null), take(1))
    if (currentUserId && project?.owner === currentUserId) {
      return true;
    }
    if (!project || !project.hasOwnProperty('members')) {
      return false;
    }
    const projectMembersMap = _keyBy(
      project.members.filter((member) => member.isActive),
      'userId'
    );
    return projectMembersMap.hasOwnProperty(currentUserId);
  }

  getRoleOfUserId(project: Project, userId: string) {
    const projectMembersMap = _keyBy(
      project.members.filter((member) => member.isActive),
      'userId'
    );

    return projectMembersMap.hasOwnProperty(userId) ? projectMembersMap[userId] : null;
  }

  isAllowedFor(allowedRoles: PROJECT_MEMBER_ROLE[], project: Project, userId?: string): boolean {
    if (userId) {
      return memberIsAllowedFor(allowedRoles, project, userId);
    }
    const id = this.userService.getUserId(); //TODO: Observable<boolean> = this.userService.userId$
    // this.userService.userId$.pipe(take(1)).subscribe((userId) => {});
    return memberIsAllowedFor(allowedRoles, project, id);

    // let isAllowed = false;
    // const currentUserId = userId || this.userService.getUserId(); //TODO: Observable<boolean> = this.userService.userId$
    // if (currentUserId && project && Array.isArray(project.members)) {
    //   const projectMembersMap = _keyBy(
    //     project.members.filter((member) => member.isActive),
    //     'userId'
    //   );
    //   isAllowed = allowedRoles.some(
    //     (r) => projectMembersMap[currentUserId] && projectMembersMap[currentUserId].role === r
    //   );
    // }
    // return isAllowed;
  }

  /**
   * @deprecated
   * use ngrx store / effects
   */
  private loadById(id: string): Observable<Project> {
    console.warn(`${PAGE} @deprecated loadById`);
    // GETPROJECT loadById deprectaed
    return this.projectsApiService.getProject(id).pipe(
      map((project) => {
        DEBUG_LOGS && console.log(`${PAGE} loadById getProject: %o`, project);
        return project;
      })
    );
  }

  /**
   * @deprecated
   */
  private loadPreviewById(id: string): Observable<Project> {
    console.warn(`${PAGE} @deprecated loadById`);
    return this.projectsApiService.getProjectPreview(id).pipe(
      map((project) => {
        DEBUG_LOGS && console.log(`${PAGE} loadPreviewById: %o`, project);
        return project;
      })
    );
  }

  /**
   * @deprecated - use ProjectEffect
   */
  private getUserProjectCrews(userId: string): Observable<ProjectMember[]> {
    console.warn(`${PAGE} @deprecated getUserProjectCrews`);
    return this.projectCrewApiService.getCrewForUser(userId);
  }
}
