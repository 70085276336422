/**
 * Clip Model
 * @format
 */

import { Utils } from '../utils';

export const DEFAULT_POSTER = '/assets/images/clip-default-poster.png';
export const MAX_DESC_LENGTH_CLIPS = 1000;

export const sortClipsRecent = (a: Clip, b: Clip) => {
  const aDate = a.created;
  const bDate = b.created;
  return aDate < bDate ? 1 : -1;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sortRecentModified = (a: Clip, b: Clip) => {
  const aDate = a.modified || a.created;
  const bDate = b.modified || b.created;
  return aDate < bDate ? 1 : -1;
};

// Clip Privacy
export enum CLIP_PRIVACY {
  UNLISTED = 'UNLISTED',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export enum ClipProvider {
  Youtube = 'YOUTUBE',
  Vimeo = 'VIMEO',
}

/** hlsMeta Prop for backend upgrade existing */
export const HLS_META_UPGRADE_PROP = 'upgrade';
export const HLS_META_PROP_SUBMITTED = 'submitted';
export const HLS_META_UPGRADE = {
  [HLS_META_UPGRADE_PROP]: true,
};
/** hlsMeta Prop for backend transcode using new architecture */
export const HLS_META_TRANSCODE_PROP = 'hls';
export const HLS_META_TRANSCODE = {
  [HLS_META_TRANSCODE_PROP]: true,
};

export interface ClipVideoFile {
  src?: string; // source.src data or string for video tag
  filename?: string;
  orig?: string;
  // ex: video/mp4
  type?: string;
  // path to the file
  filepath?: string;
  // size in bytes
  size?: number;
  // does it have music burned in
  hasMusic?: boolean;
  lastModifiedDate?: string;
  duration?: string;
  durationInSeconds?: number;
  quality?: string; // 480p | 720p (quality name)

  // capture VideoItems
  startTime?: number;
  endTime?: number;
  posterTime?: string;
  posterTimeInt?: number;
  vimeo_id?: string;
  youtube_id?: string;
  /**
   * @deprecated
   * @todo remove me
   */
  youtube_id_nomusic?: string;

  youtube_data?: object;
  title?: string;
  poster?: string;
  name?: string;
  uploaded?: boolean;
  uploading?: boolean;
  file?: File;
  loading?: boolean;

  // s3Object?: any;
}

export interface ClipVideoTrack {
  kind: string;
  label: string;
  srclang: string;
  src: string;
  isDefault: boolean;
}

export interface ClipHlsMeta {
  status?: string;
  upgrade?: boolean;
  submitted?: boolean;
  hls?: boolean;
  percentComplete?: string;
  errorMessage?: string;
  stackId?: string;
  // clipMetadataList: object; // StackHlsMeta
}

/*

clip.moderation = { futureEnhancement: true }

stack.isApproved
clip.isApproved

*/

export class Clip {
  // // partition key - get all clips for project
  projectId: string;
  // sort key
  id: string;
  // index user that uploaded clip
  userId: string;
  // user index RANGE
  created: string;
  userIdentityId: string;
  avatar?: string; // url
  geoLat?: string;
  geoLng?: string;
  filmingDate?: string;
  language?: string;

  // the source file - if found on createDDB, stream will process
  source: ClipVideoFile;
  sourcePath?: string; // the path to get the source
  sourceUrl?: string; // path to the original source video file
  // s3Object?: any;

  // the filenames of the source files
  sources: ClipVideoFile[] = [];
  // length of clip "00:00:00"
  duration: string = '00:00'; // clip_length
  title?: string;
  description?: string;
  // URL or Path
  poster?: string;
  topics: string[] = [];
  tags: string[] = [];
  emotions: string[] = [];

  // stats
  views?: number = 0;
  votes?: number = 0;
  numStacks?: number; // the number of Stacks it has been published in
  featured?: number;
  suggested?: number;
  private?: boolean;
  privacy?: CLIP_PRIVACY;
  modified?: string;

  /**
   * approved by moderation, only required for moderated projects
   *
   * clips and stacks must be moderated before public use if Project.isModerated
   */
  isApproved?: boolean;
  /**
   * locked/collaborative : Clip cannot be modified
   */
  isLocked?: boolean;

  tracks?: ClipVideoTrack[];
  transcript?: string;

  vimeo_id?: string;
  youtube_id?: string;
  /**
   * @deprecated
   * @todo remove me
   */
  youtube_id_nomusic?: string;

  provider?: ClipProvider;
  providerId?: string; // youtubeId, vimeoId

  startTime?: number;
  endTime?: number;

  hlsSrc?: string;
  hlsMeta?: ClipHlsMeta; // may be a JSON string or object

  /** carry an error within app, not in DB */
  error?: string;

  constructor(fields: object) {
    // Quick and dirty extend/assign fields to this model
    for (const f in fields) {
      if (fields.hasOwnProperty(f)) {
        this[f] = fields[f];
      }
    }
  }
}

/**
 * check if clip is currently transcoding
 */
export const getDoneTranscoding = (clip: Clip): boolean =>
  clip &&
  ((clip.hlsSrc && clip.hlsSrc.length > 0) ||
    (Array.isArray(clip.sources) && clip.sources.length > 0) ||
    (clip.providerId && clip.provider?.length > 0) ||
    clip.youtube_id?.length > 0 ||
    clip.vimeo_id?.length > 0);

/**
 * check if clip is done transcoding
 */
export const isHlsComplete = (clip: Clip): boolean => getDoneTranscoding(clip) || clip?.hlsMeta?.status === 'COMPLETE';

export const convertVideoFileToClip = (video: ClipVideoFile, initialClip = {}): Clip => {
  const clip: Clip = new Clip(initialClip);
  const filename = video.filename || video.name || '';
  if (video.duration) clip.duration = video.duration;
  if (video.title) {
    clip.title = video.title;
  } else if (filename) {
    clip.title = Utils.removeFileExt(filename);
  } else {
    clip.title = 'my clip';
  }
  if (video.poster) clip.poster = video.poster;
  video.lastModifiedDate = Utils.getDateTimeString(video.lastModifiedDate);
  clip.filmingDate = video.lastModifiedDate;

  if (video.youtube_id) {
    clip.youtube_id = video.youtube_id;
    clip.source = {
      title: video.title,
      lastModifiedDate: video.lastModifiedDate,
      duration: video.duration,
      youtube_id: video.youtube_id,
      startTime: video.startTime,
      endTime: video.endTime,
      src: 'youtube',
      type: 'youtube',
      // avoid filename for youtube_id's ***
    };
    clip.startTime = video.startTime;
    clip.endTime = video.endTime;
  }
  if (video.youtube_data) {
    console.log(`convertVideoFileToClip TODO youtube DATA:`, video.youtube_data);
    clip.source.youtube_data = video.youtube_data;
  }

  if (video.src && video.type) {
    clip.sources = [{ src: video.src, type: video.type }];
    clip.source = {
      filename,
      type: video.type,
      size: video.size,
      lastModifiedDate: video.lastModifiedDate,
      startTime: video.startTime,
      endTime: video.endTime,
    };
    if (video.posterTime) clip.source.posterTime = video.posterTime;
    if (video.durationInSeconds) clip.source.durationInSeconds = video.durationInSeconds;
  }

  // console.log(`convertVideoFileToClip clip:`, clip);
  return clip;
};

export const getFilmingDate = (clip: Clip) => {
  if (clip && clip.filmingDate) {
    return Utils.formatEuDate(new Date(clip.filmingDate));
  } else if (clip && clip.created) {
    return Utils.formatEuDate(new Date(clip.created));
  } else {
    console.log('no date?', clip);
    return '';
  }
};

export const getMp4Source = (clip: Partial<Clip>) => {
  const mp4Source = clip?.sources?.filter(source => source?.type === 'video/mp4' && source?.src)
  
  if (mp4Source?.length > 0) {
    if (mp4Source?.length > 1) {
      console.warn('Why more than 1 mp4Source? ..using first', mp4Source);
    }
    return mp4Source[0].src;
  } else {
    console.log('no sources?', clip);
    return `Not Found: ${clip?.projectId ?? 'project?'}/${clip?.id ?? 'id?'}`;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getClipEmotionsString = (clip: Clip, dir: string = 'asc') => {
  if (clip && clip.emotions) {
    // console.log(clip.emotions);
    const emo = clip.emotions;
    // // sort by weight
    // if (dir==="asc") {
    //   emo.sort((a,b) => (a.weight < b.weight) ? 1 : ((b.weight < a.weight) ? -1 : 0));
    // } else {
    //   emo.sort((a,b) => (a.weight > b.weight) ? 1 : ((b.weight > a.weight) ? -1 : 0));
    // }
    // console.log(emo);
    // return emo.map((item) => item['name']).join(", ");
    return emo.join(', ');
  }
  return '';
};

export const getClipTaxNameArray = (clip: Clip) => {
  // console.log(clip.tax);
  const aRet = clip.tags || [];
  // let tax = clip.tax;
  // for (let i in tax) {
  //   if (tax.hasOwnProperty(i)) {
  //     let prop = tax[i];
  //     //  console.log(prop, typeof prop, Array.isArray(prop));
  //      if (Array.isArray(prop)) {
  //         for (let item of prop) {
  //           if (item.name) {
  //             aRet.push(item.name);
  //           }
  //         }
  //      }
  //   }
  // }
  return aRet;
};

export const getClipTaxArray = (clip: Clip): Array<string> => {
  const tags: string[] = [];
  if (clip.tags) {
    for (const t of clip.tags) {
      const tagIndex = tags.findIndex((i) => i === t);
      if (tagIndex < 0) {
        tags.push(t);
      }
    }
  }
  const clipTags = getClipTaxNameArray(clip);
  // console.log(clipTags);
  for (const clipTag of clipTags) {
    const indexOfTag = tags.findIndex((i) => i === clipTag);
    if (indexOfTag < 0) {
      tags.push(clipTag);
    }
  }
  return tags;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DefaultClip = new Clip({});
