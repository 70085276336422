/**
 * @format
 */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { EMPTY, from, interval, of } from 'rxjs';
import { withLatestFrom, mergeMap, catchError, map, concatMap, filter, switchMap, tap, take } from 'rxjs/operators';
import { State } from '../reducers';
import * as mystackActions from '../actions/mystack.actions';
import * as clipActions from '../actions/clips.actions';
import * as stackActions from '../actions/stacks.actions';
import { getId as getClipId, splitId as splitClipId } from '@store/reducers/clips.reducers';
import { getId as getStackId, splitId as splitStackId } from '@store/reducers/stacks.reducers';
import { selectClipIds, selectClipEntities } from '@store/selectors/clips.selectors';
import { selectStackEntities, selectIdEdit, selectAddToStackPlaylist } from '@store/selectors/stacks.selectors';
import { selectMyStackClipIds, selectMyStackClips, selectMyStackState } from '@store/selectors/mystack.selectors';
import { getUserId } from '@store/selectors/user.selectors';
import { AnalyticsService } from '@app/core/services/analytics/analytics.service';
import { SentryService } from '@app/core/services/sentry.service';
import { UpdateParam } from '@app/core/api/api-types';
import { StacksApiService } from '@app/core/api/stacks-api.service';
import { DEFAULT_POSTER } from '@app/shared/models/clip.model';
import { Stack, STACK_DEFAULT_POSTER, STACK_PRIVACY } from '@app/shared/models/stack.model';
import { Utils } from '@app/shared/utils';
/** @todo refactor FLASH_MYSTACK event on tabs-main */
import { EventsService, EventActions } from '@app/core/services/events.service';
import { environment } from 'src/environments/environment';

const DEBUG_LOGS = false;
const PAGE = '[MyStackStoreEffects]';

/**
 * @todo Verify if MyStack needs saved before it's replaced
 */
const mergeMyStackClips = (stack, mystack): Stack => {
  // verify if there's any clips in my stack that might get lost
  // this would be true for any stack, so we also need to check if there's a stackId (not saved yet)

  // append the stacks if this is the same id
  const mystackClipIds = Array.isArray(mystack.clipIds) && mystack.clipIds.length > 0 ? mystack.clipIds : [];
  const stackClipIds =
    mystackClipIds.length > 0
      ? mystackClipIds
      : (stack.playlist || []).filter((p) => p && p.projectId && p.id).map((p) => getClipId(p.projectId, p.id));

  stack.clipIds = [...stackClipIds, ...mystackClipIds.filter((id) => id && stackClipIds.indexOf(id) < 0)];

  DEBUG_LOGS && console.log(`[Effect] mergeMyStackClips:`, { mystackState: mystack, stackClipIds, stack });

  return stack;
};

@Injectable()
export class MyStackEffects {
  /**
   * This effect does not yield any actions back to the store. Set
   * `dispatch` to false to hint to @ngrx/effects that it should
   * ignore any elements of this effect stream.
   *
   * The `defer` observable accepts an observable factory function
   * that is called when the observable is subscribed to.
   * Wrapping the database open call in `defer` makes
   * effect easier to test.
   */
  // @Effect({ dispatch: false })
  // openDB$: Observable<any> = defer(() => {
  //   return this.db.open('clips_app');
  // });

  /**
   * Handle the updates with updates to DB
   * this.mystackService.updateTitle(this.createForm.get('title').value);
   * this.mystackService.updateDescription(this.createForm.get('description').value);
   * updatePrivacy
   */
  updateStackTitle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mystackActions.updateTitle),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.pipe(select(selectMyStackState))),
            withLatestFrom(this.store$.select(getUserId))
          )
        ),
        map(([[action, state], userId]) => ({ prop: 'title', action, state, userId })),
        filter(({ prop, action, state, userId }) => action[prop] && action[prop].length > 0),
        tap(({ prop, action, state, userId }) => {
          this.updateProp(action, state, prop, userId);
        })
        // mergeMap(({ prop, action, state, userId }) => {
        //   this.updateProp(action, state, prop, userId);
        //   return [
        //     listActions.updateDrafts({ stack: state, props: ['title'] })
        //   ]
        // }),
      ),
    { dispatch: false }
  );
  updateStackDescription$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mystackActions.updateDescription),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.pipe(select(selectMyStackState))),
            withLatestFrom(this.store$.select(getUserId))
          )
        ),
        map(([[action, state], userId]) => ({ prop: 'description', action, state, userId })),
        filter(({ prop, action, state, userId }) => action[prop] && action[prop].length > 0),
        tap(({ prop, action, state, userId }) => {
          this.updateProp(action, state, prop, userId);
        })
      ),
    { dispatch: false }
  );
  /** updatePrivacy changed from private<boolean> to privacy enum */
  updateStackPrivacy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mystackActions.updatePrivacy),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.pipe(select(selectMyStackState))),
            withLatestFrom(this.store$.select(getUserId))
          )
        ),
        map(([[action, state], userId]) => ({ prop: 'privacy', action, state, userId })),
        filter(({ prop, action, state, userId }) => Object.values(STACK_PRIVACY).includes(action[prop])),
        // filter(({ prop, action, state, userId }) => typeof action[prop] === 'boolean'),
        tap(({ prop, action, state, userId }) => {
          console.log({ prop, action, state, actionVal: action[prop] });
          this.updateProps(
            [
              {
                prop: 'private',
                value: action[prop] === STACK_PRIVACY.PRIVATE,
              },
              {
                prop: 'privacy',
                value: action[prop],
              },
              // if this was made private then remove featured
              ...(action[prop] === STACK_PRIVACY.PRIVATE
                ? [
                    {
                      prop: 'featured',
                      value: 0,
                    },
                  ]
                : []),
            ],
            state,
            userId
          );
        })
      ),
    { dispatch: false }
  );

  /**
   * We Edited MyStack - check if there's a stackId and update stack in api
   */
  updateStackPoster$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mystackActions.updatePoster),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.pipe(select(selectMyStackState))),
            withLatestFrom(this.store$.select(getUserId))
          )
        ),
        map(([[action, state], userId]) => ({ prop: 'poster', action, state, userId })),
        filter(({ prop, action, state, userId }) => state.stackId && action[prop] && action[prop].length > 0),
        tap(({ prop, action, state, userId }) => {
          // we need to delay this so that the DB exists when creating a new draft
          interval(900)
            .pipe(take(1))
            .subscribe(() => {
              this.updateProp(action, state, prop, userId);
            });
        })
      ),
    { dispatch: false }
  );

  /**
   * We Edited MyStack - check if there's a stackId and update stack in api
   */
  updateStackDuration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mystackActions.updateDuration),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.pipe(select(selectMyStackState))),
            withLatestFrom(this.store$.select(getUserId))
          )
        ),
        map(([[action, state], userId]) => ({ prop: 'duration', action, state, userId })),
        filter(({ prop, action, state, userId }) => state.stackId && action[prop] && action[prop].length > 0),
        tap(({ prop, action, state, userId }) => {
          // we need to delay this so that the DB exists when creating a new draft
          interval(900)
            .pipe(take(1))
            .subscribe(() => {
              this.updateProp(action, state, prop, userId);
            });
        })
      ),
    { dispatch: false }
  );

  /**
   * We Edited MyStack - check if there's a stackId and update stack in api
   */
  updateStackPlaylistReordered$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mystackActions.reorderClipIds),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.pipe(select(selectMyStackState))),
            withLatestFrom(this.store$.select(getUserId))
          )
        ),
        map(([[action, state], userId]) => ({ prop: 'playlist', clipIds: action.clipIds, state, userId })),
        // filter(({ prop, action, state }) => state.stackId && action[prop] && action[prop].length > 0),
        tap(({ prop, clipIds, state, userId }) => {
          DEBUG_LOGS && console.log(`VERIFY: Update API..`, { prop, clipIds, state });
          this.updateProp(state, state, prop, userId);
        })
      ),
    { dispatch: false }
  );

  /**
   * We Add Clip(s) to MyStack - update stack in api
   */
  addClipUpdateApi$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mystackActions.addClip),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.pipe(select(selectMyStackState))),
            withLatestFrom(this.store$.pipe(select(selectMyStackClips))),
            withLatestFrom(this.store$.select(getUserId))
          )
        ),
        map(([[[action, state], clips], userId]) => ({ prop: 'playlist', clip: action.clip, clips, state, userId })),
        // filter(({ prop, action, state }) => state.stackId && action[prop] && action[prop].length > 0),
        tap(({ prop, clip, clips, state, userId }) => {
          // if is done transcoding? no regardless we should update playlist - a clip was added!

          /** update duration, noting that it might be 00:00 for a newly uploaded clip.. */
          const durations = clips?.filter((c) => c?.duration).map((c) => c.duration) ?? [];
          const updates = [
            {
              prop,
              value: state[prop],
            },
            {
              prop: 'duration',
              value: Utils.getTotalDuration(durations),
            },
          ];
          // also check if the stack needs a poster
          if (!state.poster || state.poster === DEFAULT_POSTER) {
            const foundPoster = clips.find((c) => c?.poster?.length > 0);
            if (foundPoster?.poster) {
              updates.push({
                prop: 'poster',
                value: foundPoster.poster,
              });
            }
          }
          if (updates.length > 0) {
            DEBUG_LOGS && console.log(`VERIFY: Update API..`, { updates, durations, clip, clips, state });
            this.updateProps(updates, state, userId);
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * when adding clipIds, update the playlist via API
   */
  addClipsUpdateApi$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mystackActions.addClipIds),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.pipe(select(selectMyStackState))),
            withLatestFrom(this.store$.select(getUserId))
          )
        ),
        map(([[action, state], userId]) => ({ prop: 'playlist', clipIds: action.ids, state, userId })),
        // filter(({ prop, action, state }) => state.stackId && action[prop] && action[prop].length > 0),
        tap(({ prop, clipIds, state, userId }) => {
          console.log(`VERIFY: Update API..`, { prop, clipIds, state });
          this.updateProp(state, state, prop, userId);
        })
      ),
    { dispatch: false }
  );

  /**
   * We Edited MyStack - check if there's a stackId and update stack in api
   */
  updateMyStack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mystackActions.updateMyStack),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.select(selectStackEntities)),
            withLatestFrom(this.store$.select(selectMyStackState)),
            withLatestFrom(this.store$.select(selectMyStackClips)),
            withLatestFrom(this.store$.select(getUserId))
          )
        ),
        map(([[[[{ stack, updates }, stackEntities], mystackState], clips], userId]) => ({
          stack,
          updates,
          stackEntities,
          mystackState,
          clips,
          userId,
        })),
        // filter(({ stack, updates, stackEntities, mystackState }) => stackEntities && stackEntities[id] && stackEntities[id].stackId && stackEntities[id].stackId.length > 0),
        mergeMap(({ stack, updates, stackEntities, mystackState, clips, userId }) => {
          DEBUG_LOGS && console.log(`[Effect] updateMyStack$`, { stack, updates, stackEntities, mystackState });
          /**
           * check if there's a stackId and if so
           * update that stack in API and then
           * update both stack entities (mystack already done in resolver)
           */
          if (stack && stack.stackId) {
            if (!stack.poster || stack.poster === STACK_DEFAULT_POSTER) {
              const clip = clips.find((c) => c?.poster?.length > 0 && c.poster !== DEFAULT_POSTER);
              if (clip?.poster) {
                (updates as UpdateParam[]).push({ prop: 'poster', value: clip.poster });
              }
            }
            (updates as UpdateParam[]).push({ prop: 'updatedBy', value: userId });
            (updates as UpdateParam[]).push({ prop: 'dteSaved', value: new Date().toISOString() });
            return from(this.stacksApi.updateStack(stack, updates)).pipe(
              take(1),
              tap((res) => {
                DEBUG_LOGS && console.log(`[mystackEffects.updateMyStack] Updated Stack:`, res);
              }),
              map((res) => stackActions.update({ stack: res, updates })),
              catchError((error) => {
                console.error(error);
                return EMPTY;
              })
            );
          }
          return EMPTY;
          // return [
          //   mystackActions.newMyStack({ stack }),
          //   mystackActions.addClipIds({ ids: stack.playlist.map(p => ({ projectId: p.projectId, id: p.id })) })
          // ];
        }),
        catchError((error) => {
          console.error(error);
          return EMPTY;
        })
        // ));
      ),
    { dispatch: false }
  );

  /**
   * A Stack was selected to edit, Effect will verify loaded
   * also watch loadSuccess to see if id = slectedEdit and do same things
   */
  selectIdEdit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stackActions.selectIdEdit),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.select(selectStackEntities)),
          withLatestFrom(this.store$.select(selectMyStackState))
        )
      ),
      map(([[{ projectId, stackId }, stackEntities], mystackState]) => ({
        id: getStackId(projectId, stackId),
        projectId,
        stackId,
        stackEntities,
        mystackState,
      })),
      filter(({ id, projectId, stackId, stackEntities, mystackState }) => stackEntities?.[id]?.stackId?.length > 0),
      switchMap(({ id, projectId, stackId, stackEntities, mystackState }) => {
        // this action id already exists in stacksState, use this one as our mystack
        // but first verify if there's any clips in my stack that might get lost

        const isSame = mystackState && mystackState.projectId === projectId && mystackState.stackId === stackId;
        // if the current Edit Stack is the same as our stack, lets merge
        const stack = isSame ? mergeMyStackClips(stackEntities[id], mystackState) : stackEntities[id];

        DEBUG_LOGS &&
          console.log(`[Effect] selectIdEdit$ is same?`, {
            isSame,
            merged: stack,
            mystackState,
            doAnalyticsAndNewStack: !isSame || mystackState.loading || !mystackState.loaded,
          });
        if (!isSame || mystackState.loading || !mystackState.loaded) {
          this.analyticsService.selectStackEdit(stack);
          return [
            mystackActions.newMyStack({ stack }),
            // make sure all the clips are in state
            mystackActions.loadClipIds({ ids: stack.playlist.map((p) => ({ projectId: p.projectId, id: p.id })) }),
          ];
        }
        // make sure all the clips are in state
        return [mystackActions.loadClipIds({ ids: stack.playlist.map((p) => ({ projectId: p.projectId, id: p.id })) })];
      }),
      catchError((error) => {
        console.error(error);
        return EMPTY;
      })
    )
  );

  /**
   * A Stack was loaded, Effect will verify if this is the selectedEdit id
   * also watching setSelectedEditId to do same things
   */
  checkLoadSuccessMystack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stackActions.loadSuccess, stackActions.add),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.select(selectIdEdit)),
          withLatestFrom(this.store$.select(selectMyStackState))
        )
      ),
      map(([[{ stacks }, selectedIdEdit], mystackState]) => ({ stacks, selectedIdEdit, mystackState })),
      filter(({ stacks, selectedIdEdit, mystackState }) => Array.isArray(stacks)),
      map(({ stacks, selectedIdEdit, mystackState }) => {
        const { projectId, stackId } = splitStackId(selectedIdEdit);
        // see if this payload has what we're looking for
        const selected = stacks.find((stack) => stack && stack.projectId === projectId && stack.stackId === stackId);
        return {
          selected,
          projectId,
          stackId,
          mystackState,
        };
      }),
      filter(({ selected, projectId, stackId, mystackState }) => selected?.stackId?.length > 0),
      // filter(({ selected, projectId, stackId, mystackState }) => selected.stackId === stackId && selected.projectId === projectId),
      switchMap(({ selected, projectId, stackId, mystackState }) => {
        // the selected Edit stack is in payload, use this one as our mystack
        // but first verify if there's any clips in my stack that might get lost

        // the current Edit Clip is the same as our stack, lets merge
        const isSame = mystackState && mystackState.projectId === projectId && mystackState.stackId === stackId;

        const stack = isSame ? mergeMyStackClips(selected, mystackState) : selected;

        if (!isSame || mystackState.loading || !mystackState.loaded) {
          // this stack should be our new stack, as it was loaded from api -> update via newMyStack
          return [mystackActions.newMyStack({ stack })];
        }
        DEBUG_LOGS &&
          console.log(`checkLoadSuccessMystack$ same mystack, losing clips?`, {
            stack: { clipIds: stack.clipIds, playlist: stack.playlist },
            mystackState: { clipids: mystackState.clipIds, playlist: mystackState.playlist },
          });

        // since it's the same, just update what was loaded from api...
        // but using newMyStack since it's easier than extracting the changes?
        return [mystackActions.newMyStack({ stack })];
      }),
      catchError((error) => {
        console.error(error);
        return EMPTY;
      })
    )
  );

  /**
   * Load All Clips for MyStack
   * Clip Logic (not loading existing clips, notifying errors) handled in clipActions.loadBatchIds
   * Store Selector getMyStackClips handles checking against ClipStore for undefined or missing entites
   *
   * @todo revisit this logic, it appears to be depending on entities in local mystack store - should be combining stack entities
   */
  loadMyStack$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mystackActions.load.type),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store$.select(selectMyStackState)))),
      // tap(([action, state]) => { console.log({state, clipIds: state.clipIds, test: state && Array.isArray(state.clipIds) && state.clipIds.length > 0});  }),
      filter(([action, state]) => state && Array.isArray(state.clipIds) && state.clipIds.length > 0),
      map(([action, state]) => {
        // check if we have all the entites, typically happens when captured clips exist
        if (state.entities && state.clipIds.length === Object.keys(state.entities).length) {
          // done, update entities
          DEBUG_LOGS &&
            console.log(`${PAGE} loadMyStack clipActions.addClips`, { clips: Object.values(state.entities) });
          this.store$.dispatch(clipActions.addClips({ clips: Object.values(state.entities) }));
        } else {
          const actionIds = state.clipIds.map(splitClipId).filter((c) => c.projectId && c.id);
          DEBUG_LOGS && console.log(`${PAGE} loadMyStack$ :`, { actionIds, state });
          // add missing clips to store
          this.store$.dispatch(
            clipActions.loadBatchIds({ ids: actionIds, stack: new Stack({ projectId: 'editor', stackId: 'mystack' }) })
          ); // stack: new Stack(state) ?
        }
        return state;
      }),
      map((state) => mystackActions.loadSuccess({ stack: { projectId: state.projectId, stackId: state.stackId } })),
      catchError((error) => {
        this.sentryService.captureError(error);
        return of(mystackActions.loadFail({ error }));
      })
    )
  );

  /**
   * Load Clips if there are not already in ClipStore
   * Also, update mystack poster if it is not already set
   */
  addClipsById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mystackActions.addClipIds.type, mystackActions.loadClipIds.type),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.select(selectClipIds)),
          withLatestFrom(this.store$.select(selectClipEntities)),
          withLatestFrom(this.store$.select(selectMyStackState))
        )
      ),
      filter(([[[action, clipIds], clips], mystack]) => action && Array.isArray(action.ids) && action.ids.length > 0),
      mergeMap(([[[{ ids }, clipIds], clips], mystack]) => {
        // get the ids that do not exist in the clipStore
        const newClipIds = ids.map((id) => getClipId(id.projectId, id.id));
        const newIds = newClipIds.filter((id) => (clipIds as string[]).indexOf(id) < 0);
        const actions = [];
        try {
          if ((!mystack.poster || mystack.poster === STACK_DEFAULT_POSTER) && newIds.length !== ids.length) {
            // our stack does not have a poster yet, let's see if we can use one of these
            // if the newIds.length !== ids.length there's at least one that exists
            const existingClips = newClipIds
              .filter((id) => (clipIds as string[]).indexOf(id) >= 0)
              .map((id) => clips[id]);
            // take the first one
            const poster = existingClips.find((clip) => clip?.poster)?.poster;
            if (poster) {
              DEBUG_LOGS &&
                console.log(`Updating mystack.poster with clip poster:`, {
                  poster,
                  newClipIds,
                  newIds,
                  mystack_poster: mystack.poster,
                  mystack,
                });
              actions.push(mystackActions.updatePoster({ poster })); // this will update the api too, in effect
            }
          }
          // check if the duration needs set (may happen with new mystack when playlist added initially)
          if ((!mystack.duration || mystack.duration === '00:00') && newClipIds.length > 0) {
            const newClips = newClipIds.map((id) => clips[id]);
            const durations = newClips.filter((clip) => clip?.duration).map((clip) => clip.duration);
            actions.push(mystackActions.updateDuration({ duration: Utils.getTotalDuration(durations) }));
          }
        } catch (error) {
          console.warn(`Caught Error while trying to get a poster?`, error);
        }

        if (newIds.length < 1) {
          return actions.length > 0 ? actions : EMPTY;
        }
        DEBUG_LOGS && console.log(`${PAGE} addClipsById$ post-filter mergeMap newIds:`, newIds); //, state);
        return [...actions, clipActions.loadBatchIds({ ids: newIds.map(splitClipId) })];
      }),
      // if we see an error here, need to reset store ...are you sure?!?!
      catchError((error) => {
        console.error(error);
        return of(mystackActions.reset());
      })
    )
  );

  /**
   * When Adding a Clip to MyStack, FLASH_MYSTACK
   */
  addClip$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mystackActions.addClip.type, mystackActions.addClipIds.type),
        switchMap((action) => {
          this.events.publish(EventActions.FLASH_MYSTACK);
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  /**
   * When Adding a Clip to MyStack, FLASH_MYSTACK
   */
  subClipCheckPoster$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clipActions.subUpdate),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.select(selectMyStackClipIds)),
            withLatestFrom(this.store$.select(selectMyStackState)),
            withLatestFrom(this.store$.select(getUserId))
          )
        ),
        // filter(([[action, clipIds], state) => action[prop] && action[prop].length > 0),
        switchMap(([[[action, clipIds], state], userId]) => {
          if (!state.poster || state.poster === STACK_DEFAULT_POSTER) {
            if (action?.clip?.poster?.length > 0 && action.clip.poster !== DEFAULT_POSTER) {
              this.updateProp({ poster: action.clip.poster }, state, 'poster', userId);
            }
          }
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  /**
   * Take the stack playlist and apply it, if was added to current mystack
   */
  addPlaylistToDraft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(stackActions.addToStackIdPlaylist),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store$.select(selectAddToStackPlaylist)),
          withLatestFrom(this.store$.select(selectMyStackState))
        )
      ),
      map(([[{ projectId, stackId }, stackPlaylist], mystackState]) => ({
        projectId,
        stackId,
        stackPlaylist,
        mystackState,
      })),
      filter(
        ({ projectId, stackId, stackPlaylist, mystackState }) =>
          projectId && stackId && mystackState.stackId === stackId && mystackState.projectId === mystackState.projectId
      ),
      mergeMap(({ projectId, stackId, stackPlaylist, mystackState }) => {
        this.events.publish(EventActions.FLASH_MYSTACK);
        return [mystackActions.addClipIds({ ids: stackPlaylist.map((p) => ({ projectId: p.projectId, id: p.id })) })];
      })
    )
  );

  constructor(
    private actions$: Actions<mystackActions.ActionsUnion>,
    private store$: Store<State>,
    private stacksApi: StacksApiService,
    private events: EventsService,
    private analyticsService: AnalyticsService,
    private sentryService: SentryService
  ) {}

  /**
   * Helper method for Errors
   */
  private captureError(error, msg = ''): void {
    const e = error && Array.isArray(error.errors) && error.errors.length > 0 ? error.errors[0] : error;
    console.warn(`${PAGE} ${msg} captureError`, e);
    this.sentryService.captureError(e);
  }

  private updateProp(action, stack, prop: string, userId) {
    if (!stack || !stack.projectId || !stack.stackId) {
      // Avoid Sentry-11C Missing required arguments: stack ids
      // this may happen when clip added and poster is being set but the Stack is not yet id'd ?
      !environment.production && console.warn('[MystackEffetcs] updateProp missing stack - why?');
      return;
    }
    const updates: UpdateParam[] = [
      {
        prop,
        value: action[prop],
      },
      {
        prop: 'updatedBy',
        value: userId,
      },
      { prop: 'dteSaved', value: new Date().toISOString() },
    ];
    DEBUG_LOGS && console.log(`${PAGE} action -> api ${prop}`, { action, updates, stack });
    this.stacksApi.updateStack(stack, updates).catch((error) => {
      this.captureError(error, `mystackEffects api update '${prop}' caught`);
    });
  }
  private updateProps(props = [], stack: Partial<Stack> = {}, userId) {
    if (!stack || !stack.projectId || !stack.stackId) {
      // Avoid Sentry-11C Missing required arguments: stack ids
      // but..why does this happen?
      !environment.production && console.warn('[MystackEffetcs] updateProps missing stack - why?');
      return;
    }
    // { prop: string, value }[]
    const updates: UpdateParam[] = props.map(({ prop, value }) => ({
      prop,
      value,
    }));
    updates.push({ prop: 'updatedBy', value: userId });
    if (!updates.find((u) => u.prop === 'dteSaved')) {
      updates.push({ prop: 'dteSaved', value: new Date().toISOString() });
    }

    DEBUG_LOGS && console.log(`${PAGE} action -> api ${stack.stackId}`, { props, updates, stack });
    this.stacksApi.updateStack(stack as Stack, updates).catch((error) => {
      this.captureError(error, `mystackEffects api update caught`);
    });
  }
}
