/**
 * @format
 */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Utils } from '@app/shared/utils';
import { environment } from 'src/environments/environment';

const DEBUG_LOGS = false;

const PAGE = '[GoogleAnalytics]';

/*

  GA 4

*/

export enum AuthMethod {
  Email = 'email',
  Username = 'username', // userId || preferred_username?
  Invite = 'invite',
  // Phone = 'phone',
}

export enum SelectContentType {
  Stack = 'stack',
  Clip = 'clip',
  Project = 'project',
  StackEdit = 'stack_edit',
  StackPlay = 'stack_play',
}
export enum ProjectRole {
  Owner = 'owner',
  Executive = 'executive',
  Producer = 'producer',
  Crew = 'crew',
  Public = 'public',
}
export interface ShareInput {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  method: 'Twitter' | 'Facebook' | 'LinkedIn' | 'mobile_native' | 'clipboard' | any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content_type?: 'image' | 'stack' | 'project' | 'clip' | 'unknown' | any;
  item_id?: string;
}
export interface SelectItemInput {
  /** The items array is expected to have a single element, representing the selected item */
  items: SelectItem[];
  item_list_id?: string; // listId
  item_list_name?: string; // label + type
}
/**
 * select_item input
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#select_item_item
 */
export interface SelectItem {
  /** One of item_id or item_name is required */
  item_id: string; // The ID of the item.
  item_name: string; // The name of the item.
  /** Event-level and item-level affiliation parameters are independent. */
  affiliation?: string; //	A product affiliation to designate a supplying company or brick and mortar store location.
  /** Event-level and item-level coupon parameters are independent. */
  coupon?: string; // The coupon name/code associated with the item.
  /** Multiple currencies per event is not supported. Each item should set the same currency. */
  currency?: string; // USD The currency, in 3-letter ISO 4217 format. // If set, event-level currency is ignored.
  discount?: number; // The monetary discount value associated with the item.
  index?: number; // The index/position of the item in a list.
  item_brand?: string; // The brand of the item.
  item_category?: string; // The category of the item. If used as part of a category hierarchy or taxonomy then this will be the first category.
  item_category2?: string; // The second category hierarchy or additional taxonomy for the item.
  item_category3?: string; // The third category hierarchy or additional taxonomy for the item.
  item_category4?: string; // The fourth category hierarchy or additional taxonomy for the item.
  item_category5?: string; // sleeve	The fifth category hierarchy or additional taxonomy for the item.
  /**
   * If set, event-level item_list_id is ignored.
   * If not set, event-level item_list_id is used, if present.
   */
  item_list_id?: string; // The ID of the list in which the item was presented to the user.
  /**
   * If set, event-level item_list_name is ignored.
   * If not set, event-level item_list_name is used, if present.
   */
  item_list_name?: string; // The name of the list in which the item was presented to the user.
  item_variant?: string; // The item variant or unique code or description for additional item details/options.
  /**
   * If set, event-level location_id is ignored.
   * If not set, event-level location_id is used, if present.
   */
  location_id?: string; // The location associated with the item. It's recommended to use the Google Place ID that corresponds to the associated item. A custom location ID can also be used.
  price?: number; // The monetary price of the item, in units of the specified currency parameter.
  quantity?: number; // Item quantity.
}

/*
GA 3
Events:
appEvent
setUser
pageviewCustomEvent
appScreenView
*/
// declare const ga: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let dataLayer: any;

/* TODO: Ionic Native GA
  ref: https://ionicframework.com/docs/native/google-analytics/
*/
@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  ready: boolean = false;

  /*
      GA4
  */

  /**
   * Log this event when a user joins a group such as a guild, team, or family.
   * Use this event to analyze how popular certain groups or social features are.
   * @param group_id string - The ID of the group.
   */
  joinGroup(group_id: string) {
    if (!group_id) return;
    dataLayer.push({
      event: 'join_group',
      group_id, //: "G_12345"
    });
  }
  /**
   * @param method string - The method used to login
   */
  login(method: AuthMethod) {
    dataLayer.push({
      event: 'login',
      method,
    });
  }
  /**
   * This event indicates that a user has signed up for an account.
   * Use this event to understand the different behaviors of logged in and logged out users.
   * @param method 'email' | 'invite' - The method used for sign up.
   * check if invited crew
   */
  signUp(method: AuthMethod) {
    dataLayer.push({
      event: 'sign_up',
      method,
    });
  }
  /**
   * Event: 'share'
   * Intent to Share Stack (shack.numShares)
   * Conversion: Successful share - app opens with direct link to Stack Player (/stack/play/stackid)
   * - new vs returning user
   * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#share
   * @param method string - The method in which the content is shared.
   * @param content_type string - The type of shared content.
   * @param item_id string - The ID of the shared content.
   */
  share({ method, content_type, item_id }: ShareInput) {
    if (!content_type && !item_id) return;
    dataLayer.push({
      event: 'share',
      method,
      content_type,
      item_id,
    });
  }

  /**
   * Use this event to contextualize search operations. This event can help you identify the most popular content in your app.
   * @param search_term string REQUIRED - The term that was searched for.
   */
  search(search_term: string) {
    if (!search_term) return;
    dataLayer.push({
      event: 'search',
      search_term,
    });
  }

  /*
   * Custom Events
   */

  clipUpload({
    projectId,
    id,
    userId,
    seconds,
    size,
  }: {
    projectId: string;
    id: string;
    userId: string;
    seconds?: string;
    size?: string;
  }) {
    dataLayer.push({
      event: 'clip_upload',
      project: projectId,
      clip: id,
      user: userId,
      seconds,
      size,
    });
  }
  watchStack({ projectId, stackId }) {
    dataLayer.push({
      event: 'watch_stack',
      project: projectId,
      stack: stackId,
    });
  }
  watchStackComplete({ projectId, stackId }) {
    dataLayer.push({
      event: 'watch_stack_complete',
      project: projectId,
      stack: stackId,
    });
  }
  watchStackAgain({ projectId, stackId }) {
    dataLayer.push({
      event: 'watch_stack_again',
      project: projectId,
      stack: stackId,
    });
  }
  stackPublished({ projectId, stackId, role }: { projectId: string; stackId: string; role: ProjectRole }) {
    dataLayer.push({
      event: 'stack_published',
      project: projectId,
      stack: stackId,
      role,
    });
  }
  projectPosterUpdated(projectId, imageUrl) {
    dataLayer.push({
      event: 'project_poster_update',
      project: projectId,
      image_url: imageUrl,
    });
  }
  stackApproved({ projectId, stackId, isApproved }) {
    dataLayer.push({
      event: 'stack_approved',
      project: projectId,
      stack: stackId,
      approved: isApproved,
    });
  }
  userUpdated({ property, value, userId }) {
    dataLayer.push({
      event: 'user_updated',
      property,
      value,
      user: userId,
    });
  }

  /**
   * select_content
   * This event signifies that a user has selected some content of a certain type.
   * This event can help you identify popular content and categories of content in your app.
   * https://developers.google.com/analytics/devguides/collection/ga4/reference/events#select_content
   * @params content_type string - The type of selected content.
   * @params item_id string - An identifier for the item that was selected.
   *
   * GOAL: Record number of project users have (invited/create)
   * add events to “let’s do this“ and “add user to project”
   * event: campaign_id (for weddings?) project_id
   * event: select_content
   */
  selectContent({ content_type, item_id }: { item_id?: string; content_type?: SelectContentType }) {
    if (!content_type && !item_id) return;
    dataLayer.push({
      event: 'select_content',
      content_type,
      item_id,
    });
  }
  /**
   * @todo implement when makes sense...
   *
   * This event signifies an item was selected from a list.
   * @param item_list_id string - The ID of the list in which the item was presented to the user. Ignored if set at the item-level.
   * @param item_list_name string - The name of the list in which the item was presented to the user. Ignored if set at the item-level.
   * @param items Array<Item>	REQUIRED* - The items for the event.
   * The items array is expected to have a single element, representing the selected item.
   * If multiple elements are provided, only the first element in items will be used.
   */
  selectItem({ item_list_id, item_list_name, items }: SelectItemInput) {
    if (!Array.isArray(items)) return;
    dataLayer.push({
      event: 'select_item',
      item_list_id, //: "related_products",
      item_list_name, //: "Related products",
      items,
      // {
      //   item_id: "SKU_12345",
      //   item_name: "Stan and Friends Tee",
      //   affiliation: "Google Merchandise Store",
      //   coupon: "SUMMER_FUN",
      //   currency: "USD",
      //   discount: 2.22,
      //   index: 0,
      //   item_brand: "Google",
      //   item_category: "Apparel",
      //   item_category2: "Adult",
      //   item_category3: "Shirts",
      //   item_category4: "Crew",
      //   item_category5: "Short sleeve",
      //   item_list_id: "related_products",
      //   item_list_name: "Related Products",
      //   item_variant: "green",
      //   location_id: "L_12345",
      //   price: 9.99,
      //   quantity: 1
      // }
    });
  }

  /**
   * This event signifies that a player has reached the end of a level.
   * @param level_name The name of the level.
   * @param success boolean Set to true if the level was completed successfully.
   */
  stackEnd(level_name: string, success = true) {
    if (!level_name) return;
    dataLayer.push({
      event: 'level_end',
      level_name,
      success,
    });
  }
  /**
   * This event signifies that a player has started a level.
   * @param level_name The name of the level.
   */
  stackStart(level_name: string) {
    if (!level_name) return;
    dataLayer.push({ event: 'level_start', level_name });
  }
  /**
   * This event signifies the start of the on-boarding process. Use this in a funnel with tutorial_complete to understand how many users complete the tutorial.
   */
  tutorialBegin() {
    dataLayer.push({ event: 'tutorial_begin' });
  }
  /**
   * This event signifies the user's completion of your on-boarding process.
   * Use this in a funnel with tutorial_begin to understand how many users complete the tutorial.
   */
  tutorialComplete() {
    dataLayer.push({ event: 'tutorial_complete' });
  }

  /**
   * @todo when makes sense
   * Log this event when the user has unlocked an achievement.
   * This event can help you understand how users are experiencing your game.
   * @param achievement_id REQUIRED - The id of the achievement that was unlocked.
   */
  // unlockAchievement(achievement_id: string) {
  //   if (!achievement_id) return;
  //   dataLayer.push({ event: 'unlock_achievement', achievement_id });
  // }

  /*
      UA3
  */

  sendInitEvent() {
    DEBUG_LOGS && console.log('GA init');
    try {
      const hostname =
        window && window.location && window.location.hostname ? window.location.hostname : '!window.location.hostname';
      let eventLabel = '';

      /**
       * Dev: capture widget session
       * @todo remove this once verified
       *
       * var url = (window.location != window.parent.location)
       *    ? document.referrer
       *    : document.location.href;
       *
       * Causes Error: SecurityError: Blocked a frame with origin "https://app.filmstacker.com" from accessing a cross-origin frame
       */
      try {
        if (document.referrer && !document.referrer.startsWith('https://app.filmstacker.com')) {
          DEBUG_LOGS && console.log(`[GA] Document referrer: '${document.referrer}'`);
          eventLabel = `Referrer: ${document.referrer}`;
        }
      } catch (error) {
        console.warn(`[App] document.referrer caught:`, error);
      }

      // this.configService.loadConfig().then(config => {
      //   if (config.isWidgetActive) {
      //     eventLabel = `Event: ${config.eventTitle} [${config.event}] [${config.appVersion}]`;
      //   } else {
      //     eventLabel = `${config.projectId} [${config.appVersion}]`;
      //   }
      // });

      const version = Utils.getAppVersion(environment.version, environment.production);

      dataLayer.push({
        event: 'appEvent',
        eventAction: `${version} : ${environment.name} : ${hostname}`,
        eventLabel,
        eventCategory: 'app.init',
        // page_title: (always Filmstacker in GA)
      });
    } catch (error) {
      console.warn(error);
      dataLayer = dataLayer && typeof dataLayer.push === 'function' ? dataLayer : [];
    }
  }

  /**
   * Capturing the User Event
   * @param user
   */
  setUser(user: { id: string; userId: string; username: string; email?: string }) {
    const {
      // id,
      username,
      userId,
      // email?
    } = user;
    // console.log(`${PAGE} setUser`, { id, username });

    const version = Utils.getAppVersion(environment.version, environment.production);

    dataLayer.push({
      event: 'setUser',
      eventAction: userId,
      eventLabel: `${version} : ${environment.name}`,
      // eventLabel: id,
      eventCategory: 'user',
    });

    this.addTag({ name: 'user', value: username });
  }

  pushNavEvent({ path, title }: { path: string; title: string }) {
    DEBUG_LOGS && console.log(`${PAGE} pushNavEvent`, { path, title });
    dataLayer.push({
      event: 'pageviewCustomEvent',
      pagePath: path,
      pageTitle: title,
      page_title: title,
    });
  }

  /**
   dataLayer.push({
    'color': 'red',
    'conversionValue': 50,
    'event': 'customizeCar'
    });
   * @param eventName 
   */
  pushEvent({ event = 'appEvent', action = '', label = '', category = '' }) {
    dataLayer.push({
      event,
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    });
  }

  /**
   * dataLayer.push({'variable_name': 'variable_value'});
   * @param eventName
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addTag({ name = '', value = '' }) {
    dataLayer.push({ name: value });
  }

  // sendTestEvent() {
  //   this.pushEvent('Test');
  // }

  onScreenLoad() {
    dataLayer.push({
      screenPath: 'home',
      screenName: 'Home Page',
    });
    dataLayer.push({ event: 'appScreenView' });
  }

  onTopnavClick(label: string) {
    //send event
    dataLayer.push({
      appEventCategory: 'topnav',
      appEventAction: 'click',
      appEventLabel: label,
    });
    // dataLayer.push({ event: 'appEvent' });
  }

  startNativeTracker() {
    console.log(`${PAGE} startNativeTracker not used...`);
    // this.platform.ready().then((readySource) => {

    //   // dataLayer.push({
    //   //   'screenPath': 'about',
    //   //   'screenName': 'About Us'
    //   //   });
    //   // dataLayer.push({'event': 'appScreenView'})

    //   if (readySource != "dom") { // ==  (this.platform.is('ios') || this.platform.is('android'))
    //     if (this.platform.is('ios') || this.platform.is('android')) {
    //       // set startTrackerWithId
    //       // , interval:	number	= Optional dispatch period in seconds. Defaults to 30.
    //       this.googleAnalytics.startTrackerWithId(GA_ID, 30)
    //         .then(() => {
    //           console.log(`${PAGE} Google analytics is ready now`);
    //           this.ready = true;

    //           // You can now track pages or set additional information such as AppVersion or UserId

    //           if (environment.name != 'prod') {
    //             console.log(`${PAGE} dev mode -> debugMode`);
    //             this.googleAnalytics.debugMode();
    //           }
    //           /**
    //            * Enabling Advertising Features in Google Analytics allows you to take advantage of
    //            * Remarketing, Demographics & Interests reports, and more
    //            */
    //           // this.googleAnalytics.setAllowIDFACollection(true);
    //         })
    //         .catch(e => console.log('Error starting GoogleAnalytics', e));
    //     } else {
    //       // web
    //       this.ready = true;
    //       // console.log(`${PAGE} web ga:`);//, ga);
    //       //  ga('create', 'UA-XXXXX-XX', 'auto');
    //       //  ga('send', 'pageview');

    //     }
    //   }
    // });
  }

  // examples() {

  //   this.googleAnalytics.startTrackerWithId(GA_ID, 30)
  //     .then(() => {
  //       console.log('Google analytics is ready now');

  //       this.googleAnalytics.trackView('Screen Title', 'my-scheme://content/1111?utm_source=google&utm_campaign=my-campaign')
  //         .then(() => {
  //           console.log("Success 1")
  //         })
  //         .catch((e) => {
  //           console.log("Faild 1" + e)
  //         })

  //       this.googleAnalytics.trackEvent('Category', 'Action', 'Label', 30)
  //         .then(() => {
  //           console.log("Success Event");
  //         })
  //         .catch(() => {
  //           console.log("Faild")
  //         })

  //       this.googleAnalytics.trackMetric(5)
  //         .then(() => {
  //           console.log("Key Matrics run successfully");
  //         })
  //         .catch((e) => {
  //           console.log("Faild" + e)
  //         })

  //       this.googleAnalytics.trackTiming('Category', 2, 'Variable', 'Label')
  //         .then(() => {
  //           console.log("TrackTiming success")
  //         })
  //         .catch((e) => {
  //           console.log("Faild" + e)
  //         })
  //       // Tracker is ready

  //     })

  // }
}
