/** @format */

import { createAction, props, union } from '@ngrx/store';
import { Clip } from '@app/shared/models/clip.model';

export const reset = createAction('[WatchLater] Reset');
export const addClip = createAction('[WatchLater] Add Clip', props<{ clip: Clip }>());
export const removeClip = createAction('[WatchLater] Remove Clip', props<{ clip: Clip }>());

export const hydrated = createAction('[WatchLater] Hydrated');
export const load = createAction('[WatchLater] Load');
export const loadSuccess = createAction(
  '[WatchLater] Load Success',
  props<{ clips: Clip[]; current: string | null }>()
);
export const loadFail = createAction('[WatchLater] Load Fail', props<{ error: string }>());
export const clearAllClips = createAction('[WatchLater] Clear All Clips');
// export const removeClipSuccess = createAction(
//   '[WatchLater] Remove Clip Success',
//   props<{ clips: Clip[] }>()
// );
// export const removeClipFail = createAction(
//   '[WatchLater] Remove Clip Fail',
//   props<{ clips: Clip[]; error: any }>()
// );

// do not export return value
const actions = union({
  reset,
  addClip,
  // addClipSuccess,
  // addClipFail,
  removeClip,
  // removeClipSuccess,
  // removeClipFail,
  clearAllClips,
  hydrated,
  load,
  loadSuccess,
  loadFail,
});

// export only type
export type ActionsUnion = typeof actions;
