/** @format */

import { createAction, props, union } from '@ngrx/store';
import { Update, EntityMap, Predicate } from '@ngrx/entity';
import { List } from '@app/shared/models/list.model';

/**
 * Lists / Carousels Actions
 *
 * loadList (+API)
 * loadSuccess (+API)
 * loadFail (+API)
 * loadMore (+API)
 *
 */

// export const loadList = createAction(
//   '[ListStore] Load List',
//   props<{ list: List }>());
// export const loadLists = createAction(
//   '[ListStore] Load Lists',
//   props<{ lists: List[] }>());

// export const loadSuccess = createAction(
//   '[ListStore] Load List Success',
//   props<{ list: List }>());
// export const loadListsSuccess = createAction(
//   '[ListStore] Load Lists Success',
//   props<{ lists: List[] }>());
// export const loadFail = createAction(
//   '[ListStore] Load Lists Fail',
//   props<{ error: any; lists: List[] }>());

// export const loadMore = createAction(
//   '[ListStore] Load More',
//   props<{ id: string; }>());

// export const loadMoreSuccess = createAction(
//     '[ListStore] Load More List Success',
//     props<{ list: List }>());
// export const loadMoreFail = createAction(
//       '[ListStore] Load More Lists Fail',
//       props<{ error: any; lists: List[] }>());

// /**
//  * TBD how to use this and if it is needed
//  */
// export const filterList = createAction(
//     '[ListStore] Filter List',
//     props<{ id: string, filter: any }>());

// export const resetListItems = createAction(
//     '[ListStore] Reset List Items',
//     props<{ id: string; }>());

/**
 * https://ngrx.io/guide/entity/adapter#adapter-collection-methods
 */
export const addList = createAction('[ListStore] Add List', props<{ list: List }>());
// export const setList = createAction('[ListStore] Set List', props<{ list: List }>());
export const upsertList = createAction('[ListStore] Upsert List', props<{ list: List }>());
export const addLists = createAction('[ListStore] Add Lists', props<{ lists: List[] }>());
export const upsertLists = createAction('[ListStore] Upsert Lists', props<{ lists: List[] }>());
export const updateList = createAction('[ListStore] Update List', props<{ update: Update<List> }>());
export const updateLists = createAction('[ListStore] Update Lists', props<{ updates: Update<List>[] }>());
// export const mapList = createAction('[ListStore] Map List', props<{ entityMap: EntityMapOne<User> }>());
export const mapLists = createAction('[ListStore] Map Lists', props<{ entityMap: EntityMap<List> }>());
export const deleteList = createAction('[ListStore] Delete List', props<{ id: string }>());
export const deleteLists = createAction('[ListStore] Delete Lists', props<{ ids: string[] }>());
export const deleteListsByPredicate = createAction(
  '[ListStore] Delete Lists By Predicate',
  props<{ predicate: Predicate<List> }>()
);
export const clearLists = createAction('[ListStore] Clear Lists');

// do not export return value
const actions = union({
  addList,
  upsertList,
  addLists,
  upsertLists,
  updateList,
  updateLists,
  mapLists,
  deleteList,
  deleteLists,
  deleteListsByPredicate,
  clearLists,

  // loadList,
  // loadLists,
  // // loadListsByProject,
  // loadSuccess,
  // loadListsSuccess,
  // loadFail,

  // loadMore,
  // loadMoreSuccess,
  // loadMoreFail,

  // resetListItems,
  // filterList,
});

// export only type
export type ActionsUnion = typeof actions;
