<ion-list lines="none">
  <ion-radio-group 
    [value]="control?.value?.toString() ?? ''"
    (ionChange)="updateProp($any($event).detail.value)"
  >
    <ion-list-header *ngIf="header">
      <ion-label class="form-radio__title form-radio__header">
        {{ header }} &dash; 
        {{ values && values[control.value] && values[control.value].title }}
      </ion-label>
    </ion-list-header>

    <ion-item *ngFor="let record of values | keyvalue:keepOriginalOrder" class="ion-text-wrap">
      <ion-radio labelPlacement="end" justify="start" [value]="record.key" [attr.aria-label]="record.value.title">
        <div class="form-radio__title">{{ record.value.title }}</div>
        <div class="form-radio__text ion-text-wrap">{{ record.value.description }}</div>
      </ion-radio>
    </ion-item>

  </ion-radio-group>
</ion-list>
