/** @format */

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest } from 'rxjs';
import { tap, take, filter, distinctUntilChanged, map } from 'rxjs/operators';
import { ProjectCrewApiService } from '@app/core/api/project-crew-api.service';
import { AnalyticsService } from '@app/core/services/analytics/analytics.service';
import { UserService } from '@app/core/services/user.service';
import { SentryService } from '@app/core/services/sentry.service';
import { User } from '@app/shared/models/user.model';
import { getIsMemberRole } from '@app/projects/shared/project.model';
import { ProjectMember, PROJECT_MEMBER_ROLE } from '@app/project-members/shared/project-member.model';

import { Store } from '@ngrx/store';
import { State } from '@store/reducers';
import * as MemberActions from '@store/actions/members.actions';
import * as ProjectActions from '@store/actions/projects.actions';
import {
  selectCurrentMember,
  selectCurrentMemberId,
  getMembersInProjectIds,
  selectMemberQueryResults,
  selectMember,
} from '@store/selectors/members.selectors';
import { selectProject } from '@store/selectors/projects.selectors';

const DEBUG_LOGS = false;
// const HYDRATE_DELAY = 800; //ms
const PAGE = '[ProjectMemberService]'; // eslint-disable-line  @typescript-eslint/no-unused-vars

@Injectable({
  providedIn: 'root',
})
export class ProjectMemberService {
  /*
     ngrx Store connections
   */
  public selectedId$: Observable<string> = this.store.select(selectCurrentMemberId);
  public selected$: Observable<User> = this.store.select(selectCurrentMember);

  constructor(
    private userService: UserService,
    private store: Store<State>,
    private projectMemberApi: ProjectCrewApiService,
    private sentryService: SentryService,
    private analyticsService: AnalyticsService,
    private translate: TranslateService
  ) {}

  /**
   * Set UserId as Selected, and Load UserData if not already Done
   */
  selectUserId(userId: string) {
    this.store.dispatch(MemberActions.selectMember({ userId }));
  }

  /**
   * used in Get Members for all Projects (Studio)
   * @todo should be simplified..
   * create member.selector
   */
  selectProjectMembersByProjectIds(ids: string[]): Observable<User[]> {
    return this.store.select(getMembersInProjectIds({ ids }));
  }

  resetQueryMembers(): void {
    this.store.dispatch(MemberActions.queryMembers({ query: '' }));
  }
  queryMembers(query: string): void {
    DEBUG_LOGS && console.log(`queryMembers`, query);
    this.store.dispatch(MemberActions.queryMembers({ query }));
  }
  selectQueryMemberResults(): Observable<User[]> {
    return this.store.select(selectMemberQueryResults);
  }
  addToQueryMemberResults(users: User[] | ProjectMember[]) {
    DEBUG_LOGS && console.log(`addToQueryMemberResults`, users);
    this.store.dispatch(MemberActions.addMembersToQueryResults({ users }));
  }

  /**
   * Ensure the MemberStore has the user, load if not in MembersEffects
   * @param userId {string}
   * @returns Observable<User>
   */
  getPublicUserInfo(userId: string): Observable<User> {
    DEBUG_LOGS && console.log(`${PAGE} getPublicUserInfo userId: '${userId}'`);
    /** MVP-970 ngrx deprecated selectorWithProps */
    return this.store.select(selectMember(userId)).pipe(
      tap((user) => {
        if (!user || !user.userId || !user.loaded) {
          DEBUG_LOGS && console.warn(`${PAGE} TODO getPublicUserInfo LOAD! '${userId}'`);
          // this.store.dispatch(MemberActions.loadMember({ userId }));
        }
      }),
      filter((user) => user && user.userId === userId),
      distinctUntilChanged(
        (p: User, q: User) => p.userId === q.userId && p.name === q.name && p.identityId === q.identityId
      )
    );
  }

  getUserAvatar(userId: string): string {
    return this.userService.getUserAvatarUrl(userId);
    // return this.getPublicUserInfo(userId).pipe(
    //   map(user => user.avatar || defaultIfNone),
    //   // switchMap(user => {
    //   //   if (user && user.avatar && user.avatar !== defaultIfNone) {
    //   //     return of(user.avatar);
    //   //   } else if (user && user.identityId) {
    //   //     return from(this.userService.getUserAvatar(user.identityId, defaultIfNone)).pipe(
    //   //       take(1),
    //   //       map(avatar => {
    //   //         if (avatar !== defaultIfNone) {
    //   //           DEBUG_LOGS && console.log(`${PAGE} getUserAvatar UPDATE avatar`, { userId, avatar });
    //   //           this.store.dispatch(MemberActions.updateMemberAvatar({ userId, avatar }));
    //   //         }
    //   //         return avatar;
    //   //       }),
    //   //     );
    //   //   }
    //   //   DEBUG_LOGS && console.log(`${PAGE} getUserAvatar (NO AVATAR)`, { userId, avatar: user.avatar, user});
    //   //   return of(defaultIfNone);
    //   // })
    // );
  }

  getPublicUsername(userId: string): Observable<string> {
    /** MVP-970 ngrx deprecated selectorWithProps */
    return this.store.select(selectMember(userId)).pipe(
      tap((user) => {
        if (!user || !user.userId || !user.name) {
          DEBUG_LOGS && console.log(`${PAGE} getPublicUsername LOAD! '${userId}'`);
          // this.store.dispatch(MemberActions.loadMember({ userId }));
        }
      }),
      filter((user) => user && user.userId === userId),
      distinctUntilChanged((p: User, q: User) => p.userId === q.userId && p.name === q.name),
      map((user) => (user.name ? user.name : userId))
    );
    // return this.getPublicUserInfo(userId).pipe(
    //   map(user => (user.name) ? user.name : userId)
    // );
  }

  /**
   * update store
   * 2022-07-14 jd changed from addMember to updateMember
   */
  updateProjectMember(projectMember: ProjectMember): void {
    this.store.dispatch(ProjectActions.updateMember({ member: projectMember }));
    // this.store.dispatch(ProjectActions.addMember({ member: projectMember }));

    // project.effects.addProjectMemberToDb$ will handle DB, MemberActions.addToProject
    // return this.projectMemberApi.updateProjectCrewMember(projectMember).pipe(
    //   tap((res) => {
    //     const user = this.convertMemberToEntity(projectMember);
    //     this.store.dispatch(MemberActions.addToProject({ user, projectId: projectMember.projectId }));
    //     this.store.dispatch(ProjectActions.addMember({ member: projectMember }));
    //     // this.store.dispatch(MemberActions.loadSuccess({ user }));
    //   })
    // );
  }

  /**
   * Take an array of ProjectMembers and update them in store with api Effect
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addNewMembers(members: ProjectMember[]) {
    console.warn(`addMembers Not implemented`);
  }

  addMemberToProject(member: ProjectMember) {
    this.store.dispatch(ProjectActions.addMember({ member }));
  }

  /**
   * @todo do not send the entity, just the member
   */
  addToProject(member, projectId) {
    const user = this.convertMemberToEntity(member);
    this.store.dispatch(MemberActions.addToProject({ user, projectId }));
  }

  /**
   * @todo refactor this to Effect (see other usages of addNewMemberToDb)
   * addMemberToProject(member: ProjectMember) {
   * ProjectActions.addMember
   * Projects.addMine
   *
   * Assuming the token is valid & not expired
   */
  applyTokenRoleForCurrentUser(
    projectId: string,
    role: PROJECT_MEMBER_ROLE
  ): Promise<ProjectMember | { message: string }> {
    return new Promise((resolve, reject) => {
      combineLatest([this.userService.userId$, this.userService.username$, this.store.select(selectProject(projectId))])
        .pipe(take(1))
        .subscribe(([userId, username, project]) => {
          // check if this user is already a proj member
          const hasCurrentRole = getIsMemberRole(userId, project);
          if (hasCurrentRole) {
            DEBUG_LOGS && console.log('applyTokenRoleForCurrentUser already a member', { hasCurrentRole, project });
            return resolve({ message: this.translate.instant('TOKENS.ALREADY_MEMBER') });
          }

          if (projectId && role) {
            const member: ProjectMember = {
              isActive: true,
              role,
              projectId,
              userId,
              username,
            };
            /**
             * here we know this will be in MINE Projects once done here
             * sent to Effect with action instead of direct call to API
             */
            this.store.dispatch(ProjectActions.addMember({ member }));
            this.analyticsService.crewInviteAccepted(projectId);

            resolve(member);
          } else {
            reject(`Missing params?`);
          }
        });
    });
  }

  private convertMemberToEntity(member: ProjectMember) {
    return {
      userId: member.userId,
      name: member.username,
      avatar: member.avatar,
      memberProjects: [member],
    };
  }

  /*
   * untested - needed?
   */
  // getMembersForProject(projectId: string) {
  //   return this.store.select(fromStore.selectCurrentMember);
  // }

  private addMember(member: ProjectMember) {
    const user = this.convertMemberToEntity(member);
    this.store.dispatch(MemberActions.addMember({ user }));
  }

  private updateMember(member: ProjectMember) {
    const user = this.convertMemberToEntity(member);
    this.store.dispatch(MemberActions.loadSuccess({ user }));
  }
}
