/** @format */
import _pickBy from 'lodash/pickBy';
import { Action, createReducer, on } from '@ngrx/store';
import * as UserActions from '../actions/user.actions';
import * as StackActions from '@store/actions/stacks.actions';
import * as ClipActions from '@store/actions/clips.actions';
import { DEFAULT_USER_AVATAR } from '@app/shared/models/user.model';
import { ProjectMember } from '@app/project-members/shared/project-member.model';
import { SubscriptionLevel, SubscriptionStatus } from '@app/billing/shared/billing.model';
import { CognitoUserInterface } from '@app/core/api/api-types';

const DEBUG_LOGS = false;

export const featureKey = 'user';

export interface State {
  loggedIn: boolean;
  // cognito:username
  userId: string;
  // public name (first last)
  name?: string; // handle | name
  // handle (preferred_username)
  username?: string;
  prior_username?: string; // for verifying the cognito save
  email?: string;
  //  Amazon Cognito Identity - for federating logins from multiple locations
  identityId?: string;
  cognitoUser?: CognitoUserInterface;
  // string url to s3 bucket containing image
  avatar?: string;
  //  region / state
  location?: string;
  country?: string;
  bio?: string;

  authState?: string;
  groups?: Array<string>;
  //  array of projects you are an owner - or, we can query project index
  // projects?: Project[];
  projectsIds?: string[];
  // projectCrews?: ProjectMember[];
  memberProjects?: ProjectMember[];

  subscriptionId?: string;
  subscriptionLevel?: SubscriptionLevel;
  subscriptionStatus?: SubscriptionStatus;
  subscriptionMinutes?: number;

  //  array of stacks & clips
  // bookmarks?: Array<string>;

  //  stats
  numClipsWatched?: number;
  numClipsAddedToStack?: number;
  numStacksPublished?: number;
  numStacksWatched?: number;
  numVotes?: number;
  numShares?: number;
  votes?: Array<string>; // ids of the items voted on
  likes?: Array<string>; // ids of the items liked

  //  json of environ data for your user - don't include history here
  // environment?: string;
  interactedWithDom?: boolean;
}

export const initialState: State = {
  loggedIn: false,
  userId: null, // initialState is null, but logout & reset set to ''
  name: '',
  username: '',
  email: '',
  identityId: '',
  cognitoUser: null,
  avatar: DEFAULT_USER_AVATAR,
  location: '',
  country: '',
  bio: '',
  groups: [],
  // projects: [],
  projectsIds: [],
  memberProjects: [],
  // projectCrews: [],
  // bookmarks: [],
  subscriptionId: '',
  subscriptionLevel: SubscriptionLevel.Unsubscribed,
  subscriptionStatus: SubscriptionStatus.InActive,
  subscriptionMinutes: 0,

  numClipsWatched: 0,
  numClipsAddedToStack: 0,
  numStacksPublished: 0,
  numStacksWatched: 0,
  numVotes: 0,
  numShares: 0,
  votes: [],
  likes: [],
  interactedWithDom: false,
};

/**
 * remove null or undefined vals
 * and propsToRemove
 */
// eslint-disable-next-line @typescript-eslint/ban-types
const removeProps = (obj: object) => {
  const propsToRemove = ['projects', 'memberProjects'];

  return _pickBy(
    obj,
    (value, key) =>
      // not (falsy and not boolean) or undefined && not in propsToRemove
      !((!value && typeof value !== 'boolean') || value === undefined) && propsToRemove.indexOf(key) < 0
  );
};

const userReducer = createReducer(
  initialState,
  on(UserActions.reset, () => ({ ...initialState, userId: '' })),
  on(UserActions.logout, () => ({ ...initialState, userId: '' })),

  on(UserActions.loginSuccess, (state, { user }) => ({
    ...state,
    ...removeProps(user), // remove null or undefined vals
    loggedIn: true,
  })),

  on(UserActions.updateUserData, (state, { user }) => ({
    ...state,
    ...removeProps(user),
  })),
  on(UserActions.setAuthState, (state, { authState }) => ({ ...state, authState })),

  on(UserActions.setAvatar, (state, { avatar, tempAvatar }) => ({ ...state, avatar: tempAvatar || avatar })),
  on(UserActions.setName, (state, { name }) => ({ ...state, name })),
  on(UserActions.setUsername, (state, { username }) => ({ ...state, prior_username: state.username, username })),
  on(UserActions.setUsernameFail, (state) => ({ ...state, username: state.prior_username, prior_username: null })),
  on(UserActions.setEmail, (state, { email }) => ({ ...state, email })),
  on(UserActions.setLocation, (state, { location }) => ({ ...state, location })),
  on(UserActions.setCountry, (state, { country }) => ({ ...state, country })),
  on(UserActions.setBio, (state, { bio }) => ({ ...state, bio })),

  on(ClipActions.play, (state) => ({ ...state, numClipsWatched: state.numClipsWatched + 1 })),
  on(UserActions.clipAddedToStack, (state) => ({ ...state, numClipsAddedToStack: state.numClipsAddedToStack + 1 })),
  on(UserActions.stackPublished, (state) => ({ ...state, numStacksPublished: state.numStacksPublished + 1 })),
  on(StackActions.watchComplete, (state) => ({ ...state, numStacksWatched: state.numStacksWatched + 1 })),

  on(UserActions.interactWithDom, (state) => ({ ...state, interactedWithDom: true })),

  on(UserActions.subUpdate, (state, { user }) => {
    if (user && user.userId && state.userId === user.userId) {
      // this is an item we are tracking
      const newState: Partial<State> = {};
      for (const prop in user) {
        if (
          user.hasOwnProperty(prop) &&
          (user[prop] || typeof user[prop] === 'boolean' || typeof user[prop] === 'number')
        ) {
          const val = user[prop];
          if (state[prop] !== val) {
            newState[prop] = val;
            console.log(`[subUpdate] changed [${prop}]`, val);
          }
        }
      }
      DEBUG_LOGS && console.log(`[UserStore] subUpdate:`, { newState });
      return {
        ...state,
        ...newState,
      };
    } else {
      // ignoring users not us - handle them in members.reducers
      return state;
    }
  })
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}

/** 
 * moved to selectors/user.selector.ts
 * @note MVP-867-upgrade-ionic-angular changed <AppState> to <State> 
 */
// export const selectState = createFeatureSelector<State>(featureKey);
