import { Routes } from '@angular/router';
import { ProjectAdminPageGuard } from '@app/projects/project-admin/project-admin.guard';

const TABS_ROUTE = 'stack';
export const HOME_PAGE = 'home';
export const HELP_ROUTE = 'help';
export const LOGIN_PAGE = 'login';
export const SIGNUP_PAGE = 'signup';
export const CONFIRM_AUTH_PAGE = 'confirm-account';
export const FORGOT_PASS_PAGE = 'reset-password';
export const FORGOT_PASS_STEP2_PAGE = 'reset-password';
export const WATCH_ROUTE = TABS_ROUTE + '/play';
export const EDIT_ROUTE = TABS_ROUTE + '/edit';
export const EDIT_CLIP_ROUTE = TABS_ROUTE + '/clip';
export const PROJECT_DETAIL_ROUTE = TABS_ROUTE + '/discover/project';
export const COMMUNITY_ROUTE = TABS_ROUTE + '/community';
export const DISCOVER_PAGE = TABS_ROUTE + '/discover';
export const STUDIO_PAGE = TABS_ROUTE + '/studio';
export const STUDIO_SETTINGS_ROUTE = TABS_ROUTE + '/studio/settings';

export const DEFAULT_BACK_ROUTE = HOME_PAGE;
export const NAV_PAGE_ON_LOGIN = STUDIO_PAGE;
/** if the route is home page on login, change to this: */
export const HOME_PAGE_ON_LOGIN_EFFECT = STUDIO_PAGE;

export const ROOT_EDIT_URL = `/${EDIT_ROUTE}`;

export const APP_ROUTES: Routes = [
  // Ex: Lazy Loading another Routing Config
  // (use this for tabs-main and pro and teams)
  // {
  //     path: 'flight-booking',
  //     loadChildren: () =>
  //         import('./booking/flight-booking.routes')
  //             .then(m => m.FLIGHT_BOOKING_ROUTES)
  // },

  /**
   * If we want to show the tabs on the home page - uncomment next line
   */
  // { path: 'home', redirectTo: '/stack/home', pathMatch: 'full' },

  // hijack home for landing
  // { path: 'home', redirectTo: '/landing', pathMatch: 'full' },
  // { path: 'stack/home', redirectTo: '/landing', pathMatch: 'full' },
  
  // note that we lose the queryparams in the redirectTo (why?) so we added the invite as route 
  // events/accept/projectId/inviteCode
  { 
    path: 'events/live/wedding-mba-2024', 
    // redirectTo: 'events/weddings/accept/wedding-mba_20241008', 
    redirectTo: 'events/weddings/accept/wedding-mba_20241008/vegas2024mba', 
    pathMatch: 'prefix'
  },
  // ex: Directly Lazy Loading a Standalone Component
  {
    path: HOME_PAGE,
    loadComponent: () => 
        import('./pages/home/home.page')
            .then(m => m.HomePage)
  },
  {
    path: 'landing',
    loadComponent: () => 
        import('./pages/landing/landing.page')
            .then(m => m.LandingPage)
  },
  /* 
    TABS: /stack/ 
  */
  {
    path: TABS_ROUTE,
    loadChildren: () =>
        import('./tabs.routes')
            .then(m => m.TABS_ROUTES)
  },
  { path: 'capture', redirectTo: `/${TABS_ROUTE}/capture`, pathMatch: 'full' },
  { path: 'clip', redirectTo: `/${TABS_ROUTE}/clip`, pathMatch: 'prefix' },
  { path: 'theater', redirectTo: `/${TABS_ROUTE}/play`, pathMatch: 'full' },
  { path: 'dashboard', redirectTo: `/${TABS_ROUTE}/studio`, pathMatch: 'full' },
  { path: 'studio', redirectTo: `/${TABS_ROUTE}/studio`, pathMatch: 'full' },
  { path: 'studio/edit', redirectTo: `/${TABS_ROUTE}/studio/settings`, pathMatch: 'full' },
  { path: 'studio/settings', redirectTo: `/${TABS_ROUTE}/studio/settings`, pathMatch: 'full' },
  { path: 'discover', redirectTo: `/${TABS_ROUTE}/discover`, pathMatch: 'full' },

  { path: 'projects', redirectTo: `/${TABS_ROUTE}/discover/projects`, pathMatch: 'full' },
  { path: 'project/:id', redirectTo: `/${TABS_ROUTE}/discover/project/:id`, pathMatch: 'full' },

  {
    path: 'project/:id/edit',
    loadComponent: () => 
        import('./projects/project-admin/project-admin.page')
            .then(m => m.ProjectAdminPage),
    canActivate: [ProjectAdminPageGuard]
  },

  {
    path: LOGIN_PAGE,
    loadComponent: () => 
        import('./pages/auth/login/login.page')
            .then(m => m.LoginPage)
  },
  {
    path: SIGNUP_PAGE,
    children: [
      {
        path: '',
        loadComponent: () => 
            import('./pages/auth/signup/signup.page')
                .then(m => m.SignupPage)
      },
      {
        path: ':id',
        loadComponent: () => 
            import('./pages/auth/signup/signup.page')
                .then(m => m.SignupPage)
      },
    ],
  },
  {
    path: CONFIRM_AUTH_PAGE,
    loadComponent: () => 
        import('./pages/auth/confirm-account/confirm-account.page')
          .then(m => m.ConfirmAccountPage)
  },
  { path: 'confirm', redirectTo: `/${CONFIRM_AUTH_PAGE}`, pathMatch: 'full' },
  {
    path: FORGOT_PASS_PAGE,
    loadComponent: () => 
        import('./pages/auth/reset-password/reset-password.page')
            .then(m => m.ResetPasswordPage)
  },
  {
    path: `${FORGOT_PASS_PAGE}/:email`,
    loadComponent: () => 
        import('./pages/auth/reset-password/set-password/set-password.page')
            .then(m => m.SetPasswordPage)
  },
  // handle auth redirects
  { path: 'forgot-password', redirectTo: `/${FORGOT_PASS_PAGE}`, pathMatch: 'full' },
  { path: 'forgot/:email', redirectTo: `/${FORGOT_PASS_PAGE}/:email`, pathMatch: 'full' },

  {
    path: 'about',
    loadComponent: () => 
        import('./pages/about/about.page')
            .then(m => m.AboutPage)
  },
  {
    path: HELP_ROUTE,
    loadComponent: () => 
        import('./pages/help/help.page')
            .then(m => m.HelpPage)
  },
  {
    path: 'contact',
    loadComponent: () => 
        import('./pages/contact/contact.page')
            .then(m => m.ContactPage)
  },
  {
    path: 'privacy',
    loadComponent: () => 
        import('./pages/privacy/privacy.page')
            .then(m => m.PrivacyPage)
  },
  {
    path: 'community-rules',
    loadComponent: () => 
        import('./pages/community-rules/community-rules.page')
            .then(m => m.CommunityRulesPage)
  },
  {
    path: 'terms',
    loadComponent: () => 
        import('./pages/terms/terms.page')
            .then(m => m.TermsPage)
  },
  {
    path: 'social',
    loadComponent: () => 
        import('./pages/social/social.page')
            .then(m => m.SocialPage)
  },
  {
    path: 'community',
    children: [
      {
        path: '',
        loadComponent: () => 
          import('./pages/community/community.page')
              .then(m => m.CommunityPage)
      },
      // handle the route within the component
      {
        path: '**',
        loadComponent: () => 
          import('./pages/community/community.page')
              .then(m => m.CommunityPage)
      }
    ],
  },
  {
    path: 'tour',
    loadComponent: () => 
        import('./pages/widget-splash/widget-splash.page')
            .then(m => m.WidgetSplashPage)
  },
  { path: 'widget-tour', redirectTo: '/tour', pathMatch: 'full' },
  { path: 'splash', redirectTo: '/tour', pathMatch: 'full' },

  /* 
    Onboarding: Pro & Events 
  */
  {
    path: 'pro',
    loadChildren: () => 
        import('./onboarding/pro.routes')
            .then((m) => m.PRO_ROUTES),
  },
  {
    path: 'events',
    loadChildren: () => 
        import('./onboarding/events.routes')
            .then((m) => m.EVENTS_ROUTES),
  },
  { path: 'event', redirectTo: 'events', pathMatch: 'prefix' },
  { path: 'team', redirectTo: 'events', pathMatch: 'prefix' },
  { path: 'teams', redirectTo: 'events', pathMatch: 'prefix' },
  { path: 'wedding', redirectTo: 'events/weddings', pathMatch: 'prefix' },
  { path: 'weddings', redirectTo: 'events/weddings', pathMatch: 'prefix' },
  { path: 'onboarding', redirectTo: '/events/signup', pathMatch: 'full' },

  // Root path
  { path: '', redirectTo: DEFAULT_BACK_ROUTE, pathMatch: 'full' },
  // { path: '', redirectTo: '/landing', pathMatch: 'full' },

  // Fallback when no prior routes are matched
  { path: '**', redirectTo: DEFAULT_BACK_ROUTE, pathMatch: 'full' },
];
