/** @format */

import { createAction, props, union } from '@ngrx/store';
import { Update, EntityMap, Predicate } from '@ngrx/entity';
import { User } from '@app/shared/models/user.model';
import { ProjectMember } from '@app/projects/shared/project.model';

/**
 * Community Members Actions
 * 
 * LoadMemberDetails (+API)
 * AddMemberToProject (+API)
 * InviteEmailToProject (+API)
 * ApplyAsMember (+API)
 * 
 * onProjectActions.projectLoadSuccess => AddMembersFromProject -> map to entity
 * onStackActions.loadSuccess => AddMemberFromStack(s)
 
 * Not inplemented yet:
 * SearchMembers (+API)
 * onWatchLaterActions.add => AddMemberFromStack
 * 
 */

export const subUpdate = createAction(
  '[MemberStore] Subscription Update',
  props<{ memberProject: ProjectMember; userId?: string }>()
);

export const loadMember = createAction('[MemberStore] Load Member', props<{ userId: string }>());
export const loadMembers = createAction('[MemberStore] Load Members', props<{ userIds: string[] }>());

export const loadSuccess = createAction('[MemberStore] Load Member Success', props<{ user: User }>());
export const loadMembersSuccess = createAction('[MemberStore] Load Members Success', props<{ users: User[] }>());
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loadFail = createAction('[MemberStore] Load Members Fail', props<{ error: any; users: User[] }>());

export const addToProject = createAction(
  '[MemberStore] Add Member To Project',
  props<{ projectId: string; user: User }>()
);

export const inviteEmailToProject = createAction(
  '[MemberStore] Invite Email To Project',
  props<{ projectId: string; email: string }>()
);

export const applyAsMember = createAction(
  '[MemberStore] Apply As Member',
  props<{ projectId: string; userId: string }>()
);

export const updateMemberAvatar = createAction(
  '[MemberStore] Update Member Avatar',
  props<{ userId: string; avatar: string }>()
);

/**
 * https://ngrx.io/guide/entity/adapter#adapter-collection-methods
 */
export const addMember = createAction('[MemberStore] Add Member', props<{ user: User }>());
// export const setMember = createAction('[MemberStore] Set Member', props<{ user: User }>());
export const upsertMember = createAction('[MemberStore] Upsert Member', props<{ user: User }>());
export const addMembers = createAction('[MemberStore] Add Members', props<{ users: User[] }>());
export const upsertMembers = createAction('[MemberStore] Upsert Members', props<{ users: User[] }>());
export const updateMember = createAction('[MemberStore] Update Member', props<{ update: Update<User> }>());
export const updateMembers = createAction('[MemberStore] Update Members', props<{ updates: Update<User>[] }>());
// export const mapMember = createAction('[MemberStore] Map Member', props<{ entityMap: EntityMapOne<User> }>());
export const mapMembers = createAction('[MemberStore] Map Members', props<{ entityMap: EntityMap<User> }>());
export const deleteMember = createAction('[MemberStore] Delete Member', props<{ id: string }>());
export const deleteMembers = createAction('[MemberStore] Delete Members', props<{ ids: string[] }>());
export const deleteMembersByPredicate = createAction(
  '[MemberStore] Delete Members By Predicate',
  props<{ predicate: Predicate<User> }>()
);
export const clearMembers = createAction('[MemberStore] Clear Members');

export const selectMember = createAction('[MemberStore] Select MemberId', props<{ userId: string }>());
export const queryMembers = createAction('[MemberStore] Query Members', props<{ query: string }>());
export const addMembersToQueryResults = createAction(
  '[MemberStore] Add Members to Query Results',
  props<{ users: User[] }>()
);
export const queryMembersSuccess = createAction('[MemberStore] Query Members Success', props<{ users: User[] }>());

// do not export return value
const actions = union({
  addMember,
  upsertMember,
  addMembers,
  upsertMembers,
  updateMember,
  updateMembers,
  mapMembers,
  deleteMember,
  deleteMembers,
  deleteMembersByPredicate,
  clearMembers,
  selectMember,

  loadMember,
  loadMembers,
  // loadMembersByProject,
  loadSuccess,
  loadMembersSuccess,
  loadFail,

  addToProject,
  inviteEmailToProject,
  applyAsMember,
  queryMembers,
  queryMembersSuccess,
  addMembersToQueryResults,
  updateMemberAvatar,
  subUpdate,
});

// export only type
export type ActionsUnion = typeof actions;
