import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Pipe({
    name: 'formControlCast',
    standalone: true
})
export class FormControlCastPipe implements PipeTransform {

  transform(formControl: AbstractControl): FormControl<typeof formControl['value']> {
    return formControl as FormControl<typeof formControl['value']>;
  }
}
