/** @format */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { PopoverController, IonList, IonItem, IonIcon, IonText } from '@ionic/angular/standalone';

export interface PopoverAction {
  icon: string;
  text: string;
  tooltip?: string;
  type: string; // used for click handler
  disabled?: boolean; // default: false
  color?: string; // e.g. delete
}

@Component({
  selector: 'app-action-popover',
  templateUrl: './action-popover.component.html',
  styleUrls: ['./action-popover.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, IonList, IonItem, IonIcon, IonText],
})
export class ActionPopoverComponent {
  @Input() actions: PopoverAction[];
  @Input() dismissOnEmit = false;
  /** note that media breakpoint will hide it too */
  @Input() showText = true;
  @Output() selected = new EventEmitter<string>();

  constructor(private popoverCtrl: PopoverController) {}

  onClick(actionType) {
    this.selected.emit(actionType);
    if (this.dismissOnEmit) {
      this.popoverCtrl.dismiss({ selected: actionType });
    }
  }
}
