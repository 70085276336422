/** @format */

import { Injectable } from '@angular/core';
import { API, graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import { Observable, from, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '@app/shared/models/user.model';
// import { Project } from '@app/projects/shared/project.model';
import { ALL_USER_DATA_FIELDS, PUBLIC_USER_DATA_FIELDS, CURRENT_USER_DATA_FIELDS } from './api.models';
import { UpdateParam, UpdateParamInt } from './api-types';
import { ALL_PROJECT_FIELDS, ALL_PROJECT_MEMBER_FIELDS } from './api.models';
import { SentryService } from '@app/core/services/sentry.service';
import { updateProjectMembers } from './projects-api.service';
import { environment } from 'src/environments/environment';
import { Project } from '@app/projects/shared/project.model';

export const COGNITO_GROUP_ADMIN = 'Admins';
export const COGNITO_GROUP_BETA_TESTERS = 'Beta';
export const COGNITO_GROUP_DEVELOPERS = 'Developers';
export const COGNITO_GROUP_KICKSTARTER = 'Kickstarter';
export const COGNITO_GROUP_WEDDING_PILOT = 'WeddingPilot';

const DEBUG_LOGS = false;
/** how many do we need before query requires a nextToken? 1000 is the max.. */
const MEMBER_PROJECT_LIMIT = 500;

const PAGE = '[UsersApiService]';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  /**
   * The AppSync types for the update command
   */
  types = {
    userId: 'ID!',
    // Amazon Cognito Identity - for federating logins from multiple locations
    identityId: 'String',
    // public name, first last
    name: 'String',
    // string (handle) (preferred_username)
    username: 'String',
    email: 'String',
    // app version used
    version: 'String',
    // <type>_uuid (types= fb_, up_userpool, g_goog)
    userIdType: 'String',
    groups: '[String!]',
    // string url to s3 bucket containing image
    avatar: 'String',
    // region/state
    location: 'String',
    country: 'String',
    bio: 'String',
    // array of stacks & clips marked as watch later
    watchLater: '[String!]',
    recentProjects: '[String!]',
    // stats
    numClipsWatched: 'Int',
    numClipsAddedToStack: 'Int',
    numStacksPublished: 'Int',
    numStacksWatched: 'Int',
    numVotes: 'Int',
    numShares: 'Int',
    votes: '[String!]',
    likes: '[String!]',
    historyClips: '[OrderedClipInput]',
    subscriptionId: 'String',
    subscriptionLevel: 'String,',
    subscriptionStatus: 'Int,',
    subscriptionMinutes: 'Int',
    subscriptionExpires: 'String',
    subscriptionRenews: 'String',
    environment: 'AWSJSON',
    // createdAt & updatedAt: 'AWSDateTime', // updated in backend
  };

  constructor(private sentryService: SentryService) {}

  /**
   * Update User with Projects and Members
   * remove the "items" from the api response, reduce to array
   */
  convertUserProjectsMembers(user: User, currentUser = false) {
    // DEBUG_LOGS && console.log(`convertUserProjectsMembers`, user);
    if (user && user.projects) {
      // Error Checking - notify Sentry if these API calls have nextTokens...
      if (user.projects['nextToken']) {
        this.sentryService.captureMessage(
          `[UserAPI] convertUserProjectsMembers (userId: '${user.userId}') User.projects has nextToken`
        );
      }
      //extract items
      if (Array.isArray(user.projects['items'])) {
        user.projects = user.projects['items']
          .filter((proj) => currentUser || proj.privacy === 'PUBLIC') // @todo: VERIFY THIS
          .map(updateProjectMembers) as Project[];
      } else {
        console.warn(`convertUserProjectsMembers !array`, user);
      }
    }
    if (user && user.memberProjects) {
      // Error Checking - notify Sentry if these API calls have nextTokens...
      if (user.memberProjects['nextToken']) {
        console.warn(`'${user.userId}' User.memberProjects has nextToken`);
        this.sentryService.captureMessage(
          `[UserAPI] convertUserProjectsMembers (userId: '${user.userId}') User.memberProjects has nextToken`
        );
      }
      //extract items
      if (Array.isArray(user.memberProjects['items'])) {
        user.memberProjects = user.memberProjects['items'].filter((proj) => currentUser || proj.isActive);
      } else {
        console.warn(`convertUserProjectsMembers !array`, user);
      }
    }
    DEBUG_LOGS && console.log(`convertUserProjectsMembers`, user);
    return user;
  }

  /**
   * Effect -> on Login Success
   * @param user
   */
  async effectUpdateOnAuth(userDb: User, userState: User): Promise<User> {
    try {
      // DEBUG_LOGS && console.log(`${PAGE} effectUpdateOnAuth sync updates with DB`, { userDb, userState });

      // build updates
      const updates: UpdateParam[] = [];

      if (environment.production) {
        if (userDb.identityId !== userState.identityId) {
          console.warn(`SENTRY: ${PAGE} getUser != identityId !!!`, userDb.identityId, userState.identityId);
          this.sentryService.captureMessage(
            `${PAGE} getUser != identityId :: db=(${userDb.identityId}) != cognito=(${userState.identityId})`
          );
        }

        if (userDb.version !== environment.version) {
          updates.push({
            prop: 'version',
            value: environment.version,
          });
        }

        // preferred_username / handle / public username
        if (userDb.name && userDb.name !== userState.username) {
          !environment.production && console.warn(`${PAGE} TODO: set preferred_username`, { userState, userDb });
        }
        /**
         * @todo verify if we need this!
         */
        // if (userState.name !== userDb.name) {
        //   updates.push({
        //     prop: 'name',
        //     value: userState.name,
        //   });
        // }

        if (userState.email !== userDb.email) {
          updates.push({
            prop: 'email',
            value: userState.email,
          });
        }

        if (JSON.stringify(userState.groups) !== JSON.stringify(userDb.groups)) {
          updates.push({
            prop: 'groups',
            value: userState.groups,
          });
        }
      } else {
        // environment.dev
        if (userDb.version !== environment.version + '[dev]') {
          updates.push({
            prop: 'version',
            value: environment.version + '[dev]',
          });
        }
      }
      userState.version = environment.version;

      if (updates.length > 0) {
        // const updatesObj = updates.reduce((obj, item) => (obj[item.prop] = item.value, obj) ,{});
        const updateRes = await this.updateUser(userDb, updates);
        DEBUG_LOGS && console.log(`${PAGE} effectUpdateOnAuth user update`, { userState, userDb, updates, updateRes });
      } else {
        DEBUG_LOGS && console.log(`${PAGE} effectUpdateOnAuth no user updates`, { userState, userDb, updates });
      }
      return Promise.resolve(userState);
    } catch (error) {
      console.warn(
        `${PAGE} effectUpdateOnAuth caught error`,
        error && Array.isArray(error.error) && error.error.length > 0 && error.error[0].message
          ? error.error[0].message
          : error
      );
      this.sentryService.captureError(error);
      return Promise.reject(error);
    }
  }

  createUser(user: User): Promise<GraphQLResult> {
    if (!user.userId) {
      return Promise.reject('No ID Provided');
      // return Observable.throw("No ID Provided");
    }
    const endpoint = 'createUser';

    const action = `mutation createUser(
      $userId: ID!
      $identityId: String
      $name: String
      $username: String
      $email: String
      $version: String
      $userIdType: String
      $groups: [String!]
    ) {
      createUser(
        userId: $userId
        identityId: $identityId
        name: $name
        username: $username
        email: $email
        version: $version
        userIdType: $userIdType
        groups: $groups
      ) {
        userId
        updatedAt
      }
    }`;

    /*
      Full API input:
      
          userId: ID!
          identityId: String
          name: String
          version: String
          email: String
          # sub: String
          userIdType: String
          groups: [String!]
          # avatar: String
          location: String
          # environment: AWSJSON
    
    */

    // Mutation
    const details: User = {
      userId: user.userId,
      identityId: user.identityId,
      version: environment.version || '[dev]',
    };
    if (user.name) {
      details.name = user.name;
    }
    if (user.email) {
      details.email = user.email;
    }
    if (user.groups && user.groups.length > 0) {
      details.groups = user.groups;
    }

    DEBUG_LOGS && console.log(`${PAGE} ${endpoint}`, { user, details });

    return (API.graphql(graphqlOperation(action, details)) as Promise<GraphQLResult>)
      .then((res) => res.data[endpoint])
      .catch((e) => {
        console.warn(`${PAGE} createUser error`, e);
        throw e;
      });
    // from(API.graphql(graphqlOperation(action, details)))
    //   .map(res => {
    //     console.log(`${PAGE} createUser res`, res);
    //     // return (res as any).data.queryProjectCrewsByUserId.items;
    //   });
  }

  updateUser(user: User, updates: UpdateParam[]): Promise<Partial<User>> {
    DEBUG_LOGS && console.log(`${PAGE} updateUser`, { user, updates });
    if (!user.userId) {
      return Promise.reject('No ID Provided');
      // throw Observable.throw("No ID Provided");
    }
    const endpoint = 'updateUser';
    const params = {
      userId: user.userId,
    };
    updates.forEach((update) => {
      if (update.value || typeof update.value === 'number' || typeof update.value === 'boolean') {
        // it could be number == zero
        // dynamically create the update params for string query
        params[update.prop] = update.value;
      }
    });

    let definition = '',
      mapping = '';
    Object.keys(params).forEach((key: string) => {
      if (this.types[key]) {
        definition += `$${key}: ${this.types[key]} `;
        mapping += `${key}: $${key} `;
      }
    });
    const statement = `mutation UpdateUser(${definition}) {
      updateUser(${mapping}) {
        userId
        updatedAt
      }
    }`;

    return (API.graphql(graphqlOperation(statement, params)) as Promise<GraphQLResult>)
      .then((res) => res.data[endpoint])
      .catch((e) => {
        console.warn(`${PAGE} updateUser error`, e);
        throw e;
      });
    // from(API.graphql(graphqlOperation(action, details)) as Promise<GraphQLResult>)
    //   .map(res => {
    //     console.log(`${PAGE} updateUser res`, res);
    //     // return (res as any).data.queryProjectCrewsByUserId.items;
    //   });
  }

  getUser(userId: string): Promise<User> {
    console.warn(`getUser called `);

    /**
     * @todo add historyClips
     */

    const query = `query getUser {
      getUser(userId: "${userId}") {
        ${ALL_USER_DATA_FIELDS}
      }
    }`;

    return (API.graphql(graphqlOperation(query)) as Promise<GraphQLResult>)
      .then((res) => {
        console.log(`${PAGE} getUser res:`, res);
        return res.data['getUser'];
      })
      .catch((error) => {
        console.log(`${PAGE} getUser caught:`, error);
        if (error && Array.isArray(error.errors) && error.errors.length > 0) {
          console.warn(`${PAGE} getUser error:`, error.errors[0]);
        }
        throw error;
      });
    // // Simple query
    // return from(API.graphql(graphqlOperation(query)) as Promise<GraphQLResult>)
    //   .map(res => {
    //     console.log(`${PAGE} getUser res`, res);
    //     return (res as any).data.getUser;
    //   });
  }

  /**
   * include user.projects[] and user.memberProjects: ProjectMember[]
   *
   * REFACTOR projects.effects.loadByUserId$
   * - queryProjectsByOwner
   *   - getUserProjectsCrewAndOwner
   *     - PUBLIC: projectCrewApi.getCrewForUser
   *       - projectApi.queryPublicProjectsByOwner
   *         - projectCrewApi.getCrewForUser
   *     - CURRENT_USER
   *       - projectApi.queryProjectsByOwner
   *         - projectCrewApi.getCrewForUser
   *
   * - queryProjectCrewsByUserId
   * - getPublicUserInfo
   */
  getUserWithProjects(userId: string, currentUser = false): Promise<User> {
    /**
     * @todo add historyClips
     */
    const endpoint = 'getUser';
    const currentUserFields = currentUser ? CURRENT_USER_DATA_FIELDS : '';

    const query = `query getUser {
      getUser(userId: "${userId}") {
        ${PUBLIC_USER_DATA_FIELDS}
        projects(limit: ${MEMBER_PROJECT_LIMIT}) {
          items {
            ${ALL_PROJECT_FIELDS}
          }
          nextToken
        }
        memberProjects(limit: ${MEMBER_PROJECT_LIMIT}) {
          items {
            ${ALL_PROJECT_MEMBER_FIELDS}
          }
          nextToken
        }
        ${currentUserFields}
      }
    }`;

    return (API.graphql(graphqlOperation(query)) as Promise<GraphQLResult>)
      .then((res) => {
        DEBUG_LOGS && console.log(`${PAGE} getUserWithProjects res:`, res);
        return this.convertUserProjectsMembers(res.data[endpoint], currentUser);
      })
      .catch((error) => {
        console.warn(`${PAGE} getUserWithProjects caught:`, error);
        if (error && Array.isArray(error.errors) && error.errors.length > 0) {
          console.warn(`${PAGE} getUserWithProjects error:`, error.errors[0]);
        }
        throw error;
      });
    // // Simple query
    // return from(API.graphql(graphqlOperation(query)) as Promise<GraphQLResult>)
    //   .map(res => {
    //     console.log(`${PAGE} getUser res`, res);
    //     return (res as any).data.getUser;
    //   });
  }

  getPublicUserInfo(userId: string): Promise<User> {
    const endpoint = 'getPublicUserInfo';
    const query = `query getPublicUserInfo {
      getPublicUserInfo(userId: "${userId}") {
        ${PUBLIC_USER_DATA_FIELDS}
        projects(limit: ${MEMBER_PROJECT_LIMIT}) {
          items {
            ${ALL_PROJECT_FIELDS}
          }
          nextToken
        }
        memberProjects(limit: ${MEMBER_PROJECT_LIMIT}) {
          items {
            ${ALL_PROJECT_MEMBER_FIELDS}
          }
          nextToken
        }
      }
    }`;
    //TODO: refactor to observable
    // console.log(`${PAGE} getPublicUserInfo query`, query);
    return (API.graphql(graphqlOperation(query)) as Promise<GraphQLResult>)
      .then((res) => this.convertUserProjectsMembers(res.data[endpoint]))
      .catch((error) => {
        console.log(`${PAGE} getPublicUserInfo error`, error);
        throw error;
      });
  }

  /**
   * Increment Int fields - Public
   * Will increment by 1 if field is sent as not null
   * @param user
   */
  incrementPublic(userId: string, updates: UpdateParamInt[]): Promise<Partial<User>> {
    DEBUG_LOGS && console.log(`${PAGE} incrementPublicUser`, userId, updates);
    const endpoint = 'incrementPublicUser';
    const params = {
      userId,
    };
    updates.forEach((update) => {
      if (typeof update.value === 'number') {
        // it should be a positive or negative or zero
        // dynamically create the update params for string query
        params[update.prop] = update.value;
      }
    });

    const action = `mutation incrementPublicUser(
      $userId: ID!, 
		  $numClipsWatched: Int
      $numClipsAddedToStack: Int
      $numStacksPublished: Int
      $numStacksWatched: Int
      $numVotes: Int
      $numShares: Int
    ) {
      incrementPublicUser(
        userId: $userId
        numClipsWatched: $numClipsWatched
        numClipsAddedToStack: $numClipsAddedToStack
        numStacksPublished: $numStacksPublished
        numStacksWatched: $numStacksWatched
        numVotes: $numVotes
        numShares: $numShares
      ) {
        userId
        numClipsWatched
        numClipsAddedToStack
        numStacksPublished
        numStacksWatched
        numVotes
        numShares
      }
    }`;

    return (API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} incrementPublicUser res:`, res);
      return res.data[endpoint];
    });
  }

  queryUsers(searchText: string): Observable<{ items: User[]; nextToken: string }> {
    if (!searchText || typeof searchText.toLowerCase !== 'function') {
      console.warn(`${PAGE} queryUsers no searchText!`);
      return of({ items: [], nextToken: null });
    }
    const query = `query QueryUsersNames{
      queryUsers(
        query: "${searchText.toLowerCase().trim()}"
        limit: 800
      ) {
        items {
          ${PUBLIC_USER_DATA_FIELDS}
          projects(limit: ${MEMBER_PROJECT_LIMIT}) {
            items {
              ${ALL_PROJECT_FIELDS}
            }
            nextToken
          }
          memberProjects(limit: ${MEMBER_PROJECT_LIMIT}) {
            items {
              ${ALL_PROJECT_MEMBER_FIELDS}
            }
            nextToken
          }
        }
        nextToken
      }
    }`;
    return from(API.graphql(graphqlOperation(query)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        DEBUG_LOGS && console.log(`${PAGE} queryUsers res:`, res);
        const { items, nextToken } = res.data['queryUsers'];
        return { 
          items: items.map((item) => this.convertUserProjectsMembers(item, false)),
          nextToken,
        };
        // return this.convertUserProjectsMembers((res as any).data.queryUsers, false);
      })
    );
  }
}
