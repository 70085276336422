/**
 * List Effects
 * - stackActions.subUpdate from StackEffects
 * @format
 */
/* eslint-disable @typescript-eslint/no-unused-vars */
// import _pickBy from 'lodash/pickBy';
import { Injectable } from '@angular/core';
import { of, EMPTY } from 'rxjs';
import { withLatestFrom, filter, concatMap, mergeMap, catchError, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { State } from '../reducers';
import * as listActions from '../actions/lists.actions';
import * as stackActions from '../actions/stacks.actions';
import { getCollabDraftLists } from '@store/reducers/lists.reducers';
import { selectListEntities } from '@store/selectors/lists.selectors';
import { getId, getId as getStackId, selectStackEntities } from '@store/selectors/stacks.selectors';
import { FilterEntityTypes } from '@store/reducers/viewstate.reducers';
import { getUserId } from '@store/selectors/user.selectors';
import { SentryService } from '@app/core/services/sentry.service';
import { sortFeatured, STACK_PRIVACY } from '@app/shared/models/stack.model';
import { ITEM_TYPE } from '@app/shared/models/layout.model';

const DEBUG_LOGS = false;
const PAGE = '[ListStoreEffects]';

@Injectable()
export class ListsEffects {
  /**
   * If an effect does not yield any actions back to the store. Set
   * `dispatch` to false to hint to @ngrx/effects that it should
   * ignore any elements of this effect stream.
   *
   * The `defer` observable accepts an observable factory function
   * that is called when the observable is subscribed to.
   * Wrapping the database open call in `defer` makes
   * effect easier to test.
   */

  /**
   * Do we need to do anything on subscription update?
   */
  subUpdate$ = createEffect(
    () =>
      this.stackActions$.pipe(
        ofType(stackActions.subUpdate.type),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this.store$.select(selectStackEntities)),
            withLatestFrom(this.store$.select(selectListEntities)),
            withLatestFrom(this.store$.select(getUserId))
          )
        ),
        map(([[[{ stack }, state], listState], userId]) => ({ stack, state, listState, userId })),
        filter(({ stack, state, listState, userId }) => stack?.projectId && stack?.stackId?.length > 0),
        mergeMap(({ stack, state, listState, userId }) => {
          const id = getId(stack.projectId, stack.stackId);
          const entity = state[id];
          const updates = [];
          const isPublished = stack.dtePublished || entity?.dtePublished;
          const isMine = stack.userId === userId;

          // if (stack.featured === 0) {
          //   // functionality exists in the reducer to move to recent...
          // } else
          if (stack.featured && stack.featured > 0) {
            // listsWith doesn't matter here, we should add it to any featured
            const listsToModify = Object.values(listState).filter(
              (value) =>
                value?.itemType === ITEM_TYPE.Stacks &&
                value.filters &&
                value.filters?.type === FilterEntityTypes.Featured &&
                (!value.filters.projectId || value.filters.projectId === stack.projectId)
            );

            // // v2 the index change really isn't the interesting data point here.. it's that the sort should be Featured!
            updates.push(
              ...listsToModify.map((list) => ({
                id: list.id,
                changes: {
                  // get the stack entities, sortFeatured, return updates
                  itemIds: [id, ...list.itemIds.filter((d) => d !== id)]
                    .map((d) => state[d])
                    .filter((item) => item?.projectId && item?.stackId)
                    .sort(sortFeatured)
                    .map((item) => getStackId(item.projectId, item.stackId)),
                },
              }))
            );
            DEBUG_LOGS && console.log(`[ListEffects] Update Stack.Featured...`, { updates, listsToModify, stack });
            return of(listActions.updateLists({ updates }));
          } else if (
            Object.keys(stack).length > 2 &&
            !stack.dtePublished &&
            (!entity || (entity && entity.stackId && !entity.dtePublished))
          ) {
            // checking if there's more than just stackId & projectId (Object.keys.length) - if not, likely a delete operation...
            // this might be a draft, add to any drafts lists for that project (we don't have userId here, so mystudio might not update..
            if (stack.isCollaborative) {
              const collabLists = getCollabDraftLists({
                entities: listState,
                projectId: stack.projectId,
                isCollaborative: stack.isCollaborative,
              });

              if (isMine) {
                updates.push(
                  ...collabLists.map((list) => ({
                    id: list.id,
                    changes: { itemIds: [id, ...list.itemIds.filter((itemId) => itemId && itemId !== id)] },
                  }))
                );
              }
              // console.warn({ collabLists, isMine });

              // updates.push(...collabLists.map(list => ({
              //   id: list.id,
              //   changes: { itemIds: [ id, ...list.itemIds.filter((itemId) => itemId && itemId !== id) ] },
              // })));
            } else if (stack.userId === userId) {
              // this is mine, add to draft list
              const listsToAdd = Object.values(listState).filter(
                (value) =>
                  value?.itemType === ITEM_TYPE.Stacks &&
                  value.filters &&
                  value.filters.type === FilterEntityTypes.StackDrafts &&
                  // if there's no projectId it's my studio, otherwise add to project Drafts
                  (!value.filters.projectId || value.filters.projectId === stack.projectId) &&
                  !value.filters.isCollaborative
              );
              updates.push(
                ...listsToAdd.map((list) => ({
                  id: list.id,
                  changes: { itemIds: [id, ...list.itemIds.filter((itemId) => itemId && itemId !== id)] },
                }))
              );
            }
            DEBUG_LOGS && console.log(`[ListEffects] Update My or Collab Draft...`, { updates, stack });
            return of(listActions.updateLists({ updates }));
          } else if (isPublished && stack.privacy) {
            // the privacy of a published stack may have changed
            const privLists = Object.values(listState).filter(
              (value) =>
                value?.itemType === ITEM_TYPE.Stacks &&
                value.filters &&
                value.filters?.type === FilterEntityTypes.Private &&
                (!value.filters.projectId || value.filters.projectId === stack.projectId)
            );
            if (stack.privacy === STACK_PRIVACY.PUBLIC) {
              // remove from private and add to recents (or featured if >0)
              const addToLists = Object.values(listState).filter(
                (value) =>
                  value?.itemType === ITEM_TYPE.Stacks &&
                  value.filters &&
                  value.filters?.type ===
                    (stack?.featured > 0 ? FilterEntityTypes.Featured : FilterEntityTypes.Recent) &&
                  (!value.filters.projectId || value.filters.projectId === stack.projectId)
              );
              updates.push(
                ...privLists.map((list) => ({
                  id: list.id,
                  changes: {
                    itemIds: [...list.itemIds.filter((d) => d !== id)],
                  },
                }))
              );
              updates.push(
                ...addToLists.map((list) => ({
                  id: list.id,
                  changes: {
                    itemIds: [id, ...list.itemIds.filter((d) => d !== id)],
                  },
                }))
              );
            } else {
              // was changed to private or unlisted - remove from everywhere and then add to private
              const nonPrivLists = Object.values(listState).filter(
                (value) =>
                  value?.itemType === ITEM_TYPE.Stacks &&
                  value.filters &&
                  value.filters?.type !== FilterEntityTypes.Private &&
                  (!value.filters.projectId || value.filters.projectId === stack.projectId)
              );
              updates.push(
                ...nonPrivLists.map((list) => ({
                  id: list.id,
                  changes: {
                    itemIds: [...list.itemIds.filter((d) => d !== id)],
                  },
                }))
              );
              updates.push(
                ...privLists.map((list) => ({
                  id: list.id,
                  changes: {
                    itemIds: [id, ...list.itemIds.filter((d) => d !== id)],
                  },
                }))
              );
            }
            DEBUG_LOGS && console.log({ isPublished, privacy: stack.privacy, updates });
            return of(listActions.updateLists({ updates }));
          } else {
            DEBUG_LOGS && console.log(`${PAGE} ignored subUpdate action`, { stack });
          }

          return EMPTY;
        }),
        catchError((error) => {
          this.captureError(error, 'subUpdate caught');
          this.sentryService.captureError(error);
          return EMPTY;
        })
      )
    //  { dispatch: false },
  );

  private captureError(error, msg = ''): void {
    const e = error && Array.isArray(error.errors) && error.errors.length > 0 ? error.errors[0] : error;
    console.warn(`${PAGE} ${msg} captureError`, e);
    this.sentryService.captureError(e);
  }

  //   /**
  //    * On Set Filter, find Lists that use that FilterId
  //    * add items observable via API
  //    *
  //    * @todo reset Items on every change
  //    */
  //    listSetFilters$ = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(
  //         ViewstateActions.setFilter
  //       ),
  //       concatMap(action => of(action).pipe(
  //         withLatestFrom(this.store$.pipe(select(selectListEntities))),
  //         // withLatestFrom(this.store$.pipe(select(ViewstateSelectors.selectEntities))),
  //       )),
  //       tap(([{ filter: viewStateFilter }, listEntities]) => {
  //         DEBUG_LOGS && console.log(`${PAGE} listSetFilters$ pre-filter`, { viewStateFilter, listEntities });
  //       }),
  //       // there is a filterId and lists to check
  //       filter(([{ filter: viewStateFilter }, listEntities]) => viewStateFilter && viewStateFilter.id && listEntities && Object.keys(listEntities).length > 0),
  //       // there are lists using this filterId
  //       // filter(([{ filter: viewStateFilter }, listEntities]) => (Object.values(listEntities).filter((value) => value.filters && value.filters.id && value.filters.id === viewStateFilter.id)).length > 0),
  //       tap(([{ filter: viewStateFilter }, listEntities]) => {
  //         const filterId = viewStateFilter.id;
  //         const lists = Object.values(listEntities).filter((value) => value.filters && value.filters.id && value.filters.id === filterId);
  //         console.warn(`${PAGE} listSetFilters$ post-filter lists:`, { lists, viewStateFilter, listEntities });
  //         // const { method, addItemsAction, actionItemProp } = this.getMethodBasedOnAction(list);

  //         // const entities = _pickBy(listEntities, (item) => item && item.filters && item.filters.id === id);
  //         // const items = Object.values(entities);

  //         // console.warn(`[ListsStore] ViewStateActions.resetFilter`, { id, entities, state, items });

  //         // foreach...

  //         // const entity = state.entities[id];
  //         // const filters = entity && entity.filters ? entity.filters : {};

  //         // return adapter.upsertOne(
  //         //   Object.assign(entity, {
  //         //   id,
  //         //   filters: Object.assign(filters, {
  //         //     id,
  //         //     q: '',
  //         //     tags: '',
  //         //     users: '',
  //         //   }),
  //         //   itemIds: Array.isArray(entity.defaultItemIds) && entity.defaultItemIds.length > 0 ? entity.defaultItemIds : [],
  //         //   nextToken: entity.defaultNextToken,
  //         //   defaultItemIds: [],
  //         //   defaultNextToken: '',

  //         // }), state);

  //         // todo: reset Items on every change
  //         // listActions.resetListItems(
  //       }),
  //       // distinctUntilChanged((a: any, b: any) => a.filter.id === b.filter.id && a.filter.q === b.filter.q),
  //       // map(([action, state]) => ({...state.entities[action.id], filter: action.filter })), //Get the viewstate entity from list.filter.id

  //       // map(([action, state]) => ({...state.entities[action.id], filter: action.filter })),
  //       // filter((list) => (list && list.nextToken && list.nextToken.length > 0)),
  //       // switchMap((list) => { // cancel prior call
  //       //   const { method, addItemsAction, actionItemProp } = this.getMethodBasedOnAction(list);
  //       //   return method.pipe(
  //       //     map((res) => {
  //       //       DEBUG_LOGS && console.log(`list.ApiCallBasedOnAction ${list.itemType} ${list.group} res:`, res);
  //       //       list.nextToken = res.nextToken;
  //       //       list.addItems$.next(res.items);
  //       //       if (typeof addItemsAction === 'function') {
  //       //         addItemsAction({ [actionItemProp]: res.items, group: list.group, nextToken: list.nextToken })
  //       //       }
  //       //       return list;
  //       //     }),
  //       //     map((list) => listActions.loadSuccess({ list })),
  //       //     catchError(error => of(listActions.loadMoreFail({ error, lists: [list] })))
  //       //   )
  //       // })
  //   // ));
  //  ), { dispatch: false });

  //   /**
  //    * On Set Filter, find Lists that use that FilterId
  //    * add items observable via API
  //    *
  //    * @todo reset Items on every change
  //    */
  //    listResetFilters$ = createEffect(() =>
  //     this.actions$.pipe(
  //       ofType(
  //         ViewstateActions.resetFilter
  //       ),
  //       concatMap(action => of(action).pipe(
  //         withLatestFrom(this.store$.pipe(select(selectListEntities))),
  //         // withLatestFrom(this.store$.pipe(select(ViewstateSelectors.selectEntities))),
  //       )),
  //       tap(([action, listEntities]) => {
  //         // const lists = listsState.filter(list => list && list.filter && list.filter.id == filter.id);
  //         // const { method, addItemsAction, actionItemProp } = this.getMethodBasedOnAction(list);
  //         console.warn(`${PAGE} listResetFilters$ effect`, { action, listEntities });

  //         // const entities = _pickBy(listEntities, (item) => item && item.filters && item.filters.id === id);
  //         // const items = Object.values(entities);

  //         // console.warn(`[ListsStore] ViewStateActions.resetFilter`, { id, entities, state, items });

  //         // foreach...

  //         // const entity = state.entities[id];
  //         // const filters = entity && entity.filters ? entity.filters : {};

  //         // return adapter.upsertOne(
  //         //   Object.assign(entity, {
  //         //   id,
  //         //   filters: Object.assign(filters, {
  //         //     id,
  //         //     q: '',
  //         //     tags: '',
  //         //     users: '',
  //         //   }),
  //         //   itemIds: Array.isArray(entity.defaultItemIds) && entity.defaultItemIds.length > 0 ? entity.defaultItemIds : [],
  //         //   nextToken: entity.defaultNextToken,
  //         //   defaultItemIds: [],
  //         //   defaultNextToken: '',

  //         // }), state);

  //         // todo: reset Items on every change
  //         // listActions.resetListItems(
  //       }),
  //       // distinctUntilChanged((a: any, b: any) => a.filter.id === b.filter.id && a.filter.q === b.filter.q),
  //       // map(([action, state]) => ({...state.entities[action.id], filter: action.filter })), //Get the viewstate entity from list.filter.id

  //       // map(([action, state]) => ({...state.entities[action.id], filter: action.filter })),
  //       // filter((list) => (list && list.nextToken && list.nextToken.length > 0)),
  //       // switchMap((list) => { // cancel prior call
  //       //   const { method, addItemsAction, actionItemProp } = this.getMethodBasedOnAction(list);
  //       //   return method.pipe(
  //       //     map((res) => {
  //       //       DEBUG_LOGS && console.log(`list.ApiCallBasedOnAction ${list.itemType} ${list.group} res:`, res);
  //       //       list.nextToken = res.nextToken;
  //       //       list.addItems$.next(res.items);
  //       //       if (typeof addItemsAction === 'function') {
  //       //         addItemsAction({ [actionItemProp]: res.items, group: list.group, nextToken: list.nextToken })
  //       //       }
  //       //       return list;
  //       //     }),
  //       //     map((list) => listActions.loadSuccess({ list })),
  //       //     catchError(error => of(listActions.loadMoreFail({ error, lists: [list] })))
  //       //   )
  //       // })
  //   // ));
  //  ), { dispatch: false });

  //   // /**
  //   //  * Take the List and attach the action logic
  //   //  */
  //   // getMethodBasedOnAction(list: List, filter: ViewstateSelectors.FilterEntity = null): { addItemsAction: ActionCreator, actionItemProp: string, method: Observable<any> } {
  //   //   let addItemsAction: ActionCreator,
  //   //       actionItemProp: string;
  //   //   switch (list.itemType) {
  //   //     case ITEM_TYPE.Stacks: {
  //   //       addItemsAction = stackActions.loadSuccess;
  //   //       actionItemProp = 'stacks';
  //   //       let filters: any = {};
  //   //       if (filter && filter.id) {
  //   //         filters = { ...filter };
  //   //       }
  //   //       switch (list.group) {
  //   //         case ListGroup.Recent: {

  //   //           if (list.userId) {
  //   //             return {
  //   //               method: this.stacksApi.queryStacksByUser({ userId: list.userId, limit: list.limit, nextToken: list.nextToken, filters }),
  //   //               addItemsAction,
  //   //               actionItemProp,
  //   //             };
  //   //           } else if (list.projectId) {
  //   //             return {
  //   //               method: this.stacksApi.queryRecentStacksByProject({ projectId: list.projectId, limit: list.limit, nextToken: list.nextToken, filters }),
  //   //               addItemsAction,
  //   //               actionItemProp,
  //   //             };
  //   //           } else {
  //   //             return {
  //   //               method: this.stacksApi.queryRecentStacks({ limit: list.limit, nextToken: list.nextToken, filters }),
  //   //               addItemsAction,
  //   //               actionItemProp,
  //   //             }
  //   //           }
  //   //         }
  //   //         case ListGroup.Featured: {
  //   //           if (list.projectId) {
  //   //             return {
  //   //               method: this.stacksApi.queryFeaturedStacksByProject({ projectId: list.projectId, limit: list.limit, nextToken: list.nextToken, filters }),
  //   //               addItemsAction,
  //   //               actionItemProp,
  //   //             }
  //   //           } else {
  //   //             return {
  //   //               method: this.stacksApi.queryFeaturedStacks({ limit: list.limit, nextToken: list.nextToken, filters }),
  //   //               addItemsAction,
  //   //               actionItemProp,
  //   //             }
  //   //           }
  //   //           break;
  //   //         }
  //   //         case ListGroup.All:
  //   //         default: {
  //   //           // queryStacksByProject
  //   //           console.warn(`${PAGE} getMethodBasedOnAction UNHANDLED ListGroup`, list.group);
  //   //         }
  //   //       }
  //   //       break;
  //   //     }
  //   //     case ITEM_TYPE.Projects: {
  //   //       // list.items$ = this.stacksService.getProjectStacksRecent(list.projectId);
  //   //       switch (list.group) {
  //   //         case ListGroup.Recent: {
  //   //           break;
  //   //         }
  //   //         case ListGroup.Featured: {
  //   //           break;
  //   //         }
  //   //         case ListGroup.All:
  //   //         default: {

  //   //         }
  //   //       }
  //   //       console.warn(`${PAGE} getMethodBasedOnAction UNHANDLED`, list);
  //   //       break;
  //   //     }
  //   //     case ITEM_TYPE.Clips: {
  //   //       switch (list.group) {
  //   //         case ListGroup.Recent: {
  //   //           break;
  //   //         }
  //   //         case ListGroup.Featured: {
  //   //           break;
  //   //         }
  //   //         case ListGroup.All:
  //   //         default: {

  //   //         }
  //   //       }
  //   //       console.warn(`${PAGE} getMethodBasedOnAction UNHANDLED`, list);
  //   //       break;
  //   //     }
  //   //     default: {
  //   //       console.log(`${PAGE} updateItemsBasedOnAction UNHANDLED itemType: '${list.itemType}'`, list);
  //   //     }
  //   //   }

  //   //   return {
  //   //     method: EMPTY,
  //   //     addItemsAction: null,
  //   //     actionItemProp: '',
  //   //   };
  //   // }

  //   // /**
  //   //  * On Create New List, add items observable via API
  //   //  */
  //   // loadListItems$ = createEffect(() =>
  //   //   this.actions$.pipe(
  //   //     ofType(
  //   //       listActions.loadList.type,
  //   //       // listActions.loadMore.type, // TBD if this is easier or more complicated... (action is id:string not list)
  //   //     ),
  //   //     concatMap(action => of(action).pipe(
  //   //       withLatestFrom(this.store$.pipe(select(fromStore.selectAllLists))),
  //   //       withLatestFrom(this.store$.pipe(select(ViewstateSelectors.getLists))),
  //   //     )),
  //   //     filter(([[{ list }, listsState], viewState]) => (!list.loaded)),
  //   //     mergeMap(([[{ list }, listsState], viewState]) => {
  //   //       list.id = createId(list, listsState.length);
  //   //       const filter = viewState.find(filter => filter && filter.id == list.filter.id);
  //   //       DEBUG_LOGS && console.log(`${PAGE} loadListItems -> api`, { list, filter, listsState, viewState });
  //   //       const { method, addItemsAction, actionItemProp } = this.getMethodBasedOnAction(list, filter);
  //   //       return method.pipe(
  //   //         map((res) => {
  //   //           DEBUG_LOGS && console.log(`list.ApiCallBasedOnAction ${list.itemType} ${list.group} res:`, res);

  //   //           //TODO: if filter is active, and we have less than the desired amount, loadMore

  //   //           // else:
  //   //           list.nextToken = res.nextToken;
  //   //           list.itemsSubject$.next({ items: res.items });
  //   //           if (typeof addItemsAction === 'function') {
  //   //             addItemsAction({ [actionItemProp]: res.items, group: list.group, nextToken: list.nextToken })
  //   //           }
  //   //           return list;
  //   //         }),
  //   //         map((list) => listActions.loadSuccess({ list })),
  //   //         catchError(error => of(listActions.loadFail({ error, lists: [list] })))
  //   //       )
  //   //     })
  //   // ));
  //   // // ), { dispatch: false });

  //   // /**
  //   //  * On Load More, add items observable via API
  //   //  */
  //   // loadMoreListItems$ = createEffect(() =>
  //   //   this.actions$.pipe(
  //   //     ofType(listActions.loadMore.type),
  //   //     concatMap(action => of(action).pipe(
  //   //       withLatestFrom(this.store$.pipe(select(fromStore.selectAllLists))),
  //   //       withLatestFrom(this.store$.pipe(select(ViewstateSelectors.getLists))),
  //   //     )),
  //   //     // tap(([[{ id }, listsState], viewState]) => {
  //   //     //   const list = listsState.find(lists => lists.id === id);
  //   //     //   DEBUG_LOGS && console.log(`${PAGE} loadListItems pre-filter`, { id, list });
  //   //     // }),
  //   //     filter(([[{ id }, listsState], viewState]) => {
  //   //       const list = listsState.find(lists => lists.id === id);
  //   //       return list && list.nextToken && list.nextToken.length > 0
  //   //     }),
  //   //     mergeMap(([[{ id }, listsState], viewState]) => {
  //   //       const list = listsState.find(lists => lists.id === id);
  //   //       const filter = viewState.find(filter => filter && filter.id == list.id);
  //   //       const { method, addItemsAction, actionItemProp } = this.getMethodBasedOnAction(list, filter);
  //   //       return method.pipe(
  //   //         map((res) => {
  //   //           DEBUG_LOGS && console.log(`list.ApiCallBasedOnAction ${list.itemType} ${list.group} res:`, res);
  //   //           list.nextToken = res.nextToken;
  //   //           list.itemsSubject$.next({ items: res.items });
  //   //           if (typeof addItemsAction === 'function') {
  //   //             addItemsAction({ [actionItemProp]: res.items, group: list.group, nextToken: list.nextToken })
  //   //           }
  //   //           return list;
  //   //         }),
  //   //         map((list) => listActions.loadSuccess({ list })),
  //   //         catchError(error => of(listActions.loadMoreFail({ error, lists: [list] })))
  //   //       )
  //   //     })
  //   // ));
  //   // // ), { dispatch: false });

  //   // /**
  //   //  * On Set Filter, find Lists that use that FilterId
  //   //  * add items observable via API
  //   //  *
  //   //  * @todo reset Items on every change
  //   //  */
  //   // filterListItems$ = createEffect(() =>
  //   //   this.actions$.pipe(
  //   //     // ofType(listActions.filterList.type, ViewstateActions.setFilter),
  //   //     ofType(ViewstateActions.setFilter),
  //   //     distinctUntilChanged((a: any, b: any) => a.filter.id === b.filter.id && a.filter.q === b.filter.q),
  //   //     concatMap(action => of(action).pipe(
  //   //       withLatestFrom(this.store$.pipe(select(fromStore.selectAllLists))),
  //   //       withLatestFrom(this.store$.pipe(select(ViewstateSelectors.selectEntities))),
  //   //     )),
  //   //     tap(([[{ filter }, listsState], viewState]) => {
  //   //       const lists = listsState.filter(list => list && list.filter && list.filter.id == filter.id);
  //   //       // const { method, addItemsAction, actionItemProp } = this.getMethodBasedOnAction(list);
  //   //       console.log(`filterListItems effect`, { filter, lists, listsState, viewState });

  //   //       // todo: reset Items on every change
  //   //       // listActions.resetListItems(
  //   //     }),
  //   //     // map(([action, state]) => ({...state.entities[action.id], filter: action.filter })), //Get the viewstate entity from list.filter.id

  //   //     // map(([action, state]) => ({...state.entities[action.id], filter: action.filter })),
  //   //     // filter((list) => (list && list.nextToken && list.nextToken.length > 0)),
  //   //     // switchMap((list) => { // cancel prior call
  //   //     //   const { method, addItemsAction, actionItemProp } = this.getMethodBasedOnAction(list);
  //   //     //   return method.pipe(
  //   //     //     map((res) => {
  //   //     //       DEBUG_LOGS && console.log(`list.ApiCallBasedOnAction ${list.itemType} ${list.group} res:`, res);
  //   //     //       list.nextToken = res.nextToken;
  //   //     //       list.addItems$.next(res.items);
  //   //     //       if (typeof addItemsAction === 'function') {
  //   //     //         addItemsAction({ [actionItemProp]: res.items, group: list.group, nextToken: list.nextToken })
  //   //     //       }
  //   //     //       return list;
  //   //     //     }),
  //   //     //     map((list) => listActions.loadSuccess({ list })),
  //   //     //     catchError(error => of(listActions.loadMoreFail({ error, lists: [list] })))
  //   //     //   )
  //   //     // })
  //   // // ));
  //   // ), { dispatch: false });

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(
    private stackActions$: Actions<stackActions.ActionsUnion>,
    private store$: Store<State>,
    private sentryService: SentryService
  ) {}
}
