/** @format */

import { Injectable } from '@angular/core';
import { Observable, from, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { Stack, STACK_PRIVACY, STACK_DEFAULT_POSTER } from '@app/shared/models/stack.model';
import { Utils } from '@app/shared/utils';
import { FilterEntity, FilterEntityTypes } from '@store/selectors/viewstate.selectors';
import { ALL_STACK_FIELDS } from './api.models';

import { GraphQlParamsStacks, UpdateParam, UpdateParamInt } from './api-types';
import { Project } from '@app/projects/shared/project.model';
// import { Observable as ZenObservable } from './../../../../node_modules/zen-observable-ts';
import { gqlToRx } from './api-amplify-zen';

const DEBUG_LOGS = false;
const EXPLORE_SUGGESTED_UPDATE_WITH_FEATURED = true; // if featured is updated, let's update suggested too, for now

const PAGE = '[StacksApiService]';

export const DEFAULT_STACK_POSTER_IMG = STACK_DEFAULT_POSTER; // 'https://content.filmstacker.com/public/posters/stack-default-poster.png';
export const STACK_POSTER_UPLOAD_PATH = ''; // userfiles - sync with Lambda
export const STACK_POSTER_PATH = 'https://content.filmstacker.com/public/posters/';
export const FIELD_TO_DETERMINE_FULL_ENTITY = 'description';
export const DEFAULT_FETCH_LIMIT_STACKS = 20; // default to get
export const MAX_FETCH_LIMIT_STACKS = 88; // cap the limit for doubling on nextToken loadMores

/**
 * Number=0 or NULL https://github.com/aws-amplify/amplify-js/issues/5179#issuecomment-845552401
 * @note that multiple or filters should be wrapped with and:[{or:[]}{or:[]}]
 */
const FILTER_NOT_FEATURED = 'or:[{ featured:{ lt:1 } }{ featured:{ attributeExists:false }}]';
const FILTER_PUBLIC_STACKS = 'private:{ ne: true } privacy:{ eq: "PUBLIC" }';

/**
 * Stack API interactions
 */
@Injectable({
  providedIn: 'root',
})
export class StacksApiService {
  /**
   * The AppSync types for the update command
   */
  types = {
    projectId: 'ID!',
    stackId: 'String!',
    dteSaved: 'AWSDateTime',
    userId: 'String',
    title: 'String',
    userIdentityId: 'String',
    avatar: 'String',
    eventId: 'String',
    playlist: '[OrderedClipInput]',
    duration: 'String',
    private: 'Boolean',
    privacy: 'STACK_PRIVACY',
    // cannot be re-stacked
    isLocked: 'Boolean',
    isCollaborative: 'Int',
    // if Project.isModerated
    isApproved: 'Boolean',
    dtePublished: 'String',
    description: 'String',
    credits: 'String',
    shareUrl: 'String',
    shareDomain: 'String',
    poster: 'String',
    topics: '[String!]',
    tags: '[String!]',
    emotions: '[String!]',
    recommended: 'Int',
    suggested: 'Int',
    featured: 'Int',
    shares: 'Int',
    restacks: 'Int',
    views: 'Int',
    votes: 'Int',
    likes: 'Int',
    hlsSrc: 'String',
    hlsMeta: 'String',
    updatedBy: 'String',
  };

  /**
   * this needs to align with
   * handleDynamoStackStream Lambda -> getStackHtmlRoute
   * @param projId
   * @param stackId
   */
  createShareUrl(projId: string, stackId: string): string {
    return `${projId}/${stackId}/`; // adding the ending slash works better for facebook & twitter poster image
  }

  /**
   * Subscribe to Stack Updates
   * clipUpdated(projectId: ID id: String userId: String): Clip
   * unsubscribe before subscribe not needed, as we may have multiple subscriptions waiting for specific clip ids
   * https://docs.aws.amazon.com/appsync/latest/devguide/aws-appsync-real-time-data.html
   *
   * @param filters if props set will filter results
   * @returns Observable
   *
   * @note All subscription parameters must exist as a field in the returning type.
   * This means that the type of parameter must also match the type of the field in the returning object.
   * https://stackoverflow.com/questions/64308456/how-to-make-subscription-with-arguments-correctly-in-graphql
   */
  subscribeStacksUpdated({ projectId, stackId, userId }: { projectId?: string; stackId?: string; userId?: string }) {
    // : Observable<any>
    DEBUG_LOGS && console.log(`${PAGE} subscribeStacksUpdated...`, { projectId, stackId, userId });
    const inputs: { projectId?: string; stackId?: string; userId?: string } = {};
    let inputDef = '';
    let inputVals = '';
    if (projectId) {
      inputs.projectId = projectId;
      inputDef += '$projectId: ID ';
      inputVals += 'projectId: $projectId ';
    }
    if (stackId) {
      inputs.stackId = stackId;
      inputDef += '$stackId: String ';
      inputVals += 'stackId: $stackId ';
    }
    if (userId) {
      inputs.userId = userId;
      inputDef += '$userId: String ';
      inputVals += 'userId: $userId ';
    }
    const endpoint = 'stackUpdated';
    const query = `subscription ${endpoint} (${inputDef}) {
      ${endpoint} (${inputVals}) {
        __typename
        ${ALL_STACK_FIELDS}
      }
    }`;

    /*
      Successful response :
      {
        "data": {
          "stackUpdated": {
            "projectId": "filmstacker-dev",
            "stackId": "jd_-_kailua-1MOV__202001311111",
            "hlsMeta": "{\"submitTime\":\"2021-08-20T19:02:46.000Z\",\"finishTime\":\"2021-08-20T19:03:19.000Z\",\"hlsFilename\":\"jd_-_kailua-1MOV__202001311111.m3u8\",\"mp4Filename\":\"jd_-_kailua-1MOV__202001311111_Mp4_Avc_Aac_16x9_1920x1080p_24Hz_8.5Mbps_qvbr.mp4\",\"thumbFilename\":\"jd_-_kailua-1MOV__202001311111_thumb.0000000.jpg\",\"hlsDestination\":\"https://videos.filmstacker.com/public/filmstacker-dev/jd_-_kailua-1MOV__202001311111/\",\"mp4Destination\":\"https://videos.filmstacker.com/public/filmstacker-dev/jd_-_kailua-1MOV__202001311111/\",\"thumbDestination\":\"https://videos.filmstacker.com/public/filmstacker-dev/jd_-_kailua-1MOV__202001311111/\",\"updatedAt\":\"2021-09-03T20:41:25.932Z\"}",
            "hlsSrc": testSrc6,
            "modified": null
          }
        }
      }
    */

    DEBUG_LOGS && console.log(`${PAGE} calling gql ${endpoint}`, { inputs, query });

    // subscribe needs Observable from rxjs not zen-observable-ts
    const apiObs = gqlToRx(API.graphql(graphqlOperation(query, inputs)));
    return apiObs.pipe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(({ provider, value }) => {
        if (!value || !value.data[endpoint]) {
          console.warn(`${PAGE} ${endpoint} pipe missing value.data.${endpoint}? value:`, value);
          throw new Error(`API Response missing value.data.${endpoint}`);
        }
        DEBUG_LOGS && console.log(`${PAGE} ${endpoint} pipe:`, value.data[endpoint]);
        return value.data[endpoint];
      }),
      catchError((e) => {
        console.warn(`${PAGE} subscribeStacksUpdated pipe caught `, e);
        return throwError(e);
      })
    );

    // return the observable and allow the caller to handle subscription directly
    // .subscribe({
    //   next: (res) => {
    //     DEBUG_LOGS && console.log(`***${PAGE} ${endpoint} next:`, res);
    //   },
    //   error: (err) => {
    //     console.warn(`${PAGE} ${endpoint}: `, err);
    //   },
    //   complete: () => {
    //     DEBUG_LOGS && console.log(`${PAGE} ${endpoint} subClipUpdates => Complete`);
    //   }
    // });
  }

  /**
   * createFilterString for graphQL
   * @returns string
   * 
   * FilterEntity:
      type?: 'FEATURED' | 'RECENT';
      q?: string;
      tags?: string; // e.g. “wildlife,sea,land” concatenated by “,”
      users?: string; // e.g. “simwicki,jd” concatenated by “,”
   */
  createFilterString(filters: FilterEntity): string {
    /**
     * DynamoDB values are case-sensitive in DB.
     * The Backend is conactenating the desired fields into 'searchField',
     * so we can simply query CONTAINS in searchField for q in LOWERCASE
     */
    const searchField = 'searchField';
    let result = '';
    if (filters) {
      if (filters.q) {
        // only alphanumeric
        const q = filters.q.toLowerCase();
        result += `${searchField}:{ contains:"${q}" }`;
      }
      if (filters.tags) {
        console.warn(`TODO: createFilterString HANDLE tags`, filters);
      }
      if (filters.users) {
        console.warn(`TODO: createFilterString HANDLE users`, filters);
      }
      // if (filters.projectId) { // not doing this here, rather only in the queryStacksByUser directly..
      //   result += `projectId:{ eq: ${filters.projectId} }`;
      // }
      if (filters.isApproved) {
        result += `isApproved:{ eq:true }`;
      }
      if (filters.isDraft) {
        result += `dtePublished:{ attributeExists:false }`;
        // also ignore any filters on privacy here, it's all drafts in studio
      } else {
        /**
         * not Draft - should have a dtePublished
         * @todo why is the api returning drafts with this filter?
         */
        result += `dtePublished:{ attributeExists:true }`;
      }

      /**
       * this was causing issue with old logic using filters.isTopDrawer
       * it was not returning published stacks in recent
       */
      if (filters.isCollaborative && filters.isCollaborative > 0) {
        result += `isCollaborative:{ eq:${filters.isCollaborative} }`;
      } else if (filters.isCollaborative < 1) {
        // remove collaborative drafts,
        // old note: removed "except for topnav drawer" !filters.isTopDrawer
        const collabFilter = `or:[{ isCollaborative:{ lt:1 }}{ isCollaborative:{ attributeExists:false }}]`;
        // first check if we need to wrap the "or" with "and"
        if (filters.type === FilterEntityTypes.Recent) {
          result += `and:[{${FILTER_NOT_FEATURED}}{${collabFilter}}]`;
        } else {
          result += collabFilter;
        }
      } else if (filters.type === FilterEntityTypes.Recent) {
        result += FILTER_NOT_FEATURED;
      }
      // if (filters.type === FilterEntityTypes.Recent) {
      //   result += FILTER_NOT_FEATURED;
      // }

      if (filters.isPublic) {
        result += FILTER_PUBLIC_STACKS;
      }
    }
    // if (filters.type === FilterEntityTypes.Recent) {
    //   // filter:{dtePublished:{ attributeExists:true }and:[{or:[{ featured:{ lt:1 } }{ featured:{ attributeExists:false }}]}{or:[{ isCollaborative:{ lt:1 }}{ isCollaborative:{ attributeExists:false }}]}]private:{ ne: true } privacy:{ eq: "PUBLIC" }}
    // }
    DEBUG_LOGS && console.warn(`${PAGE} createFilterString (${filters?.id})`, { result, filters });
    return result;
  }

  /**
   * queryFeaturedStacksByProject
   */
  queryFeaturedStacksByProject({
    projectId,
    limit = DEFAULT_FETCH_LIMIT_STACKS,
    nextToken = '',
    filters = {},
  }): Observable<{ items: Stack[]; nextToken: string }> {
    if (!projectId) {
      throw Promise.reject('No ID Provided');
    }
    const endpoint = 'queryFeaturedStacksByProject';

    const params: GraphQlParamsStacks = {
      projectId,
      limit,
    };

    if (nextToken) {
      DEBUG_LOGS && console.log(`${PAGE} ${endpoint}[${projectId}] using nextToken`);
      params.nextToken = nextToken;
    }

    let filterStr = '';
    if (Utils.isEmptyObj(filters)) {
      // default public stacks
      filterStr = FILTER_PUBLIC_STACKS;
    } else {
      (filters as FilterEntity).isPublic = true;
      filterStr = this.createFilterString(filters as FilterEntity);
    }

    if (filterStr) {
      filterStr = `filter: { ${filterStr} }`;
    }

    const query = `query queryFeaturedStacksByProject(
        $projectId: ID!
        $limit: Int
        $nextToken: String
      ) {
        queryFeaturedStacksByProject(
          projectId: $projectId
          limit: $limit
          nextToken: $nextToken 
          ${filterStr}
        ) {
          items {
            ${ALL_STACK_FIELDS}
          }
          nextToken
        }
    }`;

    return from(API.graphql(graphqlOperation(query, params)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        DEBUG_LOGS && console.log(`${PAGE} ${endpoint} res:`, res);
        return res.data[endpoint];
      })
    );
  }

  /**
   * @deprecated
   *
   * queryRecentStacksByProject - replaced by: queryFilteredStacksByProject
   */
  queryRecentStacksByProject({
    projectId,
    limit = DEFAULT_FETCH_LIMIT_STACKS,
    nextToken = '',
    filters = {},
  }): Observable<{ items: Stack[]; nextToken: string }> {
    if (!projectId) {
      throw Promise.reject('No ID Provided');
    }
    const endpoint = 'queryRecentStacksByProject';

    const params: GraphQlParamsStacks = {
      projectId,
      limit,
    };

    if (nextToken) {
      DEBUG_LOGS && console.log(`${PAGE} ${endpoint}[${projectId}] using nextToken`);
      params.nextToken = nextToken;
    }

    let filterStr = '';
    if (Utils.isEmptyObj(filters)) {
      // default public stacks
      filterStr = FILTER_PUBLIC_STACKS;
    } else {
      (filters as FilterEntity).isPublic = true;
      filterStr = this.createFilterString(filters as FilterEntity);
    }

    if (filterStr) {
      filterStr = `filter: { ${filterStr} }`;
    }

    const query = `query queryRecentStacksByProject(
        $projectId: ID!
        $limit: Int
        $nextToken: String
      ) {
        queryRecentStacksByProject(
          projectId: $projectId
          limit: $limit
          nextToken: $nextToken 
          ${filterStr}
        ) {
          items {
            ${ALL_STACK_FIELDS}
          }
          nextToken
        }
    }`;
    console.warn(query);

    return from(API.graphql(graphqlOperation(query, params)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        DEBUG_LOGS && console.log(`${PAGE} ${endpoint} res:`, res);
        return res.data[endpoint];
      })
    );
  }

  /**
   * queryStacksByUser
   */
  queryStacksByUser({
    userId,
    limit = DEFAULT_FETCH_LIMIT_STACKS,
    nextToken = '',
    filters = {},
  }): Observable<{ items: Stack[]; nextToken: string }> {
    if (!userId) {
      throw Promise.reject('No ID Provided');
    }

    const endpoint = 'queryStacksByUser';

    const params: GraphQlParamsStacks = {
      userId,
      limit,
    };
    if (nextToken) {
      params.nextToken = nextToken;
    }

    let filterStr = '';
    if (!Utils.isEmptyObj(filters)) {
      const filterEntity = filters as FilterEntity;
      filterStr = this.createFilterString(filterEntity);

      // this endpoint will return private or public, as it's the user's personal endpoint
      // so, we need to filter for or not private
      if (filterEntity.isPrivate) {
        filterStr += `privacy:{ eq:"${STACK_PRIVACY.PRIVATE}" }`;
      } else if (!filterEntity.isDraft) {
        // ignore any privacy filters if isDraft in studio
        // removed private:{ ne: true }
        filterStr = `privacy:{ ne:"${STACK_PRIVACY.PRIVATE}" }`;
      }
      if (filterEntity.projectId) {
        // this is not a filter - it's a Key Condition..?
        filterStr += `projectId:{ eq:"${filterEntity.projectId}" }`;
      }
    }

    if (filterStr) {
      filterStr = `filter:{ ${filterStr} }`;
    }

    // projectId is not a filter - it's a Key Condition..
    // const projectId = filters && (filters as FilterEntity).projectId;
    // if (projectId) {
    //   params.projectId = projectId;
    // }
    // ${ projectId ? '$projectId: ID!' : '' }
    // ${ projectId ? 'projectId: $projectId' : '' }

    const query = `query queryStacksByUser(
        $userId: String!
        $limit: Int
        $nextToken: String
      ) {
        queryStacksByUser(
          userId: $userId
          limit: $limit
          nextToken: $nextToken
          ${filterStr}
        ) {
          items {
            ${ALL_STACK_FIELDS}
          }
          nextToken
        }
    }`;

    // Query using a parameter
    return from(API.graphql(graphqlOperation(query, params)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        DEBUG_LOGS && console.log(`${PAGE} ${endpoint} res:`, res);
        // remove drafts from results..
        const result = res.data[endpoint];
        if (result && Array.isArray(result.items)) {
          if (!filters || !(filters as FilterEntity).isDraft) {
            return {
              ...result,
              items: result.items.filter((item) => item && item.dtePublished),
            };
          }
        }
        return result;
      })
    );
  }

  /**
   * getStack
   * @param projectId
   * @param stackId
   */
  getStack(projectId: string, stackId: string): Promise<Stack> {
    if (!projectId || !stackId) {
      throw Promise.reject('No ID Provided');
    }

    const endpoint = 'getStack';
    const params: GraphQlParamsStacks = {
      projectId,
      stackId,
    };

    const query = `query GetStack(
        $projectId: ID!, 
        $stackId: String!
      ) {
        getStack(
          projectId: $projectId,
          stackId: $stackId
        ) {
          ${ALL_STACK_FIELDS}
        }
    }`;

    // Query using a parameter
    return (API.graphql(graphqlOperation(query, params)) as Promise<GraphQLResult>).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} ${endpoint} res:`, res);
      return res.data[endpoint];
    });
  }

  /**
   * create Draft Stack
   * MVP-995 saveDraft()
   */
  createStackDraft(stack: Stack): Promise<Stack> {
    DEBUG_LOGS && console.log(`${PAGE} createStackDraft`, stack);
    const endpoint = 'createStack';
    const params = stack;
    if (!stack.privacy) {
      // default privacy should be set
      params.privacy = STACK_PRIVACY.PRIVATE;
    }
    if (!stack.updatedBy) {
      params.updatedBy = stack.userId;
    }
    /** same as createStack, but without $dtePublished: String */
    const action = `mutation createStackDraft(
      $projectId: ID!
      $stackId: String!
      $dteSaved: AWSDateTime
      $userId: String
      $title: String
      $userIdentityId: String
      $avatar: String
      $eventId: String
      $playlist: [OrderedClipInput]
      $duration: String
      $private: Boolean
      $privacy: STACK_PRIVACY
      $isCollaborative: Int
      
      $description: String
      $credits: String
      $shareUrl: String
      $shareDomain: String
      $poster: String
      $topics: [String!]
      $tags: [String!]
      $emotions: [String!]
      $recommended: Int
      $suggested: Int
      $featured: Int
      $shares: Int
      $restacks: Int
      $views: Int
      $votes: Int
      $hlsSrc: String
      $hlsMeta: String
      $updatedBy: String
    ) {
      createStack(
        projectId: $projectId
        stackId: $stackId
        dteSaved: $dteSaved
        userId: $userId
        title: $title
        userIdentityId: $userIdentityId
        avatar: $avatar
        eventId: $eventId
        playlist: $playlist
        duration: $duration
        private: $private
        privacy: $privacy
        isCollaborative: $isCollaborative
        
        description: $description
        credits: $credits
        shareUrl: $shareUrl
        shareDomain: $shareDomain
        poster: $poster
        topics: $topics
        tags: $tags
        emotions: $emotions
        recommended: $recommended
        suggested: $suggested
        featured: $featured
        shares: $shares
        restacks: $restacks
        views: $views
        votes: $votes
        hlsSrc: $hlsSrc
        hlsMeta: $hlsMeta
        updatedBy: $updatedBy
      ) {
        projectId
        stackId
        dteSaved
        title
        shareUrl
        shareDomain
        dtePublished
        updatedBy
        isCollaborative
        playlist {
          projectId
          id
          order
        }
      }
    }`;

    return (API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} createStackDraft res:`, res);
      return res.data[endpoint];
    });
    // return API.graphql(graphqlOperation(action, details));
  }

  /**
   * Publish a Stack
   * @param stack
   */
  createStack(stack: Stack): Promise<Stack> {
    DEBUG_LOGS && console.log(`${PAGE} createStack`, stack);
    const endpoint = 'createStack';
    const params = stack;
    if (!stack.updatedBy) {
      params.updatedBy = stack.userId;
    }

    const action = `mutation createStack(
      $projectId: ID!
      $stackId: String!
      $dteSaved: AWSDateTime
      $userId: String
      $title: String
      $userIdentityId: String
      $avatar: String
      $eventId: String
      $playlist: [OrderedClipInput]
      $duration: String
      $private: Boolean
      $privacy: STACK_PRIVACY
      $isLocked: Boolean
      $isApproved: Boolean
      $isCollaborative: Int
      $dtePublished: String
      $description: String
      $credits: String
      $shareUrl: String
      $shareDomain: String
      $poster: String
      $topics: [String!]
      $tags: [String!]
      $emotions: [String!]
      $recommended: Int
      $suggested: Int
      $featured: Int
      $shares: Int
      $restacks: Int
      $views: Int
      $votes: Int
      $hlsSrc: String
      $hlsMeta: String
      $updatedBy: String
    ) {
      createStack(
        projectId: $projectId
        stackId: $stackId
        dteSaved: $dteSaved
        userId: $userId
        title: $title
        userIdentityId: $userIdentityId
        avatar: $avatar
        eventId: $eventId
        playlist: $playlist
        duration: $duration
        private: $private
        privacy: $privacy
        isLocked: $isLocked
        isApproved: $isApproved
        isCollaborative: $isCollaborative
        dtePublished: $dtePublished
        description: $description
        credits: $credits
        shareUrl: $shareUrl
        shareDomain: $shareDomain
        poster: $poster
        topics: $topics
        tags: $tags
        emotions: $emotions
        recommended: $recommended
        suggested: $suggested
        featured: $featured
        shares: $shares
        restacks: $restacks
        views: $views
        votes: $votes
        hlsSrc: $hlsSrc
        hlsMeta: $hlsMeta
        updatedBy: $updatedBy
      ) {
        projectId
        stackId
        dteSaved
        title
        shareUrl
        shareDomain
        isCollaborative
        playlist {
          projectId
          id
          order
        }
        updatedBy
      }
    }`;

    return (API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} ${endpoint} res:`, res);
      return res.data[endpoint];
    });
    // return API.graphql(graphqlOperation(action, details));
  }

  /**
   * Increment/Decrement Stack - Project Admins (owner & producers)
   * Can send positive or negative numbers for any of the Int fields
   * @param projectId
   * @param stackId
   * @param updates
   */
  incrementAdmin(projectId: string, stackId: string, updates: UpdateParamInt[]): Promise<Partial<Stack>> {
    DEBUG_LOGS && console.log(`${PAGE} incrementAdmin`, projectId, stackId, updates);
    const endpoint = 'incrementStackAdmin';
    const params: GraphQlParamsStacks = {
      projectId,
      stackId,
    };
    updates.forEach((update) => {
      if (typeof update.value === 'number') {
        // it should be a positive or negative or zero
        // dynamically create the update params for string query
        params[update.prop] = update.value;
      } else {
        console.log(`${PAGE} incrementAdmin SKIPPING [${update.prop}] !Int:`, update.value);
      }
    });

    const action = `mutation incStackAdmin(
      $projectId: ID!
      $stackId: String!
      $recommended: Int
      $suggested: Int
      $featured: Int
      $shares: Int
      $restacks: Int
      $views: Int
      $votes: Int
    ) {
      incrementStackAdmin(
        projectId: $projectId
        stackId: $stackId
        recommended: $recommended
        suggested: $suggested
        featured: $featured
        shares: $shares
        restacks: $restacks
        views: $views
        votes: $votes
      ) {
        projectId
        stackId
        recommended
        suggested
        featured
        shares
        restacks
        views
        votes
      }
    }`;

    return (API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} ${endpoint} res:`, res);
      const obj = res.data[endpoint];
      // obj.items = obj.items.map(item => {
      //   item.poster = (item.poster) ? PUBLIC_CONTENT_URL + item.poster : DEFAULT_POSTER;
      //   item.sources.map(source => {
      //     if (source.src) {
      //       source.src = PUBLIC_CONTENT_URL + source.src;
      //     }
      //     return source;
      //   });
      //   return item;
      // });
      return obj;
    });
    // return API.graphql(graphqlOperation(action, details));
  }

  /**
   * Increment Stack Int fields - Public
   * Will increment by 1 if field is sent as not null
   * @param stackId
   */
  incrementPublic(projectId: string, stackId: string, updates: UpdateParamInt[]): Promise<Partial<Stack>> {
    DEBUG_LOGS && console.log(`${PAGE} incrementPublic`, projectId, stackId, updates);
    const endpoint = 'incrementStackPublic';
    const params: GraphQlParamsStacks = {
      projectId,
      stackId,
    };
    updates.forEach((update) => {
      if (typeof update.value === 'number') {
        // it should be a positive or negative or zero
        // dynamically create the update params for string query
        params[update.prop] = update.value;
      }
    });

    const action = `mutation incrementStackPublic(
      $projectId: ID!
      $stackId: String!
      $shares: Int
      $restacks: Int
      $views: Int
      $votes: Int
    ) {
      incrementStackPublic(
        projectId: $projectId
        stackId: $stackId
        shares: $shares
        restacks: $restacks
        views: $views
        votes: $votes
      ) {
        projectId
        stackId
        shares
        restacks
        views
        votes
      }
    }`;

    return (API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} ${endpoint} res:`, res);
      const obj = res.data[endpoint];
      // obj.items = obj.items.map(item => {
      //   item.poster = (item.poster) ? PUBLIC_CONTENT_URL + item.poster : DEFAULT_POSTER;
      //   item.sources.map(source => {
      //     if (source.src) {
      //       source.src = PUBLIC_CONTENT_URL + source.src;
      //     }
      //     return source;
      //   });
      //   return item;
      // });
      return obj;
    });
    // return API.graphql(graphqlOperation(action, details));
  }

  /**
   * Update Stack Poster
   * @param projectId
   * @param stackId
   * @param poster image url
   */
  updateStackPoster(projectId: string, stackId: string, poster: string, userId: string): Promise<Partial<Stack>> {
    DEBUG_LOGS && console.log(`${PAGE} updateStackPoster: ${projectId}/${stackId}, poster: '${poster}'`);
    if (!projectId || !stackId || !poster) {
      return Promise.reject('Missing required args');
    }
    const endpoint = 'updateStack';
    const params: GraphQlParamsStacks = {
      projectId,
      stackId,
      poster,
      updatedBy: userId,
    };

    const action = `mutation UpdateStackPoster(
      $projectId: ID!
      $stackId: String!
      $poster: String
      $updatedBy: String
    ) {
      updateStack(
        projectId: $projectId
        stackId: $stackId
        poster: $poster
        updatedBy: $updatedBy
      ) {
        projectId
        stackId
        poster
        updatedBy
      }
    }`;

    return (API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} ${endpoint} res:`, res);
      return res.data[endpoint];
    });
  }

  /**
   * Update Stack isApproved
   * @param projectId
   * @param stackId
   * @param isApproved boolean
   */
  updateStackFeatureApprove({
    projectId,
    stackId,
    userId,
    isApproved,
    featured = -1,
  }: {
    projectId: string;
    stackId: string;
    userId: string;
    featured?: number;
    isApproved?: boolean;
  }): Promise<Partial<Stack>> {
    DEBUG_LOGS &&
      console.log(
        `${PAGE} updateStackFeatureApprove: ${projectId}/${stackId}, isApproved: '${isApproved}' featured: ${featured}`
      );
    if (!projectId || !stackId) {
      return Promise.reject('Missing required args');
    }
    if (typeof isApproved !== 'boolean' && featured < 0) {
      return Promise.reject('Nothing to do..');
    }
    const endpoint = 'updateStack';
    const params: GraphQlParamsStacks = {
      projectId,
      stackId,
      updatedBy: userId,
    };
    let inputsStr = '',
      paramStr = '';
    if (typeof isApproved === 'boolean') {
      params.isApproved = isApproved;
      paramStr += '$isApproved: Boolean ';
      inputsStr += 'isApproved: $isApproved ';
    }
    if (featured >= 0) {
      params.featured = featured;
      paramStr += '$featured: Int ';
      inputsStr += 'featured: $featured ';
    }

    const action = `mutation UpdateStackApproved(
      $projectId: ID!
      $stackId: String!
      $updatedBy: String
      ${paramStr}
    ) {
      updateStack(
        projectId: $projectId
        stackId: $stackId
        updatedBy: $updatedBy
        ${inputsStr}
      ) {
        projectId
        stackId
        isApproved
        featured
        updatedBy
      }
    }`;

    return (API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} UpdateStackApproved res:`, res);
      return res.data[endpoint];
    });
  }

  /**
   * Update a Stack
   * @param stack
   * @param updates UpdateParam[]
   */
  updateStack(stack: Stack, updates: UpdateParam[]): Promise<Stack> {
    DEBUG_LOGS && console.log(`${PAGE} updateStack`, { stack, updates });

    if (!stack || !stack.projectId || !stack.stackId) {
      console.warn('Missing required arguments: stack ids; updates:', updates);
      return Promise.reject('Missing required arguments: stack ids');
    }
    const endpoint = 'updateStack';
    const params: GraphQlParamsStacks = {
      projectId: stack.projectId,
      stackId: stack.stackId,
      /**
       * when should this happen? modifying this will change sort, so it should be important
       * posibly only when changing the playlist?
       */
      // dteSaved: Utils.getDateTimeString(),
    };
    updates.forEach((update) => {
      // it could be number == zero
      if (update.value || typeof update.value === 'number' || typeof update.value === 'boolean') {
        if (update.prop === 'hlsMeta') {
          update.value = typeof update.value === 'string' ? update.value : JSON.stringify(update.value);
        }
        if (update.prop !== 'userId') {
          // don't change the userId
          if (update.prop === 'credits') {
            if (params['userId'] && params['userId'] === stack.userId) {
              // only change credits if this is same user
              params[update.prop] = update.value;
            }
          } else {
            // dynamically create the update params for string query
            params[update.prop] = update.value;
          }
          if (EXPLORE_SUGGESTED_UPDATE_WITH_FEATURED && update.prop === 'featured') {
            params['suggested'] = update.value;
          }
        }
      }
    });

    // if (params['userId'] && params['userId'] !== stack.userId) {
    //   console.warn(`${PAGE} DEV: What happens if userId is modified, since it's an index?`);
    // }

    let definition = '',
      mapping = '';
    Object.keys(params).forEach((key: string) => {
      if (this.types[key]) {
        definition += `$${key}: ${this.types[key]} `;
        mapping += `${key}: $${key} `;
      }
    });

    DEBUG_LOGS && console.log(`${PAGE} updateStack params`, { params, mapping });

    const statement = `mutation UpdateStack(${definition}) {
      updateStack(${mapping}) {
        ${ALL_STACK_FIELDS}
      }
    }`;

    // DEBUG_LOGS && console.log(`${PAGE} updateStack`, {statement});

    return (API.graphql(graphqlOperation(statement, params)) as Promise<GraphQLResult>).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} ${endpoint} res:`, res);
      return res.data[endpoint];
    });
  }

  /**
   * Delete Stack
   * @param projectId
   * @param stackId
   */
  deleteStack(projectId: string, stackId: string): Promise<GraphQLResult> {
    DEBUG_LOGS && console.log(`${PAGE} deleteStack: ${projectId}/${stackId}`);
    if (!projectId || !stackId) {
      // throw Observable.throw("No projectId Provided");
      return Promise.reject('Missing required projectId or stackId');
    }
    const endpoint = 'deleteStack';
    const params: GraphQlParamsStacks = {
      projectId,
      stackId,
    };

    const action = `mutation deleteStack(
      $projectId: ID!
      $stackId: String!
    ) {
      deleteStack(
        projectId: $projectId
        stackId: $stackId
      ) {
        projectId
        stackId
      }
    }`;

    return (API.graphql(graphqlOperation(action, params)) as Promise<GraphQLResult>).then((res) => {
      DEBUG_LOGS && console.log(`${PAGE} ${endpoint} res:`, res);
      return res.data[endpoint];
    });
  }

  queryFilteredStacks({
    filters,
    limit = DEFAULT_FETCH_LIMIT_STACKS,
    nextToken,
  }: {
    filters: FilterEntity;
    limit: number;
    nextToken?: string;
  }): Observable<{ items: Stack[]; nextToken: string }> {
    const params: GraphQlParamsStacks = {
      limit,
      nextToken: nextToken ? nextToken : undefined,
    };
    const queryType = (({ type }) => {
      switch (type) {
        /**
         * @todo query QueryTrendingStacksByProject .. exists, needed?
         */
        case FilterEntityTypes.MostViewed:
          return 'queryTrendingStacks';
        // case FilterEntityTypes.CollabDrafts:
        case FilterEntityTypes.StackDrafts:
        case FilterEntityTypes.Recent:
          return 'queryRecentStacks';
        case FilterEntityTypes.Featured:
        default:
          return 'queryFeaturedStacks';
      }
    })(filters);

    // recent should ignore featured - but do it in createFilterString so "or"s can be wrapped with "and"
    // const recentFilter = filters.type === FilterEntityTypes.Recent ? NOT_FEATURED_QUERY_FILTER : '';
    // API already enforces privacy: 'public' at this endpoint (PK), and it's a key so not allowed in filter
    const filterStrInput = this.createFilterString(filters as FilterEntity);
    const filterStr = filterStrInput ? `filter:{ private:{ ne: true }${filterStrInput} }` : '';

    const query = `query ${queryType}(
        $limit: Int
        $nextToken: String
      ) {
        ${queryType}(
          limit: $limit
          nextToken: $nextToken 
          ${filterStr}
        ) {
          items {
            ${ALL_STACK_FIELDS}
            projectConfig {
              isModerated
              privacy
            }
          }
          nextToken
        }
    }`;
    DEBUG_LOGS && console.log(`${PAGE} queryFilteredStacks`, { params, queryType, filterStr, query });
    return from(API.graphql(graphqlOperation(query, params)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        const result = res.data[queryType];
        DEBUG_LOGS && console.log(`${PAGE} ${queryType} res:`, result);
        /**
         * project is PUBLIC, and either !projectConfig.isModerated or projectConfig.isModerated && stack.isApproved
         * "filter":{"expression" : "(projectConfig.privacy = :public) AND ((projectConfig.isModerated <> :true) OR (projectConfig.isModerated = :true AND isApproved = :true))",
         */
        if (result && Array.isArray(result.items)) {
          DEBUG_LOGS && console.log(result.items.map((i) => i.projectConfig));
          return {
            items: result.items.filter(
              (item) =>
                item &&
                item.projectConfig &&
                item.projectConfig.privacy === 'PUBLIC' &&
                (!item.projectConfig.isModerated || (item.projectConfig.isModerated && item.isApproved))
            ),
            nextToken: result.nextToken,
          };
        }
        return result;
      })
    );
  }

  /**
   * new endpoint called from stacks.effects
   */
  queryFilteredStacksByProject({
    projectId,
    filters,
    limit = DEFAULT_FETCH_LIMIT_STACKS,
    nextToken,
  }: {
    projectId: Project['id'];
    filters: FilterEntity;
    limit?: number;
    nextToken?: string;
  }): Observable<{ items: Stack[]; nextToken: string }> {
    const params: GraphQlParamsStacks = {
      projectId,
      limit,
      nextToken: nextToken ? nextToken : undefined,
    };
    const queryType = (({ type }) => {
      switch (type) {
        case FilterEntityTypes.StackDrafts:
        case FilterEntityTypes.Recent:
          return 'queryRecentStacksByProject';
        case FilterEntityTypes.Featured:
        default:
          return 'queryFeaturedStacksByProject';
      }
    })(filters);

    // recent should ignore featured - but this "or" must be wrapped by "and" so do it in createFilterString
    // const recentFilter = filters.type === FilterEntityTypes.Recent ? NOT_FEATURED_QUERY_FILTER : '';
    // const filterStr = `filter: { private:{ ne: true }${recentFilter}${this.createFilterString(
    // API already enforces privacy: 'public' at this endpoint (PK), but we can ensure with
    const newFilters = { ...filters, isPublic: true };
    // recent should ignore featured - but this "or" must be wrapped by "and" so do it in createFilterString
    const filterStr = `filter:{${this.createFilterString(newFilters as FilterEntity)} }`;

    const query = `query ${queryType}(
      $projectId: ID!
      $limit: Int
      $nextToken: String
    ) {
      ${queryType}(
        projectId: $projectId
        limit: $limit
        nextToken: $nextToken 
        ${filterStr}
      ) {
        items {
          ${ALL_STACK_FIELDS}
        }
        nextToken
      }
    }`;

    // DEBUG_LOGS && filters.type === FilterEntityTypes.Recent && console.log(`${PAGE} queryFilteredStacksByProject type: ${FilterEntityTypes.Recent}`, JSON.stringify({ params, queryType, filterStr, query }));

    // return from(API.graphql(graphqlOperation(query, params))).pipe(map((res) => (res as any).data[queryType]));
    return from(API.graphql(graphqlOperation(query, params)) as Promise<GraphQLResult>).pipe(
      map((res) => {
        DEBUG_LOGS && console.log(`${PAGE} queryFilteredStacksByProject res:`, { res });
        return res.data[queryType];
      })
    );
  }

  /*
   * @deprecated - use queryStacksByUser
   * getStacksByUser
   */
  // private getStacksByUser(
  //   userId: string,
  //   limit = DEFAULT_FETCH_LIMIT,
  //   nextToken = ''
  // ): Observable<{ items: Stack[]; nextToken: string }> {
  //   if (!userId) {
  //     throw Promise.reject('No ID Provided');
  //   }
  //   const endpoint = 'getStacksByUser';
  //   const params: GraphQlParamsStacks = {
  //     userId,
  //     limit,
  //   };
  //   if (nextToken) {
  //     params.nextToken = nextToken;
  //   }

  //   const query = `query getStacksByUser(
  //       $userId: String!
  //       $limit: Int
  //       $nextToken: String
  //     ) {
  //       getStacksByUser(
  //         userId: $userId
  //         limit: $limit
  //         nextToken: $nextToken
  //       ) {
  //         items {
  //           ${ALL_STACK_FIELDS}
  //         }
  //         nextToken
  //       }
  //   }`;

  //   // Query using a parameter
  //   return from(API.graphql(graphqlOperation(query, params)) as Promise<GraphQLResult>).pipe(
  //     map((res) => {
  //       DEBUG_LOGS && console.log(`${PAGE} getStacksByUser res:`, res);
  //       return res.data[endpoint];
  //     })
  //   );
  // }

  /*
   * @deprecated
   */
  // private getFeaturedStacks({ limit = DEFAULT_FETCH_LIMIT, projectId = '', nextToken = '' }): Promise<any> {
  //   console.warn(`${PAGE} DEPRECATED: getFeaturedStacks API`);
  //   const projPart = projectId ? `projectId: {eq:${projectId}}` : '';
  //   const privatePart = `private:{ ne: true }privacy:{ eq: "PUBLIC" }`;
  //   const filter = `{featured:{ ge: 1 }${projPart}${privatePart}}`;
  //   return this.queryStacks(filter, limit, nextToken);
  //   // return Promise.resolve([]);
  // }

  /*
   * @deprecated
   */
  // private getRecentStacks({ limit = DEFAULT_FETCH_LIMIT, projectId = '', nextToken = '' }): Promise<any> {
  //   console.warn(`${PAGE} DEPRECATED: getRecentStacks API`); // TODO: Use the DDB Index on dteSaved
  //   const projPart = projectId ? `projectId: {eq:${projectId}}` : '';
  //   const privatePart = `private:{ ne: true }privacy:{ eq: "PUBLIC" }`;
  //   const filter = `{${projPart}${privatePart}}`;
  //   return this.queryStacks(filter, limit, nextToken);
  // }

  /*
   * @deprecated
   * queryStacks
   * @param filter 
   * @param limit 
   * 
   * Filter example:
   * query queryStacks {
      queryStacks(filter: {
        projectId: {
          eq: "code-of-the-wild"
        }
        private:{
          ne: true
        }
        featured:{
          ge: 1
        }
      },limit: 10 ) {
        items {
          projectId
          stackId
        }
      }
    }
   */
  // private queryStacks(filter: string, limit = DEFAULT_FETCH_LIMIT, nextToken = null): Promise<any> {
  //   const filterStr = filter ? `filter: ${filter},` : '';
  //   const nextTokenStr = nextToken ? `nextToken: ${nextToken},` : '';

  //   const query = `query queryStacks {
  //     queryStacks( ${filterStr} limit: ${limit} ${nextTokenStr} ) {
  //       items {
  //         ${ALL_STACK_FIELDS}
  //       }
  //       nextToken
  //     }
  //   }`;

  //   // console.log(`${PAGE} queryStacks query:`,query);

  //   return (API.graphql(graphqlOperation(query)) as Promise<GraphQLResult>)
  //     .then((res) => {
  //       DEBUG_LOGS && console.log(`${PAGE} queryStacks res:`, res);
  //       const result = (res as any).data.queryStacks;

  //       return {
  //         stacks: result.items,
  //         nextToken: result.nextToken,
  //       };
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       throw err;
  //     });
  // }

  /*
   * @deprecated use queryFilteredStacksByProject
   *
   * queryStacksByProject(projectId: ID!, filter: TableStacksFilterInput, limit: Int, nextToken: String ):
   */
  // private queryStacksByProject(
  //   projectId: string,
  //   limit = DEFAULT_FETCH_LIMIT,
  //   nextToken = '',
  //   filters: { private?: boolean; featured?: boolean } = {}
  // ): Observable<{ items: any[]; nextToken: string }> {
  //   if (!projectId) {
  //     throw Promise.reject('No ID Provided');
  //   }

  //   const params: GraphQlParamsStacks = {
  //     projectId,
  //     limit,
  //   };

  //   if (nextToken) {
  //     DEBUG_LOGS && console.log(`${PAGE} queryStacksByProject[${projectId}] using nextToken`);
  //     params.nextToken = nextToken;
  //   }

  //   const privateFilter = filters && filters['private'] ? 'private:{ eq: true }' : 'private:{ ne: true }';
  //   const featuredFilter = filters && filters['featured'] ? 'featured:{ ge: 1 }' : '';

  //   const filterStr = privateFilter || featuredFilter ? `filter: { ${privateFilter} ${featuredFilter} }` : '';

  //   const query = `query getStacksByProject(
  //       $projectId: ID!
  //       $limit: Int
  //       $nextToken: String
  //     ) {
  //       queryStacksByProject(
  //         projectId: $projectId
  //         limit: $limit
  //         nextToken: $nextToken
  //         ${filterStr}
  //       ) {
  //         items {
  //           ${ALL_STACK_FIELDS}
  //         }
  //         nextToken
  //       }
  //   }`;

  //   // Query using a parameter

  //   return from(API.graphql(graphqlOperation(query, params)) as Promise<GraphQLResult>).pipe(
  //     map((res) => {
  //       DEBUG_LOGS && console.log(`${PAGE} getStacksByProject (recent or featured) res:`, res);
  //       return (res as any).data.queryStacksByProject;
  //     })
  //   );

  //   // return (API.graphql(graphqlOperation(query, params)) as Promise<GraphQLResult>).then(res => {
  // }

  /*
   * @deprecated unused
   * queryRecentStacks(filter: TableStacksFilterInput,limit: Int,nextToken: String ): StackConnection
   *  
   * query queryRecentStacks {
      items {
        stackId
        dteSaved
        featured
        privacy
        private
      }
      nextToken
    }
   */
  // private queryRecentStacks({
  //   limit = DEFAULT_FETCH_LIMIT,
  //   nextToken = '',
  //   filters = {},
  // }): Observable<{ items: any[]; nextToken: string }> {
  //   const params: GraphQlParamsStacks = {
  //     limit,
  //   };

  //   if (nextToken) {
  //     DEBUG_LOGS && console.log(`${PAGE} queryRecentStacks using nextToken`);
  //     params.nextToken = nextToken;
  //   }

  //   // API already enforces privacy: 'public' at this endpoint (PK)
  //   const filterStrInput = this.createFilterString(filters as FilterEntity);
  //   const filterStr = filterStrInput ? `filter: { ${filterStrInput} }` : '';

  //   const query = `query queryRecentStacks(
  //         $limit: Int
  //         $nextToken: String
  //       ) {
  //         queryRecentStacks(
  //           limit: $limit
  //           nextToken: $nextToken
  //           ${filterStr}
  //         ) {
  //           items {
  //             ${ALL_STACK_FIELDS}
  //           }
  //           nextToken
  //         }
  //     }`;

  //   // need Observable from rxjs not zen-observable-ts
  //   return from(API.graphql(graphqlOperation(query, params)) as Promise<GraphQLResult>).pipe(
  //     map((res) => {
  //       DEBUG_LOGS && console.log(`${PAGE} queryRecentStacks res:`, res);
  //       return (res as any).data.queryRecentStacks;
  //     })
  //   );
  // }

  /*
   * @deprecated unused
   * queryFeaturedStacks(filter: TableStacksFilterInput,limit: Int,nextToken: String ): StackConnection
   * 
   * query queryFeaturedStacks {
     items {
       stackId
       dteSaved
       featured
       privacy
       private
     }
     nextToken
   }
   */
  // private queryFeaturedStacks({
  //   limit = DEFAULT_FETCH_LIMIT,
  //   nextToken = '',
  //   filters = {},
  // }): Observable<{ items: Stack[]; nextToken: string }> {
  //   const params: GraphQlParamsStacks = {
  //     limit,
  //   };

  //   if (nextToken) {
  //     DEBUG_LOGS && console.log(`${PAGE} queryFeaturedStacks using nextToken`);
  //     params.nextToken = nextToken;
  //   }

  //   const filterStrInput = this.createFilterString(filters as FilterEntity);
  //   // default public stacks (api already uses privacy as the PK)
  //   const filterStr = filterStrInput ? `filter: { ${filterStrInput} }` : '';
  //   // DEBUG_LOGS && console.log(`${PAGE} queryFeaturedStacks filterStr: '${filterStr}'`);

  //   const query = `query queryFeaturedStacks(
  //         $limit: Int
  //         $nextToken: String
  //       ) {
  //         queryFeaturedStacks(
  //           limit: $limit
  //           nextToken: $nextToken
  //           ${filterStr}
  //         ) {
  //           items {
  //             ${ALL_STACK_FIELDS}
  //           }
  //           nextToken
  //         }
  //     }`;

  //   return from(API.graphql(graphqlOperation(query, params)) as Promise<GraphQLResult>).pipe(
  //     map((res) => {
  //       DEBUG_LOGS && console.log(`${PAGE} queryFeaturedStacks res:`, res);
  //       return (res as any).data.queryFeaturedStacks;
  //     })
  //   );
  // }
}
