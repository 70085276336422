/** @format */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import * as stackActions from '../actions/stacks.actions';
import * as clipActions from '../actions/clips.actions';
import * as userActions from '../actions/user.actions';
import { AnalyticsEventName, AnalyticsService } from '@app/core/services/analytics/analytics.service';

type ActionsUnion = clipActions.ActionsUnion | stackActions.ActionsUnion | userActions.ActionsUnion;

@Injectable()
export class AnalyticsEffects {
  stackWatch$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stackActions.selectIdPlay.type),
        tap((action) => {
          const { type, ...props } = action;
          this.analyticsService.recordEvent(AnalyticsEventName.STACKS_WATCH, props);
        })
      ),
    { dispatch: false }
  );

  stackEdit$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stackActions.selectIdEdit.type),
        tap((action) => {
          const { type, ...props } = action;
          this.analyticsService.recordEvent(AnalyticsEventName.STACKS_EDIT, props);
        })
      ),
    { dispatch: false }
  );

  stackWatchComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stackActions.watchComplete.type),
        tap((action) => {
          const { type, ...props } = action;
          this.analyticsService.recordEvent(AnalyticsEventName.STACKS_WATCH_COMPLETE, props);
        })
      ),
    { dispatch: false }
  );

  clipPlay$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(clipActions.play.type),
        tap((action) => {
          const { type, ...props } = action;
          if (props && props.clip && props.clip.projectId) {
            const payload = {
              projectId: props.clip.projectId,
              clipId: props.clip.id,
            };
            this.analyticsService.recordEvent(AnalyticsEventName.CLIPS_PLAY, payload);
          } else {
            // Fix: Sentry-X8 projectId of null
            // Saw again in -10E after "UpdateService clearing store state and reloading app" (not a surprise that store is empty)
            console.log(`Why are we missing the clip prop here?`, action);
          }
        })
      ),
    { dispatch: false }
  );

  stackShare$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stackActions.share.type),
        tap((action) => {
          const { type, ...props } = action;
          const payload = {
            projectId: props.projectId,
            stackId: props.stackId,
            shareUrl: props.response.shareUrl,
            method: props.response.method,
            itemType: props.response.itemType,
          };
          this.analyticsService.recordEvent(AnalyticsEventName.STACKS_SHARE, payload);
        })
      ),
    { dispatch: false }
  );

  stackPlayIndex$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stackActions.playCurrentIndex.type),
        tap((action) => {
          const { type, ...props } = action;
          this.analyticsService.recordEvent(AnalyticsEventName.STACKS_PLAY_INDEX, props);
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions<ActionsUnion>, private analyticsService: AnalyticsService) {}
}
