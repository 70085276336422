/** @format */
/**
 * @todo Native handle autofill:
 * https://capacitorjs.com/docs/guides/autofill-credentials
 */

import { Component, Input, OnInit } from '@angular/core';
import { NgStyle } from '@angular/common';
import { IonInput } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { BaseFormComponent } from '../base-form.component';
import { FormItemComponent } from '../form-item/form-item.component';

@Component({
  selector: 'fs-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  standalone: true,
  imports: [NgStyle, FormItemComponent, IonInput],
})
export class FormInputComponent extends BaseFormComponent implements OnInit {
  @Input() type?: string = 'text';
  @Input() placeholder?: string;
  @Input() hint: string = '';
  @Input() initValue?: string;
  @Input() showErrorTextOnError = true;
  /**
   * @todo: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   * requires a 'name' attribute to work
   */
  @Input() autocomplete: 'username' | 'email' | 'current-password' | 'new-password' | 'off' | null = null;

  constructor(translate: TranslateService) {
    super(translate);
  }

  ngOnInit() {
    if (this.initValue) {
      this.control.setValue(this.initValue);
    }
  }
}
