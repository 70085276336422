/** @format */

import { ChargebeePlanId, getPricesWithTiers, Plan, PlanPriceTier } from '@app/billing/shared/billing.model';
import { getEventType, Project, ProjectEventType } from '@app/projects/shared/project.model';
import { TOKEN_QUERY_PARAM } from '@app/tokens/shared/tokens.model';

export const ROUTE_DATA_PROP = 'onboardingType';
export const TRANSLATE_ROOT = 'ONBOARDING.';

export const SIGNUP_RETURN_URL_PRO = '/pro/signup';
export const SIGNUP_RETURN_URL_EVENTS = '/events/signup';
export const SIGNUP_RETURN_URL_WEDDINGS = '/events/weddings/signup';
export const ONBOARD_ROUTE_WEDDINGS = '/events/weddings';

export const DEFAULT_BACKGROUND_URL = '/assets/images/events/crowd-filming@2.jpg';
export const DEFAULT_BACKGROUND_POSITION = 'center';

const ROOT_APP_URL = 'https://app.filmstacker.com/';

export enum OnboardingTypes {
  None = 'NONE',
  Projects = 'PROJECTS',
  // Basic = 'BASIC',
  Pro = 'PRO',
  Teams = 'EVENTS',
  Weddings = 'WEDDINGS',
  // Festivals = "FESTIVALS",
}

export interface OnboardingMeta {
  routeId: OnboardingTypes;
  translatePath: string;
  actionUrl: string;
  backgroundUrl: string;
  backgroundPosition: string;
}

export interface PlanItem {
  plan: Plan;
  planId: ChargebeePlanId;
  tiers: PlanPriceTier[];
  meta?: OnboardingMeta;
  /** I am a ProPlan */
  isProPlan?: boolean;
  // isTeamsPlan?: boolean;
}

/**
 * Not using Pro at this time, just teams or weddings
 * Project.template or .eventType
 */
export const getInviteTypeFromProject = (project: Project) => {
  switch (getEventType(project)) {
    case ProjectEventType.Weddings:
      return OnboardingTypes.Weddings;
    default:
      return OnboardingTypes.Teams;
  }
  // return '';
};

/**
 * Get the ProjectType from OnboardingType
 */
export const getProjectEventTypeFromOnboardingType = (
  onboardingType: OnboardingTypes | string = ''
): ProjectEventType => {
  switch (onboardingType.toUpperCase()) {
    case 'WEDDING':
    case OnboardingTypes.Weddings:
      return ProjectEventType.Weddings;
    case 'TEAM':
    case 'TEAMS':
    case 'EVENT':
    case OnboardingTypes.Teams:
      return ProjectEventType.Event;
    default:
      return ProjectEventType.Projects;
  }
  // return '';
};

/**
 * MVP-1144 v2 subs
 */
export const getPlanFromOnboardingType = (routeId, plans: Plan[] = []): PlanItem => {
  let planId = ChargebeePlanId.Free;
  // not if isTeamsPlan, rather is this a ProPlan (everything else would be Teams)
  let isProPlan = false;
  switch (routeId) {
    case OnboardingTypes.Weddings: {
      planId = ChargebeePlanId.Weddings;
      break;
    }
    case OnboardingTypes.Teams:
      planId = ChargebeePlanId.Teams;
      break;
    case OnboardingTypes.Pro:
    case OnboardingTypes.Projects:
      planId = ChargebeePlanId.Pro;
      isProPlan = true;
      break;
    case OnboardingTypes.None:
    default: {
      planId = ChargebeePlanId.Free;
    }
  }
  const plan = plans.find((p) => p?.id === planId);
  // console.log({ plan, plans, planId });
  const pricesWithTiers = plan ? getPricesWithTiers(plan) : [];
  if (pricesWithTiers.length > 1) {
    console.warn(`Unexpected > 1 tier? Using first..`);
  }
  return {
    plan,
    planId,
    tiers: pricesWithTiers.length > 0 ? pricesWithTiers[0]?.tiers ?? [] : [],

    isProPlan,
    // here we should get PLANS differently...
    // plan: PLANS.get(planId),
  };
};

export const getTranslatePath = (onboardingType: OnboardingTypes | string): string => {
  switch ((onboardingType || '').toUpperCase()) {
    case OnboardingTypes.Weddings:
      return TRANSLATE_ROOT + 'WEDDINGS.';
    case OnboardingTypes.Teams:
      return TRANSLATE_ROOT + 'EVENTS.';
    case OnboardingTypes.Pro:
    case OnboardingTypes.Projects:
    case OnboardingTypes.None:
    default:
      return TRANSLATE_ROOT;
  }
};

export const getInviteAcceptPath = (onboardingType: OnboardingTypes | string): string => {
  switch ((onboardingType || '').toUpperCase()) {
    case OnboardingTypes.Weddings:
      return 'events/weddings/accept';
    case OnboardingTypes.Teams:
    case OnboardingTypes.Projects:
      return 'events/accept';
    case OnboardingTypes.Pro:
    case OnboardingTypes.None:
    default:
      return 'pro/accept';
  }
};
export const getInvitePath = (onboardingType: OnboardingTypes | string): string => {
  switch ((onboardingType || '').toUpperCase()) {
    case OnboardingTypes.Weddings:
      return 'events/weddings/invite';
    case OnboardingTypes.Teams:
    case OnboardingTypes.Projects:
      return 'events/invite';
    case OnboardingTypes.Pro:
    case OnboardingTypes.None:
    default:
      return 'pro/invite';
  }
};
export const getSuccessPath = (onboardingType: OnboardingTypes | string): string => {
  switch ((onboardingType || '').toUpperCase()) {
    case OnboardingTypes.Weddings:
      return 'events/weddings/success';
    case OnboardingTypes.Teams:
      return 'events/success';
    case OnboardingTypes.Pro:
    case OnboardingTypes.Projects:
    case OnboardingTypes.None:
    default:
      return 'pro/success';
  }
};

export const createTokenQrCode = (project: Project, tokenId = '') => {
  if (!project || !project.id) {
    return `${ROOT_APP_URL}`;
  }
  const qsToken = tokenId ? `?${TOKEN_QUERY_PARAM}=${tokenId}` : '';
  return `${ROOT_APP_URL}${getInviteAcceptPath(getInviteTypeFromProject(project))}/${project.id}${qsToken}`;
};

export class OnboardingConfig {
  name: string;
  where: string;
  when: string;
  description: string;
  producerMustModerateStacks?: boolean; // producerMustModerateStacks

  constructor(props: unknown) {
    if (props && typeof props === 'object') {
      Object.entries(props).forEach(([key, value]) => (this[key] = value));
    }
  }
}

/**
 * @deprecated
 * Instead, put these in a Project directly
 */
// export class OnboardingType implements Onboarding {
export class Onboarding extends Project {
  onboardingConfig: OnboardingConfig;
  onboardingType: OnboardingTypes = OnboardingTypes.Projects;
  translatePath = getTranslatePath(OnboardingTypes.Projects);

  // title: string = 'Loading...';
  // owner: string;
  // mission: string;
  // description: string;
  // logoUrl: string;
  // hero: string;
  // private: boolean;
  // featured?: number;
  // views: number;
  // votes: number;
  // shares: number;
  // channel: string;
  // created: string;
  // configJsonUrl?: string;
  // topics?: Array<string>;
  // emotions?: Array<string>;
  // tags?: Array<string>;
  // template?: string;
  // streams?: Array<string>;
  // shareUrl: string = null;
  // allowPublicPublish?: boolean;

  constructor(props: unknown) {
    super(props);
    if (props && typeof props === 'object') {
      Object.entries(props).forEach(([key, value]) => (this[key] = value));
    }
  }
}

/**
 * @deprecated WeddingsOnboarding
 */
export class WeddingsOnboarding extends Onboarding {
  // id: string; // onboardingId
  weddingId: string; // ID
  onboardingType: OnboardingTypes = OnboardingTypes.Weddings;
  translatePath = getTranslatePath(OnboardingTypes.Weddings);

  constructor(props: unknown) {
    super(props);
    if (props && typeof props === 'object') {
      Object.entries(props).forEach(([key, value]) => (this[key] = value));
    }
  }
}
