/** @format */

import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { IonButton, IonIcon, IonSpinner } from '@ionic/angular/standalone';

type ButtonStyle = 'primary' | 'outline' | 'clear';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'fs-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, IonButton, IonSpinner, IonIcon],
})
export class ButtonComponent implements AfterViewInit {
  @Input() buttonStyle: ButtonStyle = 'primary';
  @Input() size: 'default' | 'large' | 'small' = 'default';
  @Input() color = 'primary';
  @Input() disabled = false;
  @Input() blocked = false;
  @Input() type: 'button' | 'reset' | 'submit' = 'button';
  @Input() icon?: string;
  @Input() form?: string;

  @ViewChild('ionButton') ionButton: IonButton;

  // if only an icon is provided and there is no content to be transcluded,
  // this property will keep the unnecessary width of the button in tact
  @Input() iconOnly = false;
  @Input() loading = false;

  ngAfterViewInit() {
    if (this.form) {
      /**
       * @note this did not work to submit the form
       * issue with submitting the form from button outside the form el..
       * @see project-form.component
       * resolved with #createProjectForm="ngForm" & not sending this param
       * https://forum.ionicframework.com/t/form-submit-in-footer/71732/10
       * https://github.com/ionic-team/ionic-framework/issues/21194
       */
      this.ionButton.form = this.form;
    }
  }
}
