/** @format */

import { Component, Input, HostBinding, ViewChild } from '@angular/core';
import { NgIf } from '@angular/common';
import { ModalController, IonContent, IonFooter } from '@ionic/angular/standalone';
import { ListTitleComponent } from '../list-title/list-title.component';

@Component({
  selector: 'fs-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss'],
  standalone: true,
  imports: [NgIf, ListTitleComponent, IonContent, IonFooter],
})
export class BaseModalComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() titleIcon: string;
  @Input() mode: 'light' | 'dark' = 'dark';
  @HostBinding('attr.ngNoHost') noHost = '';
  @ViewChild(IonContent, { read: IonContent, static: false }) content: IonContent;

  constructor(private modalCtrl: ModalController) {}

  dismiss(event = {}) {
    this.modalCtrl.dismiss(event);
  }

  scrollToTop() {
    this.content.scrollToTop(400);
  }
}
