/**
 * TODO: Implement Sentry Cordova Native
 *
 * @format
 */

import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
// import { IonicErrorHandler } from '@ionic/angular/standalone';
import { Integrations } from '@sentry/tracing';
import { environment } from 'src/environments/environment';

/**
 * Sentry // use both browser and cordova, depending on platform
 * angular webapp:
 * https://sentry.io/filmstacker/filmstackerv4webapp/getting-started/javascript-angular/
 *
 * cordova:
 * https://docs.sentry.io/error-reporting/quickstart/?platform=cordova&_ga=2.163571132.108645248.1568223710-15436354.1544825942
 * cordova plugin add sentry-cordova@0.12.3
 *
 */
import * as Sentry from '@sentry/browser';
// import * as Sentry from 'sentry-cordova';

/**
 * Config
 */
const SHOW_REPORT_DIALOG = false; // Sentry.showReportDialog

const ENABLE_IN_DEV = false; // test the Sentry interface when !env.production

const DEBUG_LOGS = false;

const PAGE = '[SentryService]'; // eslint-disable-line @typescript-eslint/no-unused-vars

interface EnvOptions {
  platforms?: string[];
}

/**
 * Extract the Error
 */
const extractError = (error) => {
  try {
    if (!error) {
      return null;
    }
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error.ngOriginalError) {
      error = error.ngOriginalError;
    } else if (error.originalError) {
      error = error.originalError;
    }

    // check for GraphQL Error
    if (Array.isArray(error.errors) && error.errors.length > 0) {
      const indicator =
        error.data && Object.keys(error.data).length && Object.keys(error.data)[0]
          ? Object.keys(error.data)[0] + '_'
          : '';
      for (let i = 0; i < error.errors.length; i++) {
        console.log(`GQL Api Error[${indicator}${i}]`, error.errors[i]);
      }
      /*
       * Per Sentry Docs (2022-02-07):
       * Additional Data (setExtra) is deprecated in favor of structured contexts and should be avoided when possible
       */
      // Sentry.configureScope((scope) => {
      //   for (let i = 0; i < error.errors.length; i++) {
      //     scope.setExtra(`GraphQL_Error[${indicator || i}]`, error.errors[i]);
      //   }
      // });

      // if possible, extract the message from the error and return that string (2023-03-02)
      error = error.errors[0]?.message ?? error;
      return error;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Skip if there's no error, and let user decide what to do with it.
    return null;
  } catch (inner) {
    console.warn(`extractError caught inner:`, inner);
    return error;
  }
};

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error) {
    const extractedError = extractError(error) || 'Handled unknown error';

    // When in development mode, log the error to console for immediate feedback.
    if (!environment.production) {
      console.error(extractedError);
    }

    if (environment.production || ENABLE_IN_DEV) {
      // Capture handled exception and send it to Sentry.
      const eventId = Sentry.captureException(extractedError);

      // Optionally show user dialog to provide details on what happened.
      if (SHOW_REPORT_DIALOG) {
        Sentry.showReportDialog({ eventId });
      }
    }
  }
}

/*
  Sentry Error Tracking
  * https://docs.sentry.io/platforms/javascript/ionic/

  Setting the Level
  You can set the severity of an event to one of five values: 
  ‘fatal’, ‘error’, ‘warning’, ‘info’, and ‘debug’. (‘error’ is the default.) 
  ‘fatal’ is the most severe and ‘debug’ is the least.

*/
@Injectable({
  providedIn: 'root',
})
export class SentryService {
  isEnabled: boolean = false;

  constructor() {
    if (environment.production || ENABLE_IN_DEV) {
      this.isEnabled = true;

      // if browser
      Sentry.init({
        dsn: 'https://5a8159d397b04228b51612b2c9238f85@o221406.ingest.sentry.io/1724619',
        release: `filmstacker-v4-webapp@${environment.version}`,
        /**
         * Set tracesSampleRate to 1.0 to capture 100%
         * of transactions for performance monitoring.
         * We recommend adjusting this value in production
         */
        tracesSampleRate: 1.0,
        /**
         * Registers and configures the Tracing integration,
         * which automatically instruments your application to monitor its
         * performance, including custom Angular routing instrumentation
         */
        integrations: [
          new Integrations.BrowserTracing({
            tracingOrigins: ['localhost', 'https://app.filmstacker.com', environment.aws.appSyncGraphQLEndpoint],
            // routingInstrumentation: Sentry.routingInstrumentation, // if using @sentry/angular
          }),
        ],
        // older...
        // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
        // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
        // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
        // integrations: [new Sentry.Integrations.TryCatch({
        //   XMLHttpRequest: false,
        // })],
      });

      // if cordova
      /**
       * @todo native
       */
      // onDeviceReady: function() {
      //   var Sentry = cordova.require("sentry-cordova.Sentry");
      //   Sentry.init({ dsn: 'https://573ace45c33d410f85f6dd4baff87b70@sentry.io/1724630' });
      // }
    }
  }

  init(options: EnvOptions) {
    if (this.isEnabled) {
      DEBUG_LOGS && console.log(`${PAGE} Sentry.init`);
      this.setEnv(options);
    }
  }

  /**
   * Captures a message event and sends it to Sentry.
   * @param message — The message to send to Sentry.
   * @param level — Define the level of the message.
   * @returns — The generated eventId || null
   */
  captureMessage(msg: string) {
    if (this.isEnabled) {
      DEBUG_LOGS && console.log(`${PAGE} Sentry.captureMessage: ${msg}`);
      return Sentry.captureMessage(msg);
    }
  }

  captureError(error: Error | string) {
    // When in development mode, log the error to console for immediate feedback.
    const e = extractError(error) || 'Captured unknown error';
    console.error(e);

    if (environment.production || ENABLE_IN_DEV) {
      // Capture handled exception and send it to Sentry.
      const eventId = Sentry.captureException(e);

      // Optionally show user dialog to provide details on what happened.
      if (SHOW_REPORT_DIALOG) {
        Sentry.showReportDialog({ eventId });
      }
    }
  }

  /**
   * Capturing the User
     Sending users to Sentry will unlock a number of features, primarily the ability to drill down into the number of users 
     affecting an issue, as well to get a broader sense about the quality of the application.
   * @param user 
   */
  setUser(user: { id: string; userId: string; username: string; email?: string }) {
    if (this.isEnabled) {
      DEBUG_LOGS && console.log(`${PAGE} setUser`, user);
      const {
        id,
        userId,
        username,
        email, //?
      } = user;

      const payload: { id?: string; userId?: string; username?: string; email?: string } = {};
      if (id) {
        payload.id = id;
      }
      if (userId) {
        payload.userId = userId;
      }
      if (username) {
        payload.username = username;
      }
      if (email) {
        payload.email = email;
      }

      Sentry.configureScope((scope) => {
        // scope.setUser({"email": "john.doe@example.com"});
        scope.setUser(payload);
      });
    }
  }

  /**
   * Extra Context
     In addition to the structured context that Sentry understands, you can send arbitrary key/value pairs of data 
     which will be stored alongside the event. 
     These are not indexed and are simply used to add additional information about what might be happening
     NOTE: 200kB maximum that Sentry has on individual event payloads
   */
  setExtra() {
    // Sentry.configureScope((scope) => {
    //   scope.setExtra("character_name", "Mighty Fighter");
    // });
  }

  /**
   * Submit User Feedback
      POST /api/0/projects/{organization_slug}/{project_slug}/user-feedback/
   
      Submit and associate user feedback with an issue.
      Feedback must be received by the server no more than 30 minutes after the event was saved.
      Additionally, within 5 minutes of submitting feedback it may also be overwritten. 
      This is useful in situations where you may need to retry sending a request due to network failures.
      If feedback is rejected due to a mutability threshold, a 409 status code will be returned.
   */
  submitUserFeedback() {
    /*
      POST /api/0/projects/the-interstellar-jurisdiction/plain-proxy/user-feedback/ HTTP/1.1
      Host: sentry.io
      Authorization: Bearer <token>
      Content-Type: application/json

      {
        "comments": "It broke!", 
        "email": "jane@example.com", 
        "event_id": "14bad9a2e3774046977a21440ddb39b2", 
        "name": "Jane Smith"
      }
    */
  }

  /**
   * Tagging Events
     Tags are various key/value pairs that get assigned to an event, 
     and can later be used as a breakdown or quick access to finding related events.
   */
  private setEnv(options: EnvOptions) {
    if (this.isEnabled) {
      // console.log(`${PAGE} Sentry.setEnv`); // NODE_ENV: "dev"

      Sentry.configureScope((scope) => {
        scope.setTag('version', environment.version);
        // scope.setTag("platform", process.env.PLATFORM);
        if (options && Array.isArray(options.platforms)) {
          scope.setTag('platforms', options.platforms.join(','));
        }
        if (!environment.production) {
          scope.setTag('stage', 'dev');
        }
      });
    }
  }
}

/*  
  Ionic V3 - IonicErrorHandler no longer used in v4
*/
// export class SentryIonicErrorHandler extends IonicErrorHandler {
//   handleError(error) {
//     super.handleError(error);
//     try {
//       // if prod, do sentry
//       if (__PROD__) {
//         Sentry.captureException(error.originalError || error);
//       // } else {
//         // console.log(`sentry not PROD`, __DEV__);
//         // else console.warn("Skipping Sentry Error Report:", error.originalError || error);
//       }
//     } catch (e) {
//       console.error(e);
//     }
//   }
// }
